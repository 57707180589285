import { ComponentDefinition } from '@wix/editor-platform-sdk-types';
import { ComponentType, ControllerType, EditorAppContext } from './types';

const CLASSIC_SKIN = 'wixui.skins.SearchBox';
const RESPONSIVE_SKIN = 'wixui.skins.ResponsiveSearchBox';

export function getAppWidgetWrappedSearchBoxDefinition({
  layout,
  layoutResponsive,
  appContext,
}: {
  layout: ComponentDefinition['layout'];
  layoutResponsive?: any;
  appContext: EditorAppContext;
}): ComponentDefinition {
  const { appDefinitionId, flowAPI } = appContext;
  const isResponsiveEditor = flowAPI.environment.isEditorX;
  const skin =
    isResponsiveEditor
      ? RESPONSIVE_SKIN
      : CLASSIC_SKIN;

  // the add component flow expects the connections to be defined, and
  // it knows to replace the ids with the real ids its going to put there
  const controllerId = 'placeHolderId';
  return {
    componentType: 'platform.components.AppWidget',
    components: [
      {
        componentType: ComponentType.SearchBox,
        data: {
          type: 'SearchBox',
        },
        props: {
          type: 'SearchBoxProperties',
        },
        styleId: 'SearchBox_1',
        skin,
        layout,
        layoutResponsive,
        style: {
          skin,
          componentClassName: 'wixui.SearchBox',
          style: {
            properties: {
              'alpha-inputIconColor': '0.6',
              'alpha-inputBorderColor': '0.6',
              'alpha-buttonBackgroundColor': '0.8',
              'boxShadowToggleOn-inputShadow': 'false',
              inputShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.6)',
              inputFont: 'font_8',
            },
            propertiesSource: {
              'alpha-inputIconColor': 'value',
              'alpha-inputBorderColor': 'value',
              'alpha-buttonBackgroundColor': 'value',
              'boxShadowToggleOn-inputShadow': 'value',
              inputShadow: 'value',
              inputFont: 'value',
            },
            propertiesOverride: {
              inputFont: {
                fontSize: '16px',
              },
            },
          },
        },
        connections: {
          type: 'ConnectionList',
          items: [
            {
              type: 'ConnectionItem',
              role: 'searchBox',
              controllerId,
              isPrimary: true,
            },
          ],
        },
      } as object,
    ],
    layout,
    data: {
      type: 'AppController',
      controllerType: ControllerType.SearchApp,
      applicationId: appDefinitionId,
      settings: JSON.stringify({}),
      id: controllerId,
    },
  } as ComponentDefinition;
}

export function getSearchResultsHeaderComponentDefinition({
  text,
  layout,
  layouts,
}: {
  text: string;
  layout: ComponentDefinition['layout'];
  layouts: ComponentDefinition['layouts'];
}): ComponentDefinition {
  return {
    componentType: 'wysiwyg.viewer.components.WRichText',
    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
    layout,
    layouts,
    data: {
      text: `<h1 class="font_0" style="font-size:32px;text-align:center;"><span style="font-size:32px;">${text}</span></h1>`,
      type: 'StyledText',
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
    },
  };
}

const appWidgetLayouts = {
  containerLayout: {
    type: 'GridContainerLayout',
    rows: [
      {
        type: 'fr',
        value: 1,
      },
    ],
    columns: [
      {
        type: 'fr',
        value: 1,
      },
    ],
  },
  componentLayout: {
    type: 'ComponentLayout',
    width: {
      type: 'px',
      value: 42,
    },
    height: {
      type: 'px',
      value: 42,
    },
    minHeight: {
      type: 'px',
      value: 10,
    },
    maxHeight: {
      type: 'px',
      value: 99999,
    },
    hidden: false,
  },
  itemLayout: {
    type: 'GridItemLayout',
    gridArea: {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 1,
      columnEnd: 2,
    },
    margins: {
      top: {
        type: 'px',
        value: 0,
      },
      bottom: {
        type: 'px',
        value: 0,
      },
      left: {
        type: 'px',
        value: 0,
      },
      right: {
        type: 'px',
        value: 200,
      },
    },
    justifySelf: 'end',
    alignSelf: 'center',
  },
  type: 'SingleLayoutData',
  metaData: {
    schemaVersion: '1.0',
    isPreset: false,
    isHidden: false,
  },
};

const searchButtonLayouts = {
  type: 'SingleLayoutData',
  componentLayout: {
    type: 'ComponentLayout',
    width: {
      type: 'percentage',
      value: 3.28125,
    },
    height: {
      type: 'percentage',
      value: 47.19101123595506,
    },
    hidden: false,
  },
  containerLayout: {},
  itemLayout: {
    type: 'GridItemLayout',
    gridArea: {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 1,
      columnEnd: 2,
    },
    margins: {
      top: {
        type: 'px',
        value: 0,
      },
      left: {
        type: 'px',
        value: 0,
      },
      bottom: {
        type: 'px',
        value: 0,
      },
      right: {
        type: 'px',
        value: 0,
      },
    },
    justifySelf: 'center',
    alignSelf: 'center',
  },
  metaData: {
    schemaVersion: '1.0',
    isPreset: false,
    isHidden: false,
  },
};

export function getAppWidgetWrappedSearchButtonDefinition({
  layout,
  appContext,
}: {
  layout: ComponentDefinition['layout'];
  appContext: EditorAppContext;
}): ComponentDefinition {
  const { appDefinitionId } = appContext;

  const metaData = {
    isPreset: false,
    schemaVersion: '1.0',
    isHidden: false,
  };
  const controllerId = 'placeHolderId';

  const SearchButton = {
    skin: 'wixui.skins.Skinless',
    componentType: 'wixui.SearchButton',
    layout: {
      x: 0,
      y: 0,
      fixedPosition: false,
      width: 42,
      height: 42,
      scale: 1,
      rotationInDegrees: 0,
    },
    data: {
      type: 'StylableButton',
      metaData,
      label: 'Search',
      svgId: '11062b_a6a46efdf42c44a1be5d8720468d9a69.svg',
    },
    connections: {
      type: 'ConnectionList',
      items: [
        {
          type: 'ConnectionItem',
          role: 'searchButton',
          controllerId,
          isPrimary: true,
        },
      ],
    },
    props: {
      isWrapText: false,
      iconCollapsed: false,
      isMaxContent: false,
      type: 'SearchButtonProperties',
    },
    scopedLayouts: {},
    layouts: searchButtonLayouts,
    scopedStyles: {},
    style: {
      componentClassName: 'wixui.SearchButton',
      style: {
        groups: {},
        properties: {
          'hover-icon-display': 'initial',
          'content-horizontal-alignment': 'center',
          'border-left': '0px solid rgba(var(--color_15),1)',
          'icon-display': 'initial',
          'hover-border-right': '0px solid rgba(var(--color_18),1)',
          background: 'rgba(var(--color_11),0)',
          'hover-icon-rotation': '0',
          'icon-size': '26px',
          'hover-icon-color': 'rgba(var(--color_18),1)',
          'label-display': 'none',
          color: 'color_15',
          'hover-border-left': '0px solid rgba(var(--color_18),1)',
          font: 'font_8',
          'icon-rotation': '0',
          'hover-border-top': '0px solid rgba(var(--color_18),1)',
          'border-bottom': '0px solid rgba(var(--color_15),1)',
          'hover-border-bottom': '0px solid rgba(var(--color_18),1)',
          'icon-color': 'rgba(var(--color_15),1)',
          'border-top': '0px solid rgba(var(--color_15),1)',
          'content-gap': '8px',
          'hover-icon-size': '26px',
          'container-align-items': 'center',
          'container-flex-direction': 'row-reverse',
          'box-shadow': '',
          'hover-color': 'color_18',
          'border-right': '0px solid rgba(var(--color_15),1)',
          'text-shadow': '0px 0px 0px transparent',
          'disabled-color': 'color_14',
          'disabled-icon-size': '26px',
          'disabled-icon-color': 'rgba(var(--color_14),1)',
          'disabled-icon-rotation': '0',
          'disabled-icon-display': 'initial',
        },
        propertiesSource: {
          'hover-icon-display': 'value',
          'content-horizontal-alignment': 'value',
          'border-left': 'value',
          'icon-display': 'value',
          'hover-border-right': 'value',
          background: 'value',
          'hover-icon-rotation': 'value',
          'icon-size': 'value',
          'hover-icon-color': 'value',
          'label-display': 'value',
          color: 'theme',
          'hover-border-left': 'value',
          font: 'theme',
          'icon-rotation': 'value',
          'hover-border-top': 'value',
          'border-bottom': 'value',
          'hover-border-bottom': 'value',
          'icon-color': 'value',
          'border-top': 'value',
          'content-gap': 'value',
          'hover-icon-size': 'value',
          'container-align-items': 'value',
          'container-flex-direction': 'value',
          'box-shadow': 'value',
          'hover-color': 'theme',
          'border-right': 'value',
          'text-shadow': 'value',
          'disabled-color': 'theme',
          'disabled-icon-size': 'value',
          'disabled-icon-color': 'value',
          'disabled-icon-rotation': 'value',
          'disabled-icon-display': 'value',
        },
      },
      skin: 'wixui.skins.SearchButton',
      type: 'ComponentStyle',
      styleType: 'custom',
      pageId: '',
      compId: '',
      metaData: {
        schemaVersion: '1.0',
        isPreset: false,
        isHidden: false,
      },
    },
    activeModes: {},
    referredVariants: {},
    activeVariants: [],
  };

  const appWidget = {
    componentType: 'platform.components.AppWidget',
    layout: {
      x: 300,
      y: 0,
      fixedPosition: false,
      width: 42,
      height: 42,
      scale: 1,
      rotationInDegrees: 0,
    },
    type: 'Container',
    components: [SearchButton],
    data: {
      type: 'AppController',
      controllerType: ControllerType.SearchButton,
      applicationId: appDefinitionId,
      settings: JSON.stringify({}),
      id: controllerId,
    },
    scopedLayouts: {},
    layouts: appWidgetLayouts,
    style: {
      styleType: 'system',
      componentClassName: 'platform.components.AppWidget',
      style: {
        properties: {},
        propertiesSource: {},
        groups: {},
      },
      pageId: '',
      compId: '',
      type: 'TopLevelStyle',
      skin: 'platform.components.skins.AppWidgetSkin',
      metaData,
    },
    activeModes: {},
    referredVariants: {},
    activeVariants: [],
  };
  // the add component flow expects the connections to be defined, and
  // it knows to replace the ids with the real ids its going to put there
  return appWidget as unknown as ComponentDefinition;
}
