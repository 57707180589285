interface ClampOptions {
  clamp?: 'auto' | 'parent' | string | number;
  useNativeClamp?: boolean;
  truncationChar?: string;
  useExactLineHeight?: boolean;
  splitOnChars?: string[];
  animate?: boolean | number;
  truncationHTML?: string;
  maxLineCount?: number;
}

const inSafariMobile = () => {
  const userAgent = window.navigator ? window.navigator.userAgent : '';
  return userAgent.match(/iPhone/i) && userAgent.match(/safari/i);
};

export const clamp = (element: HTMLElement, options: ClampOptions = {}) => {
  element.removeAttribute('style');
  const opt = getOptions(options);
  const originalText = element.innerHTML;
  const supportsNativeClamp =
    typeof element.style.webkitLineClamp !== 'undefined';
  const lineHeight = getLineHeight(element, opt);
  const clampValue = getClampValue(opt, element, lineHeight);

  if (supportsNativeClamp && opt.useNativeClamp && !inSafariMobile()) {
    applyNativeClamp(element, clampValue, lineHeight, opt);
  } else {
    const height = getMaxHeight(clampValue, lineHeight);
    if (height < element.clientHeight) {
      const clampedText = truncateText(element, height, opt);
      return { original: originalText, clamped: clampedText };
    }
  }

  return { original: originalText, clamped: undefined };
};

const getOptions = (options: ClampOptions): ClampOptions => ({
  clamp: options.clamp || 2,
  useNativeClamp:
    options.useNativeClamp !== undefined ? options.useNativeClamp : true,
  useExactLineHeight:
    options.useExactLineHeight !== undefined
      ? options.useExactLineHeight
      : false,
  splitOnChars: options.splitOnChars || ['.', '-', '–', '—', ' '],
  animate: options.animate || false,
  truncationChar: options.truncationChar || '…',
  truncationHTML: options.truncationHTML,
  maxLineCount:
    options.maxLineCount !== undefined ? options.maxLineCount : 9999,
});

const getLineHeight = (element: HTMLElement, opt: ClampOptions): number => {
  if (opt.useExactLineHeight) {
    return calculateLineHeight(element);
  }
  const lineHeight = computeStyle(element, 'line-height');

  if (lineHeight === 'normal') {
    return parseInt(computeStyle(element, 'font-size'), 2) * 1.2;
  }
  return parseInt(lineHeight, 10);
};

const calculateLineHeight = (element: HTMLElement): number => {
  const parent = element.parentNode;
  if (!parent) {
    throw new Error('Parent node is null');
  }

  const lastChildNodeType = element.lastChild?.nodeType;
  const clone = (
    lastChildNodeType === 3 ? element! : element.lastChild!
  ).cloneNode(true) as HTMLElement;
  const textNode = getLastChild(clone) || clone;
  textNode.textContent = element.textContent
    ? element.textContent.trim()[0]
    : '&nbsp;';
  parent.appendChild(clone);
  const height = clone.clientHeight;
  parent.removeChild(clone);
  return height;
};

const computeStyle = (element: HTMLElement, prop: string): string =>
  window.getComputedStyle(element, null).getPropertyValue(prop);

const getClampValue = (
  opt: ClampOptions,
  element: HTMLElement,
  lineHeight: number,
): number => {
  const clampValue = opt.clamp;
  const linesCount = opt.maxLineCount ?? 2;
  if (clampValue === 'parent') {
    return getMaxLines(
      element.parentElement!.clientHeight,
      lineHeight,
      linesCount,
    );
  } else if (clampValue === 'auto') {
    return getMaxLines(undefined, lineHeight, linesCount);
  } else if (
    typeof clampValue === 'string' &&
    (clampValue.indexOf('px') > -1 || clampValue.indexOf('em') > -1)
  ) {
    return getMaxLines(parseInt(clampValue, 2), lineHeight, linesCount);
  }
  return clampValue as number;
};

const getMaxLines = (
  height: number | undefined,
  lineHeight: number,
  linesCount: number,
): number => {
  const elementHeight = height || document.documentElement.clientHeight;
  const roundFn = inSafariMobile() ? Math.ceil : Math.floor;
  const maxLines = Math.max(roundFn(elementHeight / lineHeight), 0);
  return Math.min(maxLines, linesCount);
};

const getMaxHeight = (clampValue: number, lineHeight: number): number =>
  lineHeight * clampValue;

const applyNativeClamp = (
  element: HTMLElement,
  clampValue: number,
  lineHeight: number,
  opt: ClampOptions,
) => {
  const style = element.style;
  style.overflow = 'hidden';
  style.textOverflow = 'ellipsis';
  style.webkitBoxOrient = 'vertical';
  style.display = clampValue > 0 ? '-webkit-box' : 'none';
  style.webkitLineClamp = clampValue.toString();
  style.maxHeight = clampValue * lineHeight + 'px';
  if (typeof opt.clamp === 'string') {
    style.height = opt.clamp;
  }
};

const truncateText = (
  element: HTMLElement,
  maxHeight: number,
  opt: ClampOptions,
): string | undefined => {
  let splitOnChars = opt.splitOnChars!.slice(0);
  let splitChar = splitOnChars[0];
  let chunks: string[] | null = null;
  let lastChunk: string | undefined;

  const reset = () => {
    splitOnChars = opt.splitOnChars!.slice(0);
    splitChar = splitOnChars[0];
    chunks = null;
    lastChunk = undefined;
  };

  const applyEllipsis = (elem: ChildNode, str: string) => {
    elem.nodeValue = str + opt.truncationChar;
  };

  const truncate = (target: ChildNode | undefined): string | undefined => {
    if (!target || !maxHeight) {
      return;
    }

    const nodeValue = target.nodeValue!.replace(opt.truncationChar!, '');

    if (!chunks) {
      if (splitOnChars.length > 0) {
        splitChar = splitOnChars.shift()!;
      } else {
        splitChar = '';
      }
      chunks = nodeValue.split(splitChar);
    }

    if (chunks.length > 1) {
      lastChunk = chunks.pop();
      applyEllipsis(target, chunks.join(splitChar));
    } else {
      chunks = null;
    }

    if (opt.truncationHTML) {
      const truncationHTMLContainer = document.createElement('span');
      truncationHTMLContainer.innerHTML = opt.truncationHTML;
      target.nodeValue = target.nodeValue!.replace(opt.truncationChar!, '');
      element.innerHTML =
        target.nodeValue +
        ' ' +
        truncationHTMLContainer.innerHTML +
        opt.truncationChar;
    }

    if (chunks) {
      if (element.clientHeight <= maxHeight) {
        if (splitOnChars.length >= 0 && splitChar !== '') {
          applyEllipsis(target, chunks.join(splitChar) + splitChar + lastChunk);
          chunks = null;
        } else {
          return element.innerHTML;
        }
      }
    } else {
      if (splitChar === '') {
        applyEllipsis(target, '');
        target = getLastChild(element);
        reset();
      }
    }

    if (opt.animate) {
      setTimeout(
        () => truncate(target),
        opt.animate === true ? 10 : opt.animate,
      );
    } else {
      return truncate(target);
    }
  };

  return truncate(getLastChild(element));
};

const getLastChild = (element: HTMLElement): ChildNode | undefined => {
  if (!element.lastChild) {
    return;
  }
  if (element.lastChild.childNodes && element.lastChild.childNodes.length > 0) {
    return getLastChild(Array.prototype.slice.call(element.children).pop());
  } else if (
    !element.lastChild.nodeValue ||
    element.lastChild.nodeValue === ''
  ) {
    const sibling = element.lastChild;
    do {
      if (!sibling) {
        return;
      }
      if (sibling.nodeType === 3 && sibling.nodeValue !== '') {
        return sibling;
      }
      if (sibling.lastChild) {
        const lastChild = getLastChild(sibling as HTMLElement);
        if (lastChild) {
          return lastChild;
        }
      }
      sibling.parentNode!.removeChild(sibling);
    } while (sibling === sibling.previousSibling);
  } else {
    return element.lastChild;
  }
};
