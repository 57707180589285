import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';
import DefaultDesign from '../../design';

const defaultDesign = DefaultDesign;
export type IFaqOoiStylesParams = {
  headerTextColor: StyleParamType.Color;
  headerTextFont: StyleParamType.Font;
  selectedCategoryColor: StyleParamType.Color;
  categoryOtherTextColor: StyleParamType.Color;
  categoryOtherTextFont: StyleParamType.Font;
  categoryStripeColor: StyleParamType.Color;
  widgetColor: StyleParamType.Color;
  questionTextColor: StyleParamType.Color;
  questionTextFont: StyleParamType.Font;
  questionStripesTextColor: StyleParamType.Color;
  questionStripesTextFont: StyleParamType.Font;
  questionNumberTextColor: StyleParamType.Color;
  questionNumberTextFont: StyleParamType.Font;
  stripColorBgColor: StyleParamType.Color;
  questionNumberHoverColor: StyleParamType.Color;
  answerTextColor: StyleParamType.Color;
  answerTextFont: StyleParamType.Font;
  answersLinksColor: StyleParamType.Color;
  dividerCollapseColor: StyleParamType.Color;
  isCategoryChanged: StyleParamType.Boolean;
};

export default createStylesParams<IFaqOoiStylesParams>({
  headerTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.headerText.color.name,
    getDefaultValue: wixColorParam(defaultDesign.headerText.color.value),
  },
  headerTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.headerText.font.name,
    getDefaultValue: wixFontParam(defaultDesign.headerText.font.value),
  },
  selectedCategoryColor: {
    type: StyleParamType.Color,
    key: defaultDesign.selectedCategory.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.selectedCategory.color.value,
      defaultDesign.selectedCategory.color.opacity || 100,
    ),
  },

  categoryOtherTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.categoryOtherText.color.name,
    getDefaultValue: wixColorParam(defaultDesign.categoryOtherText.color.value),
  },
  categoryOtherTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.categoryOtherText.font.name,
    getDefaultValue: wixFontParam(defaultDesign.categoryOtherText.font.value, {
      size: defaultDesign.categoryOtherText.font.startWithSize,
    }),
  },
  categoryStripeColor: {
    type: StyleParamType.Color,
    key: defaultDesign.stripColor.color.name,
    getDefaultValue: wixColorParam(defaultDesign.stripColor.color.value),
  },
  widgetColor: {
    type: StyleParamType.Color,
    key: defaultDesign.widget.color.name,
    getDefaultValue: wixColorParam(defaultDesign.widget.color.value, 100),
  },
  questionTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.questionText.color.name,
    getDefaultValue: wixColorParam(defaultDesign.headerText.color.value),
  },
  questionTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.questionText.font.name,
    getDefaultValue: wixFontParam(defaultDesign.questionText.font.value, {
      size: defaultDesign.questionText.font.startWithSize,
    }),
  },
  questionStripesTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.questionStripesText.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.questionStripesText.color.value,
    ),
  },
  questionStripesTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.questionStripesText.font.name,
    getDefaultValue: wixFontParam(
      defaultDesign.questionStripesText.font.value,
      {
        size: defaultDesign.questionStripesText.font.startWithSize,
      },
    ),
  },
  questionNumberTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.questionNumberText.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.questionNumberText.color.value,
    ),
  },
  questionNumberTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.questionNumberText.font.name,
    getDefaultValue: wixFontParam(defaultDesign.questionNumberText.font.value, {
      size: defaultDesign.questionNumberText.font.startWithSize,
    }),
  },
  stripColorBgColor: {
    type: StyleParamType.Color,
    key: defaultDesign.stripColor.color.name,
    getDefaultValue: wixColorParam(defaultDesign.stripColor.color.value),
  },
  questionNumberHoverColor: {
    type: StyleParamType.Color,
    key: defaultDesign.questionNumberHover.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.questionNumberHover.color.value,
    ),
  },
  answerTextColor: {
    type: StyleParamType.Color,
    key: defaultDesign.answerText.color.name,
    getDefaultValue: wixColorParam(defaultDesign.answerText.color.value),
  },
  answerTextFont: {
    type: StyleParamType.Font,
    key: defaultDesign.answerText.font.name,
    getDefaultValue: wixFontParam(defaultDesign.answerText.font.value, {
      size: defaultDesign.answerText.font.startWithSize,
    }),
  },
  answersLinksColor: {
    type: StyleParamType.Color,
    key: defaultDesign.answersLinks.color.name,
    getDefaultValue: wixColorParam(defaultDesign.answersLinks.color.value),
  },
  dividerCollapseColor: {
    type: StyleParamType.Color,
    key: defaultDesign.dividerCollapse.color.name,
    getDefaultValue: wixColorParam(
      defaultDesign.dividerCollapse.color.value,
      defaultDesign.dividerCollapse.color.opacity || 100,
    ),
  },
  isCategoryChanged: {
    type: StyleParamType.Boolean,
    key: 'isCategoryChanged',
    getDefaultValue: () => false,
  },
});
