import {
  arrayToObjectAsync,
  getBaseProvidersData,
} from '../../contexts/main/getBaseProvidersData';
import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { renderSeoMetatagsPage } from '../../services/SeoMetatagsPage';
import { userProviderPropsMap } from '../../contexts/User/userProviderPropsMap';
import { getChallengeInitialData } from '../../contexts/storage-contexts/Challenge';
import { Referrer } from '../../contexts/storage/referrer';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;

  return {
    async pageReady() {
      try {
        const initialProps = {
          ...(await arrayToObjectAsync([
            getBaseProvidersData({
              flowAPI,
            }),
            getChallengeInitialData(flowAPI, Referrer.THANKYOU_PAGE),
          ])),
          ...(await arrayToObjectAsync([userProviderPropsMap(flowAPI)])),
        };

        renderSeoMetatagsPage(
          flowAPI,
          initialProps?.challengeData?.challenge,
          'THANK_YOU_PAGE',
        );

        setProps(initialProps);
      } catch (err) {
        console.error(err);
      }
    },
  };
};

export default createController;
