import { type AppState } from '../../types';

export const getTopology = (state: AppState) => state.topology ?? {};
export const getBaseUrl = (state: AppState) => getTopology(state).baseUrl ?? '';
export const getSectionUrl = (state: AppState) =>
  getTopology(state).sectionUrl ?? '';
export const getPostPageSectionUrl = (state: AppState) =>
  getTopology(state).postPageSectionUrl ?? '';
export const getPostPagePath = (state: AppState) =>
  getTopology(state).postPagePath ?? '';

export const getIsTemplate = (state: AppState) =>
  getTopology(state).sectionUrl?.includes('//www.wix.com/') ?? false;
