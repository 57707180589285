import { useMemo } from 'react';

import { createHttpClient, useEnvironment } from '@wix/yoshi-flow-editor';

export function useHttpClient(instance: string) {
  const { isSSR } = useEnvironment();

  return useMemo(
    () =>
      createHttpClient({
        isSSR,
        getAppToken() {
          return instance;
        },
      }),
    [instance, isSSR],
  );
}
