import React, { useEffect, useState, useMemo, useContext } from 'react';
import { FAQService } from '../../../../../utils/FAQService';
import { useTPAComponentsContext } from 'wix-ui-tpa/cssVars';
import settingsParams from '../../../settingsParams';
import constants from '../../../../../constants';
import type { Category } from '@wix/ambassador-faq-category-v2-category/types';
import { AppContext } from '../SettingsProvider/SettingsProvider';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import VerticalTabs from './VerticalTabs';
import { CategoriesPosition } from '../../../../../enums';
import HorizontalTabs from './HorizontalTabs';

const TabsWrapper = ({ searchTerm }: { searchTerm?: string }) => {
  const settings: ISettingsContextValue = useSettings();
  const { rtl } = useTPAComponentsContext();
  const { instance, setSelectedCategory } = useContext(AppContext);
  const displayContent = settings.get(settingsParams.displayContent);
  const { showAll, categories } = useMemo(() => {
    return settings.get(settingsParams.manageCategories);
  }, [settings]);
  const splitIntoCategories =
    displayContent === constants.DISPLAY_CONTENT.SPLIT_INTO_CATEGORIES;
  const categoriesPosition: CategoriesPosition = settings.get(
    settingsParams.categoriesPosition,
  );
  const sideCategoriesPosition =
    categoriesPosition === constants.DISPLAY_CATEGORIES.SIDE;
  const topCategoriesPosition =
    categoriesPosition === constants.DISPLAY_CATEGORIES.TOP;
  const oneListOfQuestions =
    settings.get(settingsParams.displayContent) ===
    constants.DISPLAY_CONTENT.ONE_LIST_OF_QUESTIONS;
  const api = useMemo(() => new FAQService(instance), [instance]);
  const [listCategories, setListCategories] = useState<Category[]>([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const asyncFun = async () => {
      const { categories: categriesList } = await api.getListCategories();
      let firstId: string | undefined | null;
      const validLists = categriesList?.filter((category) => {
        const categoryId = category?.id;
        if (showAll) {
          if (typeof firstId === 'undefined') {
            firstId = categoryId;
          }
          return true;
        }
        if (!categoryId) {
          return false;
        }
        const isValid = categories?.includes(categoryId);
        if (isValid) {
          if (typeof firstId === 'undefined') {
            firstId = categoryId;
          }
          return true;
        }
      });
      setListCategories(validLists as Category[]);
      if (oneListOfQuestions) {
        const validCategories = validLists
          ?.filter((category: Category) => category?.id)
          .map((category: Category) => category?.id);
        setSelectedCategory?.(validCategories);
      } else if (firstId) {
        setSelectedCategory?.(firstId);
      }
    };
    asyncFun();
  }, [api, categories, oneListOfQuestions, setSelectedCategory, showAll]);
  useEffect(() => {
    if (setSelectedCategory && !oneListOfQuestions) {
      const categoryId = listCategories[activeTab]?.id;
      if (categoryId) {
        setSelectedCategory?.(categoryId);
      }
    }
  }, [activeTab, listCategories, oneListOfQuestions, setSelectedCategory]);

  const categoriesToRender = useMemo(() => {
    if (showAll) {
      return listCategories;
    }
    if (categories && categories.length > 0) {
      return listCategories.filter((category: Category) =>
        categories.includes(category?.id!),
      );
    }

    return [];
  }, [showAll, categories, listCategories]);

  const adjustedActiveTab = useMemo(() => {
    if (
      categoriesToRender.length > 0 &&
      activeTab >= categoriesToRender.length
    ) {
      return 0;
    }
    return activeTab;
  }, [activeTab, categoriesToRender]);

  return (
    <>
      {!searchTerm &&
        splitIntoCategories &&
        categoriesToRender.length > 0 &&
        topCategoriesPosition && (
          <HorizontalTabs
            categoriesToRender={categoriesToRender}
            activeTab={activeTab}
            adjustedActiveTab={adjustedActiveTab}
            setActiveTab={setActiveTab}
            splitIntoCategories={splitIntoCategories}
            rtl={rtl || false}
            searchTerm=""
          />
        )}
      {!searchTerm &&
        splitIntoCategories &&
        categoriesToRender.length > 0 &&
        sideCategoriesPosition && (
          <VerticalTabs
            sideCategoriesPosition={sideCategoriesPosition}
            categoriesToRender={categoriesToRender}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
    </>
  );
};

export default TabsWrapper;
