import React, { Component } from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { type NormalizedPost } from '../../../common/types';

const PostPageWixCommentsUnsafe = loadable(
  () =>
    import(
      /* webpackChunkName: "post-page-wix-comments" */ './post-page-wix-comments'
    ),
);

// Internal comments error handler should catch errors, but there still can be cases where error bubbles up because of bundling issues etc..
export class PostPageWixComments extends Component<{ post: NormalizedPost }> {
  state = { isInErrorState: false };

  componentDidCatch(error: Error) {
    this.setState({ isInErrorState: true });
    console.error(error);
  }

  render() {
    if (this.state.isInErrorState) {
      return null;
    }

    return <PostPageWixCommentsUnsafe {...this.props} />;
  }
}
