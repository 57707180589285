import React from 'react';
import uuid from 'uuid';
import { format } from 'date-fns';

import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  Button,
  ButtonPriority,
  ThreeDotsLoader,
  Text,
  TextTypography,
} from 'wix-ui-tpa/cssVars';
import { joinParticipant } from '@wix/ambassador-challenges-v1-participant/http';
import { Check as CheckIcon } from '@wix/wix-ui-icons-common/on-stage';

import { st, classes } from './PaymentJoinButton.st.css';
import { useUser } from '../../../../contexts/User/UserContext';
import { useGeneralData } from '../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { useHttpClient } from '../../../../hooks/useHttpClient';
import {
  Pages,
  useLocation,
} from '../../../../contexts/Location/LocationContext';

export interface IPaymentJoinButtonProps {}

export const PaymentJoinButton: React.VFC<IPaymentJoinButtonProps> = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const { participant, user, requestMemberEmail } = useUser();
  const { instance } = useGeneralData();
  const httpClient = useHttpClient(instance);
  const { goToPage } = useLocation();

  React.useEffect(() => {
    requestMemberEmail();
  }, [requestMemberEmail]);

  const onJoinClick = React.useCallback(async () => {
    setLoading(true);

    try {
      const startDate = format(new Date(), 'yyyy-MM-dd');

      await httpClient.request(
        joinParticipant({
          challengeId: participant.challenge.id,
          memberId: participant.member.id,
          timeZone: participant.timezone,
          startDate,
          actionId: uuid(),
        }),
      );
      goToPage({
        pageId: Pages.ThankYou,
        challengeId: participant.challenge.id,
      });
    } catch (error) {
      console.log('Error on join participant:', error?.response || error);

      setLoading(false);
    }
  }, [participant, goToPage, httpClient]);

  return (
    <div className={st(classes.paymentButtonContainer, {})}>
      <div className={classes.textContainer}>
        <span className={classes.title}>
          <CheckIcon />
          <Text typography={TextTypography.largeTitle}>
            {t('checkout.title.sign-up')}
          </Text>
        </span>
        <Text typography={TextTypography.runningText}>
          {t('checkout.text.logged-in', {
            memberEmail: user.email,
          })}
        </Text>
      </div>
      <Button
        onClick={onJoinClick}
        priority={ButtonPriority.primary}
        disabled={loading}
        fullWidth={true}
      >
        {loading ? (
          <span className={classes.loader}>
            <ThreeDotsLoader />
          </span>
        ) : (
          t('coupons.button.join-program')
        )}
      </Button>
    </div>
  );
};
