import React, { useContext } from 'react';
import { WixRicosViewerNext } from '@wix/ricos-viewer';
import {
  ensureRicosContent,
  ensureDraftContent,
} from 'ricos-content/libs/converters';
import { fromDraft, toDraft } from 'ricos-content/libs/migrateSchema';

import { pluginLink } from 'wix-rich-content-plugin-link/viewer';
import { pluginImage } from 'wix-rich-content-plugin-image/viewer';
import { pluginVideo } from 'wix-rich-content-plugin-video/viewer';
import { pluginIndent } from 'wix-rich-content-plugin-indent/viewer';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/viewer';
import { pluginMap } from 'wix-rich-content-plugin-map/viewer';
import { pluginLineSpacing } from 'wix-rich-content-plugin-line-spacing/viewer';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/viewer';
import { pluginEmoji } from 'wix-rich-content-plugin-emoji/viewer';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/viewer';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/viewer';
import { pluginDivider } from 'wix-rich-content-plugin-divider/viewer';
import { pluginHtml } from 'wix-rich-content-plugin-html/viewer';
import { pluginAudio } from 'wix-rich-content-plugin-audio/viewer';
import { pluginGallery } from 'wix-rich-content-plugin-gallery/viewer';
import { pluginLinkButton } from 'wix-rich-content-plugin-button/viewer';
import { pluginTable } from 'wix-rich-content-plugin-table/viewer';
import { pluginTextColor } from 'wix-rich-content-plugin-text-color/viewer';
import { DraftJSObject } from '../../helpers/Interfaces';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { AppContext } from '../SettingsProvider/SettingsProvider';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';

enum PlatformType {
  Livesite = 'Livesite',
  Editor = 'Editor',
}

const RCV = ({
  convertAnswerToJSON,
}: {
  convertAnswerToJSON: DraftJSObject;
}) => {
  const { instance } = useContext(AppContext);
  const environment = useEnvironment();
  const settings = useSettings();
  const layout = settings.get(settingsParams.layout);
  const validContent = toDraft(
    fromDraft(ensureDraftContent(convertAnswerToJSON), {
      ignoreUnsupportedValues: true,
    }),
  );
  return (
    <WixRicosViewerNext
      instance={instance}
      biSettings={{
        consumer: '[wix-labs] faq-ooi widget',
        usage: layout,
        platform: environment.isViewer
          ? PlatformType.Livesite
          : PlatformType.Editor,
      }}
      plugins={[
        pluginLink(),
        pluginLinkPreview(),
        pluginImage({ disableTransition: true }),
        pluginVideo(),
        pluginAudio({
          getAudioUrl: (src: any) => `https://static.wixstatic.com/${src.id}`,
        }),
        pluginGallery({
          disableExpand: true,
        }),
        pluginMap(),
        pluginDivider(),
        pluginIndent(),
        pluginEmoji(),
        pluginFileUpload(),
        pluginGiphy(),
        pluginLineSpacing(),
        pluginVerticalEmbed(),
        pluginLinkPreview(),
        pluginHtml({
          width: '100%',
          minWidth: '100%',
          siteDomain: 'https://nivina3.wixsite.com/my-site-334',
        }),
        pluginLinkButton(),
        pluginTable(),
        pluginTextColor(),
      ]}
      content={ensureRicosContent(validContent)}
    />
  );
};

export default RCV;
