import React from 'react';
import { isEqual } from 'lodash';
import { Provider } from 'react-redux';
import { createStore, type Store } from 'redux';
import {
  type IControllerConfig,
  type IHostProps,
} from '@wix/yoshi-flow-editor';
import { createAction } from '@wix/communities-blog-client-common';
import { createPromisifiedActionsService } from '../../actions-promisifier/create-promisified-actions-service';
import { withPromisifiedOpenModal } from '../../modals/framework/store/modal-actions';
import { getUpdatedState } from '../../services/state-optimizer/change-detector';
import {
  isDebug,
  isProduction,
  isEditor,
} from '../../store/basic-params/basic-params-selectors';
import { type AppState } from '../../types';

const setState = createAction('root/SET_STATE', (payload: AppState) => payload);
const setHost = createAction('root/SET_HOST', (payload: IHostProps) => payload);

type AnyAction = ReturnType<typeof setState> | ReturnType<typeof setHost>;

type RootState = {
  state?: Record<string, any>;
  host?: IHostProps;
  actions?: Record<string, any>;
  workerReady?: Promise<void>;
};

const root = (state: RootState = {}, action: AnyAction): RootState => {
  switch (action.type) {
    case setState.type:
      return { ...state, state: action.payload };
    case setHost.type:
      return { ...state, host: action.payload };
    default:
      return state;
  }
};

/** Passed via controlled with `setProps()` */
export type WithReduxStoreOwnProps = {
  state: Record<string, any>;
  actions: Record<string, any>;
  actionsPromisified: Record<string, any>;
  stateVersions?: Record<string, number>;
  controllerId?: number;
  isSSR?: boolean;
};

type PlatformProps = {
  host: IHostProps;
};

type CommonProps = {
  isRTL?: boolean;
  isMobile?: boolean;
  publicData?: IControllerConfig['publicData'];
  stylesParams?: IControllerConfig['style']['styleParams'];
  usesCssPerBreakpoint?: boolean;
};

export const withReduxStore = <
  T extends WithReduxStoreOwnProps & PlatformProps & CommonProps,
>(
  AppComponent: React.ComponentType<T>,
) => {
  class WithReduxStore extends React.Component<T> {
    store: Store | null = null;
    stateVersions: Record<string, number> | undefined;
    promisifiedActionsService = createPromisifiedActionsService();
    controllerId: number | undefined;
    workerReady: Promise<void> | undefined;
    markWorkerAsReady: () => void = () => {};

    componentDidUpdate(prevProps: WithReduxStoreOwnProps) {
      if (prevProps.isSSR !== this.props.isSSR && this.markWorkerAsReady) {
        this.markWorkerAsReady();
      }
    }

    getWorkerReady = () => {
      this.workerReady ||= new Promise<void>((resolve) => {
        if (!this.props.isSSR) {
          resolve();
          this.markWorkerAsReady = () => {};
        } else {
          this.markWorkerAsReady = resolve;
        }
      });
      return this.workerReady;
    };

    patchState = (state: Record<string, any>, host: IHostProps) => {
      state.appSettings = {
        ...state.appSettings,
        colorPresets: host.style.siteColors,
        textPresets: host.style.siteTextPresets,
        style: host.style.styleParams,
      };
    };

    render() {
      const {
        state,
        stateVersions,
        actions,
        actionsPromisified,
        host,
        controllerId,
      } = this.props;

      if (!this.controllerId) {
        this.controllerId = controllerId;
      }

      if (!this.store) {
        const connectedActionsPromisified = Object.keys(
          actionsPromisified,
        ).reduce((wrapped, actionName) => {
          wrapped[actionName] =
            this.promisifiedActionsService.usePromisifiedAction(
              actionsPromisified[actionName],
              state,
            );
          return wrapped;
        }, {} as Record<string, () => Promise<any>>);

        this.stateVersions = stateVersions;
        this.patchState(state, host);
        this.store = createStore(root, {
          state,
          actions: {
            ...actions,
            ...connectedActionsPromisified,
            openModal: withPromisifiedOpenModal(actions.openModal),
          },
          host,
          workerReady: this.getWorkerReady(),
        });
      } else {
        const currentState = this.store.getState().state;
        const currentStateVersions = this.stateVersions;

        const updatedState = getUpdatedState({
          currentState,
          currentVersions: currentStateVersions,
          newState: state,
          newVersions: stateVersions,
          currentControllerId: this.controllerId,
          newControllerId: controllerId,
        });
        this.stateVersions = stateVersions;

        if (isEditor(updatedState)) {
          this.patchState(updatedState, host);
          if (!isEqual(updatedState, currentState)) {
            this.store.dispatch(setState(updatedState));
          }
          this.store.dispatch(setHost(host));
        } else {
          if (
            updatedState.appSettings &&
            !updatedState.appSettings.colorPresets
          ) {
            this.patchState(updatedState, host);
          }

          if (!isEqual(updatedState, currentState)) {
            this.store.dispatch(setState(updatedState));
          }
        }
        this.promisifiedActionsService.resolvePromisifiedActions(updatedState);
      }

      if (this.controllerId !== controllerId) {
        this.controllerId = controllerId;
      }

      if (
        typeof jest === 'undefined' &&
        (isDebug(this.store.getState().state) ||
          !isProduction(this.store.getState().state))
      ) {
        console.log('AppRoot', this.props);
      }

      return (
        <Provider store={this.store}>
          <AppComponent {...this.props} />
        </Provider>
      );
    }
  }

  return WithReduxStore;
};
