import React from 'react';
import classNames from 'classnames';
import {
  isLayoutMobile,
  isLayoutTextOnImage,
  type Section,
} from '@wix/communities-blog-client-common';

import BasePagination, {
  type PaginationProps,
} from '../../components/pagination';
import { useResponsiveContext } from '../../components/responsive-listener/responsive-context';
import useLayoutProps from '../../hooks/use-layout-props';
import { createPageLink, type HandleNavigationFn } from './create-page-link';
import styles from './pagination.scss';

const MAX_WIDTH_WITH_PAGE_NUMBERS = 375;

type OwnProps = {
  pageSize: PaginationProps['pageSize'];
  page: PaginationProps['page'];
  entityCount: PaginationProps['entityCount'];
  onChange?: PaginationProps['onChange'];

  section?: Section;
  createPageUrl?: (page: number) => string;
  useDescriptionColor?: boolean;
  containerClassName?: string;
  activeClass?: string;
  showPagination?: boolean;
  handleNavigation?: HandleNavigationFn;
};

export const Pagination: React.FC<OwnProps> = (props) => {
  const layoutProps = useLayoutProps({ section: props.section });
  const { rootWidth } = useResponsiveContext();
  const isMobile = isLayoutMobile(layoutProps.layoutType);
  const shouldUseDescriptionColor =
    props.useDescriptionColor || !isLayoutTextOnImage(layoutProps.layoutType);
  const className = classNames(
    styles.container,
    isMobile && styles.mobile,
    isMobile && `blog-post-${layoutProps.section}-background-color`,
    shouldUseDescriptionColor
      ? `blog-post-${layoutProps.section}-description-color`
      : 'blog-text-color',
    props.containerClassName,
  );
  const arrowFillClass = shouldUseDescriptionColor
    ? `blog-post-${layoutProps.section}-description-fill`
    : undefined;
  const fontClassName = `blog-post-${layoutProps.section}-description-style-font`;

  return (
    <div className={className}>
      <BasePagination
        activeClass={props.activeClass}
        page={props.page}
        pageSize={props.pageSize}
        entityCount={props.entityCount}
        showPosition={
          (isMobile || rootWidth! < MAX_WIDTH_WITH_PAGE_NUMBERS) &&
          (props.showPagination ?? true)
        }
        arrowFillClass={arrowFillClass}
        onChange={props.onChange}
        pageClass={fontClassName}
        positionClass={fontClassName}
        component={createPageLink(
          props.createPageUrl ?? (() => ''),
          props.handleNavigation,
        )}
      />
    </div>
  );
};

export default Pagination;
