import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import {
  SideBySideLayout,
  getPostCover,
  resolveId,
  type MixedLayoutTypeName,
} from '@wix/communities-blog-client-common';
import useAuth from '@app/external/common/hooks/use-auth';
import { useFeedBorderWidth } from '@app/external/common/hooks/use-feed-border-width';
import { useFeedMetadataSettings } from '@app/external/common/hooks/use-feed-metadata-settings';
import useFontClassName from '@app/external/common/hooks/use-font-class-name';
import useIsFeedDesignEnabled from '@app/external/common/hooks/use-is-feed-design-enabled';
import useLayoutColorClasses from '@app/external/common/hooks/use-layout-color-classes';
import usePermissions from '@app/external/common/hooks/use-permissions';
import { type AppState } from '@app/external/common/types';
import { CommentCountCompact } from '../../../../comments/components/comment-count-compact';
import { ThreeDotsIcon } from '../../../../common/components/icons/three-dots-icon';
import { LikeButtonWithCount } from '../../../../common/components/like-button-with-count';
import Link from '../../../../common/components/link/internal-link';
import { MoreButton } from '../../../../common/components/more-button';
import { importPostActions } from '../../../../common/components/post-actions';
import PostHeaderIcons from '../../../../common/components/post-header-icons';
import PostListItemCategoryLabel from '../../../../common/components/post-list-item-category-label';
import PostListItemCover from '../../../../common/components/post-list-item-cover';
import PostListItemHeader from '../../../../common/components/post-list-item-header';
import PostTitle from '../../../../common/components/post-title';
import RatingsDisplay, {
  RatingsDisplayLayout,
} from '../../../../common/components/ratings-display';
import {
  connect,
  useSelector,
} from '../../../../common/components/runtime-context';
import { HorizontalSeparatorForPostCard } from '../../../../common/components/separator';
import ViewCountCompact from '../../../../common/components/view-count-compact';
import withPostFontSize from '../../../../common/hoc/with-post-font-size';
import {
  getSBSLayoutImagePosition,
  getIsMoreButtonEnabled,
  getMobileContentAlignment,
} from '../../../../common/selectors/app-settings-selectors';
import { getSection } from '../../../../common/selectors/section-selectors';
import { getContentAlignmentStyles } from '../../../../common/services/content-alignment-helpers';
import {
  getLineCounts,
  getFeedColorClassName,
} from '../../../../common/services/layout-config';
import { getPostActions } from '../../../../common/services/post-actions';
import { getIsRTL } from '../../../../common/store/basic-params/basic-params-selectors';
import { getIsMemberAreaInstalled } from '../../../../common/store/communities-context/communities-context-selectors';
import {
  getViewCount,
  getCommentCount,
} from '../../../../common/store/post-counters/post-counters-selectors';
import {
  getPostAverageRating,
  getPostTotalRatings,
} from '../../../../common/store/post-ratings/post-ratings-selector';
import alignmentStyles from '../../../../common/styles/post-list-item-alignment.scss';
import postListItemStyles from '../../../../common/styles/post-list-item.scss';
import styles from './side-by-side.scss';

interface PostListItemSideBySideProps {
  itemConfig: any;
  onLikeClick: (postId: string) => void;
  post: any;
  postMetadataFontSize: number;
  postTitleFontSize: number;
  sideBySideLayoutImagePosition: SideBySideLayout;
  type: MixedLayoutTypeName;
  viewCount: number;
  totalComments: number;
  alignment: string;
  isRTL: boolean;
  postTotalRatings: number;
  postAverageRating: number;
}

export const PostListItemSideBySide: React.FC<PostListItemSideBySideProps> = ({
  itemConfig,
  onLikeClick,
  post,
  postMetadataFontSize,
  postTitleFontSize,
  sideBySideLayoutImagePosition,
  type,
  viewCount,
  totalComments,
  alignment,
  isRTL,
  postTotalRatings,
  postAverageRating,
}) => {
  const { iconColorClassName } = useLayoutColorClasses();
  const { can, canSee } = usePermissions();
  const {
    showViewCount,
    showCommentCount,
    showLikeCount,
    showAuthorName,
    showPublishDate,
    showReadingTime,
    showMoreOptionsMenu,
    showCategoryLabel,
    showPostRating,
  } = useFeedMetadataSettings({ post });
  const { forPublicUser } = useAuth();
  const { getPostClassName, section } = useIsFeedDesignEnabled();
  const { borderWidth } = useFeedBorderWidth();
  const { titleFontClassName, contentFontClassName } = useFontClassName();
  const { shouldRender: withCover } = getPostCover(post);
  const lineCounts = getLineCounts(itemConfig, withCover);
  const postLink = `/${post.slug}`;
  const isMoreButtonEnabled = useSelector((state) => {
    const postActions = getPostActions({
      post,
      canSee,
      enableShare: true,
      enableSubscribe: getIsMemberAreaInstalled(state),
    });
    const showMoreButton = Boolean(postActions.length);

    return {
      isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
    };
  });

  const containerClassName = classNames(
    styles.container,
    alignmentStyles[alignment],
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    getPostClassName(
      'border-color',
      'post-container',
      getFeedColorClassName(type, 'background-color'),
    ),
  );

  const contentWrapperClassName = classNames(
    styles.flexContainer,
    sideBySideLayoutImagePosition === SideBySideLayout.ImageLeft &&
      styles.pullImageLeft,
    isRTL && alignmentStyles.isRTL,
  );

  const linkClassName = classNames(
    styles.textWrapper,
    post.isPinned && styles.withIcons,
  );
  const headerClassName = classNames(
    styles.header,
    contentFontClassName,
    alignmentStyles.headerContainer,
  );

  const titleStyle = { fontSize: postTitleFontSize };
  const showCommentStats =
    showCommentCount && (totalComments > 0 || !post.isCommentsDisabled);
  const moreButtonId = `more-button-${resolveId(post)}`;

  const footerClassName = classNames(
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
    ),
    styles.footer,
  );

  const countersVisible = showLikeCount || showCommentStats || showViewCount;
  const isImagePositionNone =
    sideBySideLayoutImagePosition === SideBySideLayout.ImageNone;
  const showFooter =
    isMoreButtonEnabled || countersVisible || !isImagePositionNone;
  const showMoreButtonInHeading =
    isMoreButtonEnabled && !countersVisible && isImagePositionNone;

  const showPostCover = getPostCover(post).shouldRender && !isImagePositionNone;

  const coverWrapperClassName = classNames(
    styles.imageContainer,
    sideBySideLayoutImagePosition === SideBySideLayout.ImageLeft &&
      styles.rightMargin,
  );

  const moreButtonIcon = (
    <ThreeDotsIcon
      className={classNames(
        iconColorClassName,
        'blog-hover-container-element-fill',
      )}
    />
  );

  const isMoreButtonInHeadingEnabled =
    showMoreOptionsMenu &&
    showMoreButtonInHeading &&
    !showFooter &&
    isMoreButtonEnabled;
  const showHeader =
    isMoreButtonInHeadingEnabled ||
    showAuthorName ||
    showReadingTime ||
    showPublishDate;
  const separatorClass = showFooter
    ? styles.separator
    : classNames(styles.separator, styles.withoutFooter);

  return (
    <article
      className={containerClassName}
      tabIndex={0}
      style={{ borderWidth }}
      data-hook="post-list-item"
    >
      <div className={contentWrapperClassName}>
        <div
          className={classNames(
            styles.titleContainer,
            alignmentStyles.textAlign,
          )}
        >
          <Link to={postLink} className={linkClassName} addHoverClasses={false}>
            <PostTitle
              type={type}
              title={post.title}
              lineCount={lineCounts.title}
              style={titleStyle}
              className={classNames(
                styles.title,
                titleFontClassName,
                showCategoryLabel && styles.withCategoryLabel,
              )}
            />
          </Link>
          {showCategoryLabel && (
            <PostListItemCategoryLabel
              className={alignmentStyles.categoryLabel}
              post={post}
              postListLayout={type}
            />
          )}
          {showPostRating && postTotalRatings ? (
            <RatingsDisplay
              className={classNames(
                postListItemStyles.rating,
                postListItemStyles[section],
                styles.rating,
                styles[type],
                alignmentStyles.rating,
              )}
              rating={postAverageRating}
              count={postTotalRatings}
              layout={RatingsDisplayLayout.with_range}
            />
          ) : null}
          {showHeader && (
            <PostListItemHeader
              className={headerClassName}
              post={post}
              showHeaderIcons={false}
              showMoreButton={isMoreButtonInHeadingEnabled}
              showProfileImage={false}
              style={{ fontSize: postMetadataFontSize }}
              moreButtonIcon={moreButtonIcon}
              moreButtonClass={styles.moreButtonInHeading}
              type={type}
            />
          )}
        </div>
        {showPostCover && (
          <div className={coverWrapperClassName}>
            <PostListItemCover
              post={post}
              postLink={postLink}
              type={type}
              canPlayVideo
              isPublic={can('share', 'post', post)}
              videoClassName={styles.videoEmbed}
              imageClassName={styles.image}
              isMobile
            />
          </div>
        )}
      </div>
      {showFooter && (
        <div className={footerClassName}>
          {countersVisible && (
            <div
              className={styles.socialButtons}
              style={{ fontSize: postMetadataFontSize }}
            >
              {showLikeCount && (
                <div className={styles.socialButton}>
                  <LikeButtonWithCount
                    onClick={() =>
                      forPublicUser(() => onLikeClick(resolveId(post)))
                    }
                    className="post-footer__like-button"
                    showZero
                    switchCounterAndButton
                    postId={resolveId(post)}
                  />
                </div>
              )}
              {showViewCount && (
                <div
                  className={classNames(
                    'description-font',
                    styles.socialButton,
                  )}
                >
                  <ViewCountCompact count={viewCount} />
                </div>
              )}
              {showCommentStats && (
                <div
                  className={classNames(
                    'description-font',
                    styles.socialButton,
                  )}
                >
                  <CommentCountCompact count={totalComments} showZero />
                </div>
              )}
            </div>
          )}
          <PostHeaderIcons
            post={post}
            className={styles.icons}
            iconClassName="blog-button-fill"
          />
          {isMoreButtonEnabled && (
            <div className="blog-more-icon-fill">
              <MoreButton
                className={styles.moreButton}
                id={moreButtonId}
                icon={moreButtonIcon}
              >
                {async () => {
                  const PostActions = await importPostActions();
                  return <PostActions post={post} />;
                }}
              </MoreButton>
            </div>
          )}
        </div>
      )}
      <HorizontalSeparatorForPostCard className={separatorClass} />
    </article>
  );
};

const mapRuntimeToProps = (state: AppState, { post }: any) => {
  const isRTL = getIsRTL(state);
  const contentAlignmentMobile = getMobileContentAlignment(state, isRTL);
  const section = getSection(state)!;
  const postId = resolveId(post);

  return {
    section,
    sideBySideLayoutImagePosition: getSBSLayoutImagePosition(state, section),
    viewCount: getViewCount(state, postId),
    totalComments: getCommentCount(state, postId),
    alignment: getContentAlignmentStyles(contentAlignmentMobile, isRTL),
    isRTL,
    postAverageRating: getPostAverageRating(state, resolveId(post)),
    postTotalRatings: getPostTotalRatings(state, resolveId(post)),
  };
};

export default flowRight(
  withPostFontSize,
  connect(mapRuntimeToProps),
)(PostListItemSideBySide);
