import type { RawParams } from '@wix/tpa-router';

import { EGroupPartition } from 'api/groups/types';

import type { StateDeclarationFn } from 'controller/types';

export default (function (vm) {
  return {
    name: 'my-groups.groups',
    url: '',
    data: {
      title: 'my-groups.groups',
      sectionId: 'my-groups',
    },
    resolve: [
      {
        token: 'groups',
        deps: ['$stateParams'],
        async resolveFn(params: RawParams) {
          return vm.groups$.query({
            ...params,
            limit: 6,
            namespace: EGroupPartition.JOINED,
            partition: EGroupPartition.JOINED,
          });
        },
      },
    ],
  };
} as StateDeclarationFn);
