import React from 'react';
import classNames from 'classnames';
import {
  getIsCategoryLabelLayoutText,
  getIsCategoryLabelUppercase,
  shouldUseMobileSettings,
} from '../../../post-list-widget/selectors/category-label-selectors';
import {
  getMainCategory,
  getMainCategoryDisplayLabel,
  getMainCategoryLink,
} from '../../store/categories/categories-selectors';
import { getTopology } from '../../store/topology/topology-selectors';
import { type NormalizedPost } from '../../types';
import CategoryLink from '../link/category-link';
import { useSelector } from '../runtime-context';
import styles from './post-list-item-category-label.scss';

type Props = {
  post: NormalizedPost;
  postListLayout: string;
  className: string;
};

const PostListItemCategoryLabel: React.FC<Props> = ({
  post,
  postListLayout,
  className,
}) => {
  const {
    mainCategory,
    mainCategoryDisplayLabel,
    mainCategoryLink,
    isCategoryLabelLayoutText,
    isCategoryLabelUpperCase,
    useMobileSettings,
    categoryLabelClicked,
  } = useSelector((state, _, actions) => ({
    mainCategory: getMainCategory(state, post),
    mainCategoryDisplayLabel: getMainCategoryDisplayLabel(state, post),
    mainCategoryLink: getMainCategoryLink(state, post, getTopology(state)),
    isCategoryLabelLayoutText: getIsCategoryLabelLayoutText(state),
    isCategoryLabelUpperCase: getIsCategoryLabelUppercase(state),
    useMobileSettings: shouldUseMobileSettings(state),
    categoryLabelClicked: actions.categoryLabelClicked,
  }));

  const label = isCategoryLabelUpperCase
    ? mainCategoryDisplayLabel?.toUpperCase()
    : mainCategoryDisplayLabel;

  if (!label || !mainCategoryLink || !mainCategory?.slug) {
    throw new Error('Category is missing label or link');
  }

  const containerClass = classNames([
    styles.container,
    styles[postListLayout],
    className,
  ]);

  const categoryLabelLayoutClass = classNames([
    useMobileSettings && styles.mobile,
    isCategoryLabelLayoutText
      ? styles.categoryLabelText
      : styles.categoryLabelButton,
  ]);

  const sendMainCategoryClickedBIEvent = () =>
    categoryLabelClicked(mainCategory);

  return (
    <div className={containerClass} data-hook="post-category-label">
      <CategoryLink
        categoryLink={mainCategoryLink}
        categoryPath={mainCategory.slug}
        className={categoryLabelLayoutClass}
        onClick={sendMainCategoryClickedBIEvent}
      >
        {label}
      </CategoryLink>
    </div>
  );
};

export default PostListItemCategoryLabel;
