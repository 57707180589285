import React from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { ClientSideRenderMark } from '../../../contexts/ClientSideRenderMark';
import type { AppWidgetProps } from '../../../types/common';

const DefaultWidget = loadable(() => import('./Widget'));
const PlanListPageWidget = loadable(() => import('./PlanListPageWidget'));

const Widget: React.FC<AppWidgetProps> = (props) => {
  return (
    <ClientSideRenderMark>
      {props.isMultiPageApp ? <PlanListPageWidget {...props} /> : <DefaultWidget {...props} />}
    </ClientSideRenderMark>
  );
};

export default Widget;
