import { EditorScriptContext } from './types';

interface ProgressBarParams {
  title: string;
  initialStepTitle?: string;
  totalSteps: number;
}

export async function showProgressBar(
  { editorSDK }: EditorScriptContext,
  { title, initialStepTitle, totalSteps }: ProgressBarParams,
) {
  let isProgressBarOpen = false;
  let currentStep = 0;
  let currentStepTitle = initialStepTitle;

  try {
    await editorSDK.editor.openProgressBar('', {
      title,
      totalSteps,
      currentStep,
      stepTitle: currentStepTitle,
    });

    // NOTE: openProgressBar() can fail/reject if there is already an open progress bar
    isProgressBarOpen = true;
  } catch {}

  const setNextProgressBarStep = async (stepTitle?: string) => {
    if (stepTitle) {
      currentStepTitle = stepTitle;
    }

    if (isProgressBarOpen) {
      await editorSDK.editor.updateProgressBar('', {
        currentStep: ++currentStep,
        stepTitle: currentStepTitle,
      });
    }
  };

  const hideProgressBar = async (error?: unknown) => {
    if (isProgressBarOpen) {
      await editorSDK.editor.closeProgressBar('', {
        isError: !!error,
      });
    }
  };

  return {
    setNextProgressBarStep,
    hideProgressBar,
  };
}
