import { type EditorAppContext } from '../../../types/editor-app-context.type';
import { blogAppDefId } from '../constants/apps';

const find = async ({
  sdk,
  appToken,
  tpaPageId,
}: EditorAppContext & { tpaPageId: string }) => {
  const res = await Promise.all([
    sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId),
    sdk.pages.data.getAll(appToken),
  ]);
  const blogAppData = res[0] || {};
  const allPages = res[1] || [];
  return allPages.find(
    (page) =>
      page.tpaPageId === tpaPageId &&
      page.tpaApplicationId === blogAppData.applicationId,
  );
};

export default { find };
