import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { BUTTON_CREATE_POST } from '../../bi-events/bi-buttons';
import { connect } from '../../components/runtime-context';
import withAuth from '../../hoc/with-auth';
import { getQueryLocale } from '../../selectors/locale-selectors';
import { getIsMobile } from '../../store/basic-params/basic-params-selectors';
import { getMetaSiteId } from '../../store/instance-values/instance-values-selectors';

export const CreatePostHandler = ({
  categorySlug,
  children,
  buttonClicked,
  forPublicUser,
  navigateWithinPostPage,
}) => {
  const handleClick = () => {
    buttonClicked({ action: BUTTON_CREATE_POST });
    return navigateWithinPostPage(
      `/create-post${categorySlug ? `?categorySlug=${categorySlug}` : ''}`,
    );
  };

  const handleClickDecorated = (...args) => {
    return forPublicUser(handleClick)(...args);
  };

  const child = React.Children.only(children);
  return React.cloneElement(child, { onClick: handleClickDecorated });
};

CreatePostHandler.propTypes = {
  categorySlug: PropTypes.string,
  children: PropTypes.node.isRequired,
  buttonClicked: PropTypes.func,
  forPublicUser: PropTypes.func,
  navigateWithinPostPage: PropTypes.func.isRequired,
  metaSiteId: PropTypes.object,
  lang: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  buttonClicked: actions.buttonClicked,
  navigateWithinPostPage: actions.navigateWithinPostPage,
  metaSiteId: getMetaSiteId(state),
  lang: getQueryLocale(state),
  isMobile: getIsMobile(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
)(CreatePostHandler);
