import { PLATFORMISED_API_URL } from '../constants/api';
import { type PlatformApi } from '../controller/platform-api';
import getEnvironment from './get-environment';

const getAPIUrl = (platformApi: PlatformApi) => {
  if (platformApi.window.rendering.env !== 'backend') {
    const { isPreview } = getEnvironment(platformApi);
    if (!isPreview) {
      return `${platformApi.location.baseUrl}${PLATFORMISED_API_URL}`;
    }
    return PLATFORMISED_API_URL;
  }

  const baseUrl = platformApi.location.baseUrl;
  if (baseUrl.includes('wixsite.com')) {
    return `${baseUrl.substring(
      0,
      baseUrl.lastIndexOf('/'),
    )}${PLATFORMISED_API_URL}`;
  }

  return `${baseUrl.replace(
    /(https?:\/\/[^/]+)\/.+/,
    '$1',
  )}${PLATFORMISED_API_URL}`;
};

export default {
  getAPIUrl,
};
