import {
  BLOG_APP_DEBUG,
  LOG_BI_TO_CONSOLE,
  LOG_REDUX_ACTIONS,
  LOG_BLOG_POST_EDITOR_ACTIONS,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  AMP_BASE_URLS,
  WP_BLOGS,
} from '@wix/communities-universal/dist/src/constants/wix-blogs';
import {
  setAppConfig as setAppConfigAction,
  GLOBALS,
} from '@wix/communities-blog-client-common';
import {
  type CommonThunkArgs,
  type CommonThunkAction,
} from '@app/types/common-thunk-action.type';
import { type PlatformApi } from '../../controller/platform-api';
import getEnvironment from '../../services/get-environment';
import { type AppState } from '../../types';
import { getTopology } from '../topology/topology-selectors';

export function setAppConfig({
  bundleName,
}: {
  bundleName: string;
}): CommonThunkAction<void> {
  return async (dispatch, getState, { platformApi, appParams }) => {
    dispatch(
      setAppConfigAction(
        getConfig({
          appParams,
          platformApi,
          state: getState(),
          bundleName,
        }),
      ),
    );
  };
}

function getConfig({
  appParams,
  platformApi,
  state,
  bundleName,
}: {
  appParams: CommonThunkArgs['appParams'];
  platformApi: PlatformApi;
  state: AppState;
  bundleName: string;
}) {
  const isProduction = process.env.NODE_ENV === 'production';
  const { isEditor, isPreview } = getEnvironment(platformApi);

  const topology = getTopology(state);

  const developmentConfig = {
    isDevMode: !isProduction,
    debugEnabled: BLOG_APP_DEBUG,
    logBiToConsole: LOG_BI_TO_CONSOLE,
    logReduxActions: LOG_REDUX_ACTIONS,
    logPostEditorActions: LOG_BLOG_POST_EDITOR_ACTIONS,
  };
  const isSeo = platformApi.seo.isInSEO();
  const isWP = WP_BLOGS.includes(appParams.instanceId!);

  const { baseUrl, postPageSectionUrl } = topology;
  // @ts-expect-error
  const isAmpEnabled = state?.tpaSettings?.settings?.ampEnabled ?? false;
  const ampBaseUrl = `${baseUrl}/amp`;
  const wpAmpBaseUrl = AMP_BASE_URLS[appParams.instanceId!];

  const clientConfig = {
    bundleName,
    imageHost: GLOBALS.MEDIA_IMAGE_HOST,
    videoHost: GLOBALS.MEDIA_VIDEO_HOST,
    instanceId: appParams.instanceId,
    duplexer: {
      url: GLOBALS.DUPLEXER_URL,
    },
    isInEditor: isEditor,
    isInPreview: isPreview,
    isWP,
    categoryPath: isWP ? 'category' : 'categories',
    useCategoryMenuLabelForMetadataTitle: isWP && !isSeo,
    ampBaseUrl: isAmpEnabled && (wpAmpBaseUrl || (baseUrl && ampBaseUrl)),
    postPageSectionUrl,
    ...developmentConfig,
  };

  return clientConfig;
}
