import { type ThunkAction, type ThunkActionDispatch } from 'redux-thunk';
import { resolvePromisifiedAction } from './store/promisified-actions-actions';

type DataSelectorFn<T> = (result: any) => T;
type ErrorSelectorFn<T> = (err: any) => T;

/** Actions accepting 1 argument */
export function createPromisifiedAction<
  ActionCreatorFn extends (
    arg0: Parameters<ActionCreatorFn>[0],
  ) => ThunkAction<any, any, any, any>,
  T,
>(
  action: ActionCreatorFn,
  dataSelector: DataSelectorFn<T>,
  errorSelector?: ErrorSelectorFn<T>,
): (
  arg0: Parameters<ActionCreatorFn>[0],
  correlationId?: any,
) => (dispatch: ThunkActionDispatch<any>) => Promise<T>;
/** Actions accepting 2 arguments */
export function createPromisifiedAction<
  ActionCreatorFn extends (
    arg0: Parameters<ActionCreatorFn>[0],
    arg1: Parameters<ActionCreatorFn>[1],
  ) => ThunkAction<any, any, any, any>,
  T,
>(
  action: ActionCreatorFn,
  dataSelector: DataSelectorFn<T>,
  errorSelector?: ErrorSelectorFn<T>,
): (
  arg0: Parameters<ActionCreatorFn>[0],
  arg1: Parameters<ActionCreatorFn>[1],
  correlationId?: any,
) => (dispatch: ThunkActionDispatch<any>) => Promise<T>;
/** Actions accepting 3 arguments */
export function createPromisifiedAction<
  ActionCreatorFn extends (
    arg0: Parameters<ActionCreatorFn>[0],
    arg1: Parameters<ActionCreatorFn>[1],
    arg2: Parameters<ActionCreatorFn>[2],
  ) => ThunkAction<any, any, any, any>,
  T,
>(
  action: ActionCreatorFn,
  dataSelector: DataSelectorFn<T>,
  errorSelector?: ErrorSelectorFn<T>,
): (
  arg0: Parameters<ActionCreatorFn>[0],
  arg1: Parameters<ActionCreatorFn>[1],
  arg2: Parameters<ActionCreatorFn>[2],
  correlationId?: any,
) => (dispatch: ThunkActionDispatch<any>) => Promise<T>;
/** Actions accepting 4 arguments */
export function createPromisifiedAction<
  ActionCreatorFn extends (
    arg0: Parameters<ActionCreatorFn>[0],
    arg1: Parameters<ActionCreatorFn>[1],
    arg2: Parameters<ActionCreatorFn>[2],
    arg3: Parameters<ActionCreatorFn>[3],
  ) => ThunkAction<any, any, any, any>,
  T,
>(
  action: ActionCreatorFn,
  dataSelector: DataSelectorFn<T>,
  errorSelector?: ErrorSelectorFn<T>,
): (
  arg0: Parameters<ActionCreatorFn>[0],
  arg1: Parameters<ActionCreatorFn>[1],
  arg2: Parameters<ActionCreatorFn>[2],
  arg3: Parameters<ActionCreatorFn>[3],
  correlationId?: any,
) => (dispatch: ThunkActionDispatch<any>) => Promise<T>;
/** Actions accepting n arguments */
export function createPromisifiedAction<
  ActionCreatorFn extends (...args: any[]) => ThunkAction<any, any, any, any>,
  T,
>(
  action: ActionCreatorFn,
  dataSelector: DataSelectorFn<T>,
  errorSelector: ErrorSelectorFn<T> = (e: any) => e,
): (
  arg0: Parameters<ActionCreatorFn>[0],
  correlationId?: any,
) => (dispatch: ThunkActionDispatch<any>) => Promise<T> {
  return (args: Parameters<ActionCreatorFn>, correlationId?: any) =>
    (dispatch: ThunkActionDispatch<any>) => {
      return dispatch(action(...args)).then(
        (result: any) => {
          return dispatch(
            resolvePromisifiedAction(true, correlationId, dataSelector(result)),
          );
        },
        (err: any) => {
          return dispatch(
            resolvePromisifiedAction(false, correlationId, errorSelector(err)),
          );
        },
      );
    };
}
