import React from 'react';
import { FCWithChildren } from '@wix/challenges-web-library';
import { Text, TextTypography, Image } from 'wix-ui-tpa/cssVars';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { ButtonNames } from '../../../../../../../../contexts/main/biInterfaces';
import { useSettings } from '@wix/tpa-settings/react';
import {
  BidirectionalAlignment,
  TextAlignment,
} from '../../../../../../Settings/challengeSettings/challengeSettings.types';
import settingsParams from '../../../../../../settingsParams';
import { SettingsKeysAbbreviation } from '../../../../../../Settings/constants/settingsKeysAbbreviation';
import { ImageShape } from '../../../../../../../ChallengesList/Settings/challengeSettings/challengeSettings.types';
import { visitorPageDataHooks } from '../../../VisitorPage';
import { st, classes } from './VisitorPageInstructors.st.css';
import { useChallengeData } from '../../../../../../../../contexts/storage-contexts/Challenge';

interface IVisitorPageInstructorsProps {
  className?: string;
  alignment: TextAlignment | BidirectionalAlignment;
  layout?: 'box' | 'column';
}

export const VisitorPageInstructors: FCWithChildren<
  IVisitorPageInstructorsProps
> = ({ className = null, alignment, layout = 'box' }) => {
  const {
    challengeData: { instructorsData },
    instructorUrls,
  } = useChallengeData();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const imageShape: ImageShape = settings.get(
    settingsParams[
      SettingsKeysAbbreviation.visitorPageAboutInstructorImageShape
    ],
  );
  const bi = useBi();

  const sendOnClickBI = React.useCallback(() => {
    void bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.GoToInstructorProfile,
      }),
    );
  }, []);

  return (
    <ul
      data-hook={visitorPageDataHooks.author()}
      className={st(
        classes.root,
        {
          mobile: isMobile,
          alignment,
          layout,
          imageShape,
        },
        className,
      )}
    >
      {(instructorsData?.instructors || []).map((instr) => {
        const instructorProfileURL = instructorUrls[instr.id];

        return (
          <li key={instr.id} className={classes.instructor}>
            <a href={instructorProfileURL} onClick={sendOnClickBI}>
              {instr.photo?.url ? (
                <Image
                  className={classes.instructorAvatar}
                  aspectRatio={1}
                  width={isMobile ? 60 : 90}
                  height={isMobile ? 60 : 90}
                  fluid={true}
                  src={instr.photo?.url || null}
                  alt={instr.photoAltText}
                />
              ) : null}

              <div className={classes.instructorContent}>
                <Text
                  className={classes.instructorName}
                  typography={TextTypography.runningText}
                >
                  {instr.name}
                </Text>
              </div>
            </a>
          </li>
        );
      })}
    </ul>
  );
};
