import type { UIRouter } from '@wix/tpa-router';

import type { IGroup } from 'api/groups/types';

export function groupSlug(router: UIRouter) {
  router.transitionService.onFinish(
    { to: true },
    async function (transition) {
      const params = transition.params();
      const injector = transition.injector();
      const group: IGroup = await injector.getAsync('group');

      if (group.slug !== params.slug) {
        return transition
          .targetState()
          .withParams({ slug: group.slug })
          .withOptions({
            location: 'replace',
            custom: { retain: true },
          });
      }
    },
    { priority: 999 },
  );
}
