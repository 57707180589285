import React from 'react';

import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { st, classes } from './StepsListAsTiles.st.css';

import { StepSidebar } from './StepSidebar';

import { ScheduledStepsList } from './ScheduledStepsList';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IStepsListAsTilesProps {
  dataHook?: string;
  steps: ParticipantStep[];
  isSPC: boolean;
  currentStepId: string;
  onStepChosen(step: ParticipantStep): void;
  isSection?: boolean;
}

export const StepsListAsTiles: FCWithChildren<IStepsListAsTilesProps> = (
  props,
) => {
  const { steps = [], isSPC } = props;

  const { isMobile } = useEnvironment();

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
      })}
      data-hook={props.dataHook || null}
      dir="auto"
    >
      {isSPC ? (
        <ul className={classes.stepsList}>
          {steps.map((step, ind) => {
            return (
              <StepSidebar
                key={ind}
                step={step}
                isSPC={props.isSPC}
                currentStepId={props.currentStepId}
                onStepChosen={props.onStepChosen}
              />
            );
          })}
        </ul>
      ) : (
        <ScheduledStepsList
          steps={steps}
          isSPC={props.isSPC}
          currentStepId={props.currentStepId}
          onStepChosen={props.onStepChosen}
        />
      )}
    </div>
  );
};

StepsListAsTiles.displayName = 'StepsListAsTiles';
