import { get } from 'lodash';
import { type CreateControllerFn } from '@wix/yoshi-flow-editor';
import {
  createPerformanceTracker,
  getWixData,
  setWixDataSuccess,
} from '@wix/communities-blog-client-common';
import { type WithReduxStoreOwnProps } from '../../common/components/runtime-context/with-redux-store';
import {
  createControllerId,
  createLogger,
} from '../../common/controller/helpers';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { getPlatformApi } from '../../common/controller/platform-api';
import { createMonitoring } from '../../common/services/create-monitoring';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';
import { simulateControllerError } from '../../common/services/simulate-error';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { type AppStore } from '../../common/types';
import {
  fetchPostListPosts,
  fetchPostListPostsPromisified,
} from '../actions/fetch-initial-data';
import { POST_LIST_WIDGET_BUNDLE_NAME } from '../constants/post-list-widget';
import { createReduxStore } from './create-redux-store';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';

const isProduction = process.env.NODE_ENV === 'production';

type RootProps = WithReduxStoreOwnProps & {
  isRTL: boolean;
  fitToContentHeight: boolean;
};

export const createPostListController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}) => {
  const { appParams, setProps, platformApiProvider } = controllerConfig;

  const platformApi = await getPlatformApi(platformApiProvider);
  const { isSSR, isDebug } = getEnvironment(platformApi);

  const { fedopsLogger } = createMonitoring(flowAPI);

  const language = getCurrentSiteLanguage(platformApi, flowAPI);

  const bundleName = POST_LIST_WIDGET_BUNDLE_NAME;

  const log = createLogger(isDebug, isProduction);

  log('createPostListController', {
    appParams,
    platformApi,
    isSSR,
    language,
  });

  let store: AppStore;

  const pageReady = async () => {
    const controllerId = createControllerId();
    const perfTag = `${bundleName} ${controllerId}`;
    const perf = createPerformanceTracker(perfTag, { isDebug, isSSR });
    const pageReadyMarker = perf.trackStart(
      `${new Date().toISOString().slice(11)} pageReady`,
    );
    log('createPostListController.pageReady -> start');

    simulateControllerError(platformApi, 'post-list.pageReady');

    initLazyActions({ isSSR });

    let marker = perf.trackStart('createReduxStore', pageReadyMarker);
    store = createReduxStore({
      controllerConfig,
      platformApi,
      flowAPI,
      fedopsLogger,
    });
    perf.trackEnd(marker);

    marker = perf.trackStart('initializeActions', pageReadyMarker);
    const actions = initializeActions({
      platformApi,
      store,
      fedopsLogger,
    });
    const actionsPromisified = initializePromisifiedActions(
      { store },
      { fetchPostListPostsPromisified },
    );
    perf.trackEnd(marker);

    await perf.trackPromise(
      'initializeStoreBaseData',
      () =>
        initializeStoreBaseData({
          controllerConfig,
          platformApi,
          flowAPI,
          store,
        }),
      pageReadyMarker,
    );
    listenToInstanceChange(platformApi, appParams, store);

    log('createPostListController.pageReady -> done');
    const state = store.getState();

    const stateVersions = getInitialStateVersions(state);

    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
      fitToContentHeight: true,
    } satisfies RootProps);

    refreshDataOnLogin({ platformApi, store, router: undefined });
    subscribeToChange(store, stateVersions, setProps, controllerId);
    perf.trackEnd(pageReadyMarker);
  };

  return {
    pageReady,
    exports: () => {},
    updateConfig: (_$w, { style: { styleParams }, publicData }) => {
      if (!store) {
        return;
      }
      store.dispatch(setAppSettings({ style: styleParams }));

      const oldComponentData = getWixData(store.getState()) || {};
      const newComponentData = get(publicData, 'COMPONENT', {});

      const oldTagId = oldComponentData.tagId ?? undefined;
      const newTagId = newComponentData.tagId ?? undefined;

      const oldCategoryId = oldComponentData.categoryId ?? undefined;
      const newCategoryId = newComponentData.categoryId ?? undefined;

      if (oldTagId !== newTagId || oldCategoryId !== newCategoryId) {
        store.dispatch(setWixDataSuccess(newComponentData));
        store.dispatch(fetchPostListPosts());
      }
    },
  };
};
