import React from 'react';
import classNames from 'classnames';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import type { Tag } from '@wix/ambassador-blog-v3-tag/types';
import { clickOnTag } from '@wix/bi-logger-blog-data/v2';
import styles from './index.scss';

type Props = {
  tags: Tag[];
  feedBaseUrl: string;
};

const Root = (props: Props) => {
  const { isRTL } = useEnvironment();
  const { t } = useTranslation();

  return (
    <nav
      dir={isRTL ? 'rtl' : 'ltr'}
      aria-label={t('tag-cloud.aria-label')}
      data-hook="tag-cloud-root"
    >
      <ul
        className={classNames(styles.list, {
          [styles.empty]: props.tags.length === 0,
        })}
      >
        {props.tags.length > 0 ? (
          props.tags.map((tag) => (
            <li key={tag.id}>
              <TagCloudTag tag={tag} feedBaseUrl={props.feedBaseUrl} />
            </li>
          ))
        ) : (
          <li>{t('empty-state.no-tags')}</li>
        )}
      </ul>
      <span hidden data-hook="blog-loaded-indicator" />
    </nav>
  );
};

const TagCloudTag = (props: { tag: Tag; feedBaseUrl: string }) => {
  const { t } = useTranslation();
  const bi = useBi();
  const href = `${props.feedBaseUrl}/tags/${props.tag.slug}`;

  const handleClickForBi: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    bi.report(
      clickOnTag({
        tag_name: props.tag.label,
        tagid: props.tag.id,
      }),
    );
  };

  return (
    <a
      onClick={handleClickForBi}
      href={href}
      className={styles.tag}
      rel="noopener noreferrer"
    >
      {props.tag.label}{' '}
      <span className={styles.count} aria-hidden="true">
        {t('tag-link.post-count', { count: props.tag.publishedPostCount })}
      </span>
      <span className={styles.visuallyHidden}>
        {t('tag-link.a11y-post-count', {
          count: props.tag.publishedPostCount,
        })}
      </span>
    </a>
  );
};

export default Root;
