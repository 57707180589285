import { createStylesParam, StyleParamType } from '@wix/tpa-settings';

export const isMobileDisplaySettingsEnabled = createStylesParam(
  'blog-mobile-isMobileDisplaySettingsEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
);

export const showAuthorName = createStylesParam('blog-isAuthorNameEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showAuthorPicture = createStylesParam(
  'blog-isAuthorPictureEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showAuthorNameMobile = createStylesParam(
  'blog-mobile-isAuthorNameEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showAuthorPictureMobile = createStylesParam(
  'blog-mobile-isAuthorPictureEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showPostPublishDate = createStylesParam(
  'blog-isPostPublishDateEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showPostPublishDateMobile = createStylesParam(
  'blog-mobile-isPostPublishDateEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showReadingTime = createStylesParam('blog-isReadingTimeEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showReadingTimeMobile = createStylesParam(
  'blog-mobile-isReadingTimeEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showPostTitle = createStylesParam('blog-isPostTitleEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showPostTitleMobile = createStylesParam(
  'blog-mobile-isPostTitleEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showPostUpdatedDate = createStylesParam(
  'blog-isPostUpdatedDateEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showPostUpdatedDateMobile = createStylesParam(
  'blog-mobile-isPostUpdatedDateEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showCategoryLabel = createStylesParam(
  'blog-isCategoryLabelEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showCategoryLabelMobile = createStylesParam(
  'blog-mobile-isCategoryLabelEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showCategoryLabels = createStylesParam(
  'blog-isCategoryLabelsEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showCategoryLabelsMobile = createStylesParam(
  'blog-mobile-isCategoryLabelsEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showTags = createStylesParam('blog-isTagsEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showTagsMobile = createStylesParam('blog-mobile-isTagsEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showMoreOptionsMenu = createStylesParam(
  'blog-isMoreOptionsMenuEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showMoreOptionsMenuMobile = createStylesParam(
  'blog-mobile-isMoreOptionsMenuEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showCommentCount = createStylesParam(
  'blog-isCommentCountEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showCommentCountMobile = createStylesParam(
  'blog-mobile-isCommentCountEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showViewCount = createStylesParam('blog-isViewCountEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showViewCountMobile = createStylesParam(
  'blog-mobile-isViewCountEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showLikeCount = createStylesParam('blog-isLikeCountEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showLikeCountMobile = createStylesParam(
  'blog-mobile-isLikeCountEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
);

export const showPostRating = createStylesParam('blog-isPostRatingEnabled', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

export const showPostRatingMobile = createStylesParam(
  'blog-mobile-isPostRatingEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
);
