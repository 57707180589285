import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  getActiveLink,
  getFeedAndCategoryLinks,
} from '../../selectors/category-links-selectors';
import { isSeo as getIsSeo } from '../../store/basic-params/basic-params-selectors';
import { useActions, useSelector } from '../runtime-context';
import ChangeCategoryTrigger from './change-category-trigger';
import SeoSelect from './seo-select';
import styles from './change-category.scss';

export const ChangeCategory: React.FC = () => {
  const links = useSelector(getFeedAndCategoryLinks);
  const activeLink = useSelector(getActiveLink);
  const isSeo = useSelector(getIsSeo);
  const { t } = useTranslation();

  const actions = useActions();

  if (isSeo) {
    return <SeoSelect />;
  }

  return (
    <ChangeCategoryTrigger title={activeLink.text}>
      <select
        data-hook="category-dropdown"
        className={styles.select}
        onChange={(e) => actions.navigateWithinBlog(e.target.value)}
        defaultValue={activeLink.path}
        aria-label={t('category-change.select-name')}
        name={t('category-change.select-name')}
      >
        {links.map((link) => (
          <option key={link.key} value={link.path}>
            {link.text}
          </option>
        ))}
      </select>
    </ChangeCategoryTrigger>
  );
};

export default ChangeCategory;
