import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { classes } from './styles.st.css';
import { st as stNumber, classes as classesNumber } from './numbered.st.css';

const AccordionNumberStrip = ({ index }: { index: number }) => {
  const formattedIndex = (index + 1).toString().padStart(2, '0');
  return (
    <div className={classes.numberwrapper}>
      <Text className={stNumber(classesNumber.number)}>{formattedIndex}</Text>
    </div>
  );
};

export default AccordionNumberStrip;
