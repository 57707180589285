import type { AppData } from '../../viewer.app';
import model from './model';
import type { PublicSettings } from './types';

const selectors = {
  root: '#commentsRoot',
  slotContainer: '#page-bottom-2',
} as const;

export default model.createController(
  ({ $w, $widget, flowAPI, appData: _appData }) => {
    const appData = _appData as AppData;
    let isWidgetOnStage: boolean = false;
    let slotContainer: $w.SlotsPlaceholder;

    const handleEvents = () => {
      const widget = $w(selectors.slotContainer);

      if (widget.slot && widget.slot.onSettingsUpdated) {
        // @ts-expect-error
        widget.slot.onSettingsUpdated(({ ratings, permissions }) => {
          $widget.fireEvent<PublicSettings>('commentsSettingsChanged', {
            ratings,
            permissions,
          });
        });
      }
    };

    return {
      pageReady: async () => {
        await appData.subjects.postPageRenderModel.subscribe((data) => {
          try {
            const root = $w(selectors.root);
            isWidgetOnStage = !root.hidden;

            if (root.hidden) {
              return;
            }

            handleEvents();
            slotContainer = $w(selectors.slotContainer);

            if (data.post.internalId) {
              slotContainer.slot.resourceId = data.post.internalId;
            }
            const shouldLockComments =
              !data.post.commentingEnabled && flowAPI.environment.isViewer;
            if (shouldLockComments && slotContainer.slot.lock) {
              slotContainer.slot.lock();
            }
          } catch (err) {
            console.info(err);
          }
        });
      },

      exports: {
        isCommentsPluginInstalled: (): boolean => {
          if (!isWidgetOnStage) {
            return false;
          }

          try {
            const widget = $w(selectors.slotContainer);

            if (widget.slot) {
              return widget.slot.isMethodSupported('resourceId');
            }
          } catch {
            return false;
          }

          return false;
        },
        getSettings: (): Promise<PublicSettings | undefined> => {
          if (!isWidgetOnStage) {
            return Promise.resolve(undefined);
          }

          try {
            if (slotContainer.slot?.getSettings) {
              return slotContainer.slot.getSettings();
            }
          } catch {
            return Promise.resolve(undefined);
          }

          return Promise.resolve(undefined);
        },
      },
    };
  },
);
