import React from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { resolveId } from '@wix/communities-blog-client-common';
import AnimatedLoader from '@app/external/common/components/animated-loader';
import { useSelector } from '@app/external/common/components/runtime-context';
import { usePostPageSettings } from '@app/external/common/hooks/use-post-page-settings';
import { getIsSeeAllPostsLinkEnabled } from '@app/external/common/selectors/app-settings-selectors';
import { getRecentPosts } from '@app/external/common/selectors/recent-posts-selectors';
import { getRelatedPosts } from '@app/external/common/selectors/related-posts-selectors';
import { getPostListCssVars } from '@app/external/common/services/post-list-css-vars';
import {
  getIsRecentPostsLoaded,
  getIsRelatedPostsLoaded,
} from '@app/external/common/store/is-loaded/is-loaded-selectors';
import type { NormalizedPost } from '@app/external/common/types';
import RecentPosts from '@app/external/recent-posts/components/recent-posts';
import styles from './post-page.scss';

interface RelevantPostsProps {
  post: NormalizedPost;
}

const RelevantPosts: React.FC<RelevantPostsProps> = ({ post }) => {
  const { t } = useTranslation();
  const { showRelatedPosts, showRecentPosts, relatedPostsLabelKey } =
    usePostPageSettings({ post });
  const postId = resolveId(post)!;
  const recentPosts = useSelector((state) => getRecentPosts(state, postId));
  const relatedPosts = useSelector((state) => getRelatedPosts(state, postId));
  const isRecentPostsLoaded = useSelector((state) =>
    getIsRecentPostsLoaded(state, postId),
  );
  const isRelatedPostsLoaded = useSelector((state) =>
    getIsRelatedPostsLoaded(state, postId),
  );
  const showAllPostsLink = useSelector(getIsSeeAllPostsLinkEnabled) !== false;

  const hasRelatedPosts = !isEmpty(relatedPosts);
  const isLoading = hasRelatedPosts
    ? !isRelatedPostsLoaded
    : !isRecentPostsLoaded;

  const shownRecentOrRelatedPosts =
    showRelatedPosts || (showRecentPosts && showRelatedPosts === undefined);

  if (!shownRecentOrRelatedPosts) {
    return null;
  }

  return (
    <AnimatedLoader isLoading={isLoading}>
      <div className={styles.recentPosts} style={getPostListCssVars()}>
        <RecentPosts
          posts={hasRelatedPosts ? relatedPosts : recentPosts}
          title={t(relatedPostsLabelKey!)}
          showAllPostsLink={showAllPostsLink}
        />
      </div>
    </AnimatedLoader>
  );
};

export default RelevantPosts;
