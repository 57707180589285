import React from 'react';
import type { UseFeedMetadataSettings } from '@app/external/common/hooks/use-feed-metadata-settings';
import type { UsePostPageSettings } from '@app/external/common/hooks/use-post-page-settings';

export const Settings = React.createContext({
  settings: {} as UsePostPageSettings | UseFeedMetadataSettings,
});

export const useSettingsContext = () => {
  const settingsContext = React.useContext(Settings);
  return settingsContext.settings;
};
