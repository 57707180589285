import { type Store } from 'redux';
import { type MembersAreaWidgetPluginService } from '@wix/members-area-widget-plugin-lib/dist/types/viewer/members-area-widget-plugin';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { type ControllerConfig } from '@app/external/common/types';
import { getInstance } from '../../common/controller/helpers';
import {
  initializePromisifiedActions as initializeCommonPromisifiedActions,
  initUserDependentStoreBaseData,
} from '../../common/controller/init-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setCommunitiesContext } from '../../common/store/communities-context/communities-context-actions';
import { setCustomRoutes } from '../../common/store/custom-routes/custom-routes-actions';
import { initializeInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import {
  setLocale,
  setQueryLocaleAction,
} from '../../common/store/locale/locale-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import {
  setActiveTabPromisified,
  setActiveTab,
} from '../store/active-tab/active-tab-actions';
import {
  fetchMyPostsPageInitialData,
  fetchMyPostsPageDataPromisified,
} from '../store/initial-data/fetch-initial-data';
import { fetchViewedUser } from '../store/viewed-user/viewed-user-actions';

export {
  initializeActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export function initializePromisifiedActions({ store }: { store: Store }) {
  return initializeCommonPromisifiedActions(
    { store },
    {
      fetchMyPostsPageDataPromisified,
      setActiveTabPromisified,
    },
  );
}

export async function initializeStoreBaseData({
  platformApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  appParams,
  isRendered,
  widgetPluginService,
}: {
  platformApi: PlatformApi;
  store: Store;
  language: string;
  platformAPIs: ControllerConfig['platformAPIs'];
  config: ControllerConfig['config'];
  bundleName: string;
  appParams: ControllerConfig['appParams'];
  isRendered: boolean;
  widgetPluginService: MembersAreaWidgetPluginService;
}) {
  const viewMode = platformApi.window.viewMode.toLowerCase();

  store.dispatch(initializeInstanceValues(getInstance(platformApi)()));

  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(platformApi, appParams));

  await Promise.all([
    initUserDependentStoreBaseData({
      store,
      platformApi,
      allowPreviewInstance: false,
    }),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(
      setBasicParams({
        viewMode,
        language,
        biPageNumber: platformAPIs.bi?.pageNumber,
        isRendered,
      }),
    ),
    store.dispatch(setAppConfig({ bundleName })),
    store.dispatch(setCustomRoutes()),
  ]);

  const instanceId = getInstanceId(store.getState());
  await store.dispatch(fetchTopology(instanceId));
  await store.dispatch(fetchViewedUser(widgetPluginService));
  const tabToNavigate = platformApi.location?.query?.tab;

  if (tabToNavigate) {
    store.dispatch(setActiveTab(tabToNavigate));
  }

  await store.dispatch(fetchMyPostsPageInitialData());
}
