import React from 'react';
import { flowRight } from 'lodash';
import { useTranslation } from '@wix/yoshi-flow-editor';
import withDeviceType, {
  type WithDeviceType,
} from '../../hoc/with-device-type';
import { getVideoEmbedThumbUrl } from '../../services/get-video-embed-thumb-url';
import { PlayArrow } from '../icons/play-arrow';
import { PlayIcon } from '../icons/play-icon';
import styles from './post-list-item-video-thumbnail.scss';

type Oembed = {
  thumbnail_url?: string;
  url?: string;
};

const getStyle = (oembed?: Oembed) => {
  if (!oembed) {
    return undefined;
  }

  return {
    backgroundImage: oembed.thumbnail_url
      ? `url(${getVideoEmbedThumbUrl(oembed)})`
      : `url(${oembed.url})`,
  };
};

type RenderPlayArrowProps = {
  isMobile: boolean;
  withoutLabel?: boolean;
};

const PlayArrowComp = ({ isMobile, withoutLabel }: RenderPlayArrowProps) => {
  const { t } = useTranslation();

  return isMobile && !withoutLabel ? (
    <div className={styles.button}>
      <PlayArrow className={styles.arrow} /> {t('video-embed.load-video')}
    </div>
  ) : (
    <span>
      <PlayIcon className={styles.icon} />
    </span>
  );
};

type PostListItemVideoThumbnailProps = {
  oembed?: Oembed;
  withoutLabel?: boolean;
} & WithDeviceType;

export const PostListItemVideoThumbnail = ({
  oembed,
  isMobile,
  withoutLabel,
}: PostListItemVideoThumbnailProps) => (
  <div className={styles.container}>
    <div className={styles.thumbnail} style={getStyle(oembed)} role="img">
      <div className={styles.overlay} />
      <PlayArrowComp isMobile={isMobile} withoutLabel={withoutLabel} />
    </div>
  </div>
);

export default flowRight(withDeviceType)(PostListItemVideoThumbnail);
