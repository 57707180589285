import { queryReferralRewards } from '@wix/ambassador-loyalty-referral-v1-referral-reward/http';
import { ReferralReward } from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getReferralRewards(flowAPI: ControllerFlowAPI): Promise<ReferralReward[]> {
  const { httpClient } = flowAPI;
  let referralRewards: ReferralReward[] = [];

  // This method fails when user is not a referred friend or referring customer
  try {
    const response = await httpClient.request(
      queryReferralRewards({
        contactId: 'me',
        query: {
          filter: {
            rewardType: 'CouponReward',
          },
        },
      }),
    );

    referralRewards = response.data.referralRewards!;
  } catch {}

  return referralRewards;
}
