import React from 'react';
import { isSite as getIsSite } from '../../store/basic-params/basic-params-selectors';
import { useActions, useSelector } from '../runtime-context';

type Props = {
  path: string;
  sectionId?: string;
  onClick?: () => void;
} & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'>;

const BaseLink: React.FC<Props> = ({
  path,
  sectionId,
  children,
  onClick,
  ...rest
}) => {
  const isSite = useSelector(getIsSite);
  const actions = useActions();

  const handleClick: React.MouseEventHandler = (event) => {
    const urlParams = new URLSearchParams(window.location.search);
    const feedbackLink = urlParams.get('rc') === 'FEEDBACK';

    if ((!isSite || feedbackLink) && sectionId) {
      event.preventDefault();

      if (feedbackLink) {
        actions.navigateProGalleryWithinPostPage(path);
      } else {
        actions.navigateToSectionInPreview(path, sectionId);
      }

      return;
    }

    onClick?.();
  };

  return (
    <a {...rest} onClick={handleClick}>
      {children}
    </a>
  );
};

export default BaseLink;
