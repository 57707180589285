import type { ICaptchaDialog } from '@wix/thunderbolt-commons';
import {
  type IWidgetControllerConfig,
  type IWixWindow,
  type ISiteApis,
  type ILocation,
  type IUserApis,
  type ISeo,
} from '@wix/native-components-infra/dist/src/types/types';

const APIS_TO_LOAD = [
  'location',
  'seo',
  'site',
  'user',
  'window',
  'authentication',
] as const;

/**
 * Replacement for old wixCodeApi, represents the APIs that are loaded from the platform
 *
 * IWixAPI will have a leaner api when $.appFields.platform.migratedToNewPlatformApi == true
 * Missing APIs will need to be loaded lazily via `getPlatformApi` method.
 **/
export type PlatformApi = {
  [key in (typeof APIS_TO_LOAD)[number]]: {
    location: ILocation;
    seo: ISeo;
    site: ISiteApis;
    user: IUserApis;
    window: IWixWindow;
    authentication: ICaptchaDialog;
  }[key];
};

export const getPlatformApi = async (
  platformApiProvider: IWidgetControllerConfig['platformApiProvider'],
): Promise<PlatformApi> => {
  const entriesToApisPromises = APIS_TO_LOAD.map((loadedApiKey) => {
    // Wrapping with Promise.resolve, since getPlatformAPI returns a string in integration test env
    const wrappedApi = Promise.resolve(
      platformApiProvider.getPlatformApi(loadedApiKey),
    );

    return wrappedApi.then((api) => [loadedApiKey, api]);
  });

  return Object.fromEntries(
    await Promise.all(entriesToApisPromises),
  ) as PlatformApi;
};
