import type { ControllerParams } from '@wix/yoshi-flow-editor';
import { createSearchHttpClient } from './createSearchHttpClient';

export function getHttpClient(params: ControllerParams) {
  const { flowAPI, controllerConfig } = params;
  const { environment } = flowAPI;
  const { wixCodeApi } = controllerConfig;
  const { isSSR } = environment;
  return createSearchHttpClient({ wixCodeApi, isSSR });
}
