import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { handleArchiveWidgetResponse } from '../aggregated-archive/actions';
import type { ArchiveWidgetThunkAction } from '../types';

type FetchArchiveWidgetInitialDataArgs = {
  showNewestPostsFirst: boolean;
  monthsDisplayLimit: number;
};

export const fetchArchiveWidgetInitialData =
  ({
    showNewestPostsFirst = true,
    monthsDisplayLimit = 12,
  }: FetchArchiveWidgetInitialDataArgs): ArchiveWidgetThunkAction =>
  async (dispatch, getState, { aggregatorRequest, platformApi }) => {
    const state = getState();
    const langQuery = getQueryLocale(state);
    const language = langQuery ? `&language=${langQuery}` : '';
    const timeZone = platformApi.site.timezone
      ? `&timeZone=${platformApi.site.timezone}`
      : '';

    const path = `/v1/archive-widget/render-model?months=${monthsDisplayLimit}&showNewestPostsFirst=${showNewestPostsFirst}${timeZone}${language}`;
    const response = await aggregatorRequest(path, {
      throwOnInvalidJson: true,
    });

    if (response && typeof response === 'object' && 'archive' in response) {
      await dispatch(handleArchiveWidgetResponse(response.archive));
    } else {
      throw new Error('Invalid response');
    }
  };
