import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ChallengeServerlessData } from '../../../../types/challenge-serverless';
import requestChallenge from '../api/requestChallenge';
import { shouldReportErrorMonitor } from './shouldReportErrorMonitor';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';
import {
  addOnLoginHandler,
  IOnLoginHandlerPriority,
} from '../../../GeneralDataProvider/helpers/onLogin';
import { getChallengeSlugFromLocation } from '../../../Location/helpers/getChallengeSlugFromLocation';

export function handleChallengeAfterLogin() {
  addOnLoginHandler({
    priority: IOnLoginHandlerPriority.SECONDARY,
    handler: async (flowAPI: ControllerFlowAPI) => {
      const challengeId = await getChallengeSlugFromLocation(flowAPI);

      let challengeData: ChallengeServerlessData = {
        challenge: null,
      };

      try {
        if (challengeId) {
          challengeData = await requestChallenge(challengeId, flowAPI);
        }
      } catch (error) {
        if (error.response?.status !== 404) {
          handleError({
            error,
            context: 'onUserLogin.getChallenge',
            preventErrorMonitorReport: !shouldReportErrorMonitor(error),
          });
        }
      }

      flowAPI.controllerConfig.setProps({
        challengeData,
      });
    },
  });
}
