import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { TooltipPortalProvider } from '../../context/tooltip-context';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import AppWrapper from '../app-wrapper';
import { useSelector } from '../runtime-context';
import styles from './app.scss';

type Props = React.PropsWithChildren<{
  main?: React.FunctionComponentElement<{ children?: React.ReactNode }>;
  mobileHeader?: React.FunctionComponentElement<{
    menu?: React.FunctionComponentElement<any>;
    hideNavBar?: boolean;
  }>;
  mobileMenu?: React.FunctionComponentElement<any>;
  messageRoot?: React.FunctionComponentElement<any>;
  modalRoot?: React.FunctionComponentElement<any>;
  popoverRoot?: React.FunctionComponentElement<any>;
}>;

const AppMobile: React.FC<Props> = ({
  modalRoot,
  messageRoot,
  popoverRoot,
  mobileHeader,
  mobileMenu,
  main,
  children,
}) => {
  const createdWithResponsiveEditor = useSelector(
    getIsCreatedWithResponsiveEditor,
  );
  const { isEditor } = useEnvironment();

  useEffect(() => {
    if (!isEditor) {
      document.documentElement.classList.add('enable-scroll');
    }
  }, [isEditor]);

  return (
    <AppWrapper
      className={classNames(styles.app, 'app-mobile', 'blog-background-color')}
    >
      <TooltipPortalProvider>
        {messageRoot}
        {modalRoot}
        {popoverRoot}
        <div
          id="content-wrapper"
          className={classNames(styles.scrollable, 'app-mobile__scrollable')}
        >
          {mobileHeader &&
            React.cloneElement(mobileHeader, {
              menu: mobileMenu,
              hideNavBar: createdWithResponsiveEditor,
            })}
          {main && React.cloneElement(main, { children })}
        </div>
      </TooltipPortalProvider>
    </AppWrapper>
  );
};

export default AppMobile;
