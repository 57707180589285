import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { IRootStore } from 'store/index';
import type {
  IApplicationDialog,
  IShowDialogPayload,
  IShowToastPayload,
} from 'store/application/types';
import * as application from 'store/application';

export function ApplicationVM(params: ControllerParams, store: IRootStore) {
  const { platformAPIs } = params.flowAPI.controllerConfig;

  store.dispatch(
    application.actions.setAppData({
      siteDisplayName: getSiteDisplayName(),
      metaSiteId: platformAPIs.bi?.metaSiteId,
    }),
  );

  return {
    application$: {
      fetchUserProfile,
      shareProfileConsent,
      showToast,
      closeToast,
      showDialog,
      closeDialog,
      promptLogin,
      fetchSettings,
    },
  };

  function getSiteDisplayName() {
    const { wixAPI } = params.flowAPI.viewerScriptFlowAPI;
    return wixAPI.site.getSiteDisplayName?.() || '';
  }

  function fetchSettings() {
    store.dispatch(application.thunks.fetchSettings());
  }

  function promptLogin() {
    store.dispatch(application.thunks.login());
  }

  function shareProfileConsent(groupId: string, value: boolean) {
    store.dispatch(application.actions.shareProfileConsent({ groupId, value }));
  }

  function showToast(params: IShowToastPayload) {
    store.dispatch(application.actions.showToast(params));
  }

  function closeToast(toastId: string) {
    store.dispatch(application.actions.closeToast(toastId));
  }

  function showDialog(params: IShowDialogPayload) {
    store.dispatch(application.actions.showDialog(params));
  }

  function closeDialog(
    dialog: IApplicationDialog,
    params?: Record<string, any>,
  ) {
    store.dispatch(application.actions.closeDialog({ dialog, params }));
  }

  function fetchUserProfile() {
    return store.dispatch(application.thunks.fetchCurrentUserProfile());
  }
}

export type IApplicationVM = ReturnType<typeof ApplicationVM>;
