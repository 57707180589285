import { type ControllerStageDataManifest } from '@wix/platform-editor-sdk';
import postListJson from '../components/PostList/.component.json';

export const overridePostListWidgetManifest = (
  controllersStageData: ControllerStageDataManifest,
) =>
  (controllersStageData[postListJson.id] = {
    default: {
      nickname: 'postList',
    },
  });
