import { MA_APP_IDS, withMembersArea } from '@wix/members-area-integration-kit';
import { ChallengesPlatform } from './editor/ChallengesPlatform';

import AppManifest, {
  AppManagerEvent,
  PageActionsEvent,
} from './editor/types/manifest';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import {
  EditorSDK,
  EditorReadyOptions,
  AppExposedApis,
} from '@wix/platform-editor-sdk';

let platform: ChallengesPlatform;

const editorApi = {
  editorReady: async (
    editorSDK: EditorSDK,
    appDefinitionId: string,
    options: EditorReadyOptions,
    flowAPI: EditorScriptFlowAPI,
  ): Promise<void> => {
    const { firstInstall, origin } = options;
    const isADI = origin?.type?.toLowerCase().includes('adi');
    const isEditorWizardCreationFlow =
      options.origin?.info?.type === 'SILENT_INSTALL_SITE_CREATION' ||
      options.origin?.info?.type === 'SILENT_INSTALL';

    platform = new ChallengesPlatform(
      editorSDK,
      appDefinitionId,
      flowAPI,
      isADI,
      firstInstall,
      isEditorWizardCreationFlow,
    );

    const isChallengesInstalled = await platform.isChallengesInstalled();
    if (!isChallengesInstalled) {
      return;
    }

    /*
      If `firstInstall`, the installation process will wait until this function will be finished.
      So any bugs or slow scripts will be reflected on installation rates and kpis. It should be as simple as possible.
      Without `firstInstall` this script will be just run on every editor load.
     */
    const editorReadyBaseTransaction = async () => {
      await platform.setupProgramsPages(firstInstall);
      await platform.participantPageMigrations();
    };

    await editorSDK.document.transactions.runAndWaitForApproval(
      appDefinitionId,
      editorReadyBaseTransaction,
    );

    // event listeners instead of onEvent(https://dev.wix.com/docs/fed-guild/articles/editor-platform/editor-application-reference/editor-platform-app#onevent)
    // for business panel
    await editorSDK.addEventListener('appActionClicked', async (event) => {
      await platform.openDashboard(event.detail.actionId);
    });

    // for pages panel
    await editorSDK.addEventListener(
      AppManagerEvent.OPEN_DASHBOARD as any,
      async (_event) => {
        await platform.openDashboard(AppManagerEvent.OPEN_DASHBOARD);
      },
    );
    // for pages panel
    await editorSDK.addEventListener(
      PageActionsEvent.REMOVE as any,
      async (_event) => {
        await platform.deleteApp();
      },
    );
  },

  getAppManifest: async ({ appManifestBuilder }): Promise<AppManifest> => {
    return platform.getManifest({ appManifestBuilder });
  },

  exports: (_params): AppExposedApis => {
    return {
      editor: {
        appInstalled(data) {
          if (data.appDefinitionId === platform.appDefId) {
            return Promise.all([
              platform.installDependencies(),
              platform.updatePublicData(),
            ]);
          }
        },
        migrate(data) {
          return platform.handleMigration(data as any);
        },
      },
    };
  },
};

const editorApiWithMA = withMembersArea(editorApi, {
  disableADI: false,
  installAutomatically: false,
  membersAreaApps: [
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.MY_PROGRAMS,
    MA_APP_IDS.ABOUT,
  ],
});

export const editorReady = editorApiWithMA.editorReady;
export const getAppManifest =
  editorApiWithMA.getAppManifest as typeof editorApi.getAppManifest;
export const exports = editorApiWithMA.exports;
