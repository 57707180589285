import { useExperiments, useWixSdk } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '@app/components/Post/stylesParams';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { useSelector } from '../components/runtime-context';
import {
  getIsBlogMenuEnabled,
  getIsBlogMenuCategoryLabelsEnabled,
  getIsBlogMenuSearchEnabled,
  getIsBlogMenuLoginButtonEnabled,
} from '../selectors/app-settings-selectors';
import { getIsPostPageBundle } from '@wix/communities-blog-client-common';

const useBlogMenuSettings = () => {
  const { experiments } = useExperiments();
  const styles = useStyles();
  const isPostPage = useSelector(getIsPostPageBundle);

  return useSelector((state) => {
    if (isPostPage && experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)) {
      return {
        showBlogMenu: getIsBlogMenuEnabled(state),
        showCategoryLabels: styles.get(stylesParams.showCategoryLabels),
        showSearch: getIsBlogMenuSearchEnabled(state),
        showLoginButton: getIsBlogMenuLoginButtonEnabled(state),
      };
    }

    return {
      showBlogMenu: getIsBlogMenuEnabled(state),
      showCategoryLabels: getIsBlogMenuCategoryLabelsEnabled(state),
      showSearch: getIsBlogMenuSearchEnabled(state),
      showLoginButton: getIsBlogMenuLoginButtonEnabled(state),
    };
  });
};

export default useBlogMenuSettings;
