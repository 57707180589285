import {
  combineReducers,
  applyMiddleware,
  createStore,
  type Store,
} from 'redux';
import thunk from 'redux-thunk';
import { type ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { wixDataReducer } from '@wix/communities-blog-client-common';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';
import commonReducers from '../../common/reducers';
import createRequests from '../../common/services/create-requests';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import categories from '../../common/store/categories/categories-reducer';
import postCount from '../../common/store/post-count/post-count-reducer';
import { type ControllerConfig, type FedopsLogger } from '../../common/types';
import { POST_LIST_WIDGET_BUNDLE_NAME } from '../constants/post-list-widget';
import { type PostListWidgetThunkArgs } from '../types/post-list-widget-thunk-args';

interface CreateReduxStoreParams {
  controllerConfig: ControllerConfig;
  platformApi: PlatformApi;
  flowAPI: ControllerFlowAPI;
  fedopsLogger: FedopsLogger;
}

export function createReduxStore({
  controllerConfig,
  platformApi,
  flowAPI,
  fedopsLogger,
}: CreateReduxStoreParams) {
  const { appParams, compId } = controllerConfig;
  const { isSSR } = getEnvironment(platformApi);
  const language = getCurrentSiteLanguage(platformApi, flowAPI);
  const p = {} as { store?: Store };
  const getStore = () => p.store!;

  const { request, platformizedRequest, aggregatorRequest } = createRequests({
    platformApi,
    bundleName: POST_LIST_WIDGET_BUNDLE_NAME,
    getStore,
    flowAPI,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      aggregatorRequest,
      platformApi,
      compId,
      appParams,
      fedopsLogger,
      httpClient: flowAPI.httpClient,
      flowAPI,
    } satisfies PostListWidgetThunkArgs),
    postMetadataMiddleware({ isSSR, language, localizePosts: true }),
  ];

  const reducers = combineReducers({
    ...commonReducers,
    wixData: wixDataReducer,
    postCount,
    categories,
  });

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
