import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';

import { FCWithChildren } from '@wix/challenges-web-library';
import {
  Button,
  ButtonPriority as ButtonPRIORITY,
  ButtonSize as ButtonSIZE,
  Spinner,
  SpinnerTypes,
  TextButton,
  TextButtonPriority,
} from 'wix-ui-tpa/cssVars';
import { useSettingsEvents } from '../../../../../../contexts/SettingsEvents/SettingsEvents';
import { IStyledButtonProps } from '../interfaces';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';

import { classes, st } from './SidebarLayoutButton.st.css';

export const SidebarLayoutButton: FCWithChildren<IStyledButtonProps> = (
  props,
) => {
  const { buttonState: SettingsEventsButtonState } = useSettingsEvents();
  const { isMobile } = useEnvironment();
  const {
    buttonState = SettingsEventsButtonState,
    className,
    size = ButtonSIZE.small,
    fullWidth = isMobile,
    priority = ButtonPRIORITY.primary,
    prefixIcon,
    suffixIcon,
    moveFocus,
    isTextButton = false,
    withSpinner = false,
    withDefaultTheme = false,
  } = props;
  const [disabled, setDisabled] = React.useState(props.disabled);
  const nextRef = useRef(null);

  useEffect(() => {
    if (moveFocus && nextRef.current && !disabled) {
      nextRef.current.focus();
    }
  }, [nextRef.current, disabled, moveFocus]);
  const onClick = props.onClick;
  const onClickHandler = useCallback(
    (e: any) => {
      setDisabled(true);
      // This is a workaround to improve INP performance for the action.
      // It makes next paint prior to the heavy action.
      setTimeout(() => {
        setDisabled(props.disabled);
        onClick(e);
      });
    },
    [onClick, props.disabled],
  );

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  const Component = isTextButton ? TextButton : Button;

  return (
    <Component
      upgrade
      ref={nextRef}
      data-hook={props.dataHook || null}
      className={classNames(
        !withDefaultTheme
          ? st(classes.root, { buttonState }, className)
          : className,
      )}
      disabled={disabled}
      // @ts-expect-error
      priority={isTextButton ? TextButtonPriority.primary : priority}
      size={size}
      prefixIcon={prefixIcon}
      suffixIcon={suffixIcon}
      fullWidth={fullWidth}
      onClick={onClickHandler}
    >
      {withSpinner && props.disabled && isMobile ? (
        <Spinner
          type={SpinnerTypes.regular}
          diameter={16}
          className={classes.spinner}
        />
      ) : (
        props.children
      )}
    </Component>
  );
};
