import {
  ControllerParams,
  CreateControllerFn,
  IWixAPI,
} from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}: ControllerParams) => {
  function getUuidQuestionIdFromUrl(url: string): string | null {
    const match = url.match(
      /[?&]questionId=([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:&|$)/,
    );
    return match ? match[1] : null;
  }
  function getAppDefIdFromUrl(url: string): string | null {
    const match = url.match(
      /[?&]appDefId=([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:&|$)/i,
    );
    return match ? match[1] : null;
  }

  return {
    async pageReady() {
      const instance = controllerConfig.appParams.instance;
      const baseURL = controllerConfig.wixCodeApi.location.baseUrl;
      const appID = controllerConfig.appParams.appDefinitionId;
      const url = controllerConfig.wixCodeApi.location.url;
      const appDefId = getAppDefIdFromUrl(url);
      const isFAQAppDefId = appDefId === appID;
      const questionId = isFAQAppDefId ? getUuidQuestionIdFromUrl(url) : null;
      controllerConfig.setProps({
        instance,
        baseURL,
        appID,
        questionId,
      });
    },
  };
};

export default createController;
