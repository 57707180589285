import { Status } from '@wix/ambassador-loyalty-v1-tier/types';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Divider, Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import { RewardsTabState } from '../../../types/settings';
import settingsParams from '../settingsParams';
import { EmptyState } from './empty-state';
import { ErrorState } from './error-state';
import { ModalProvider } from './hocs/modal-context';
import { WidgetContextProvider } from './hocs/widget-context/widget-context';
import { PointsCard } from './points-card';
import { RewardsList } from './rewards-list';
import { getActionHandlers } from './store';
import {
  AccountConfigProps,
  AppInstallStatusProps,
  CouponsConfigProps,
  LocaleProps,
  LoyaltyProgramConfigProps,
  NavigationProps,
  TiersProgramConfigProps,
  ToastConfigProps,
} from './store/slices';
import { RewardsConfigProps } from './store/slices/rewards/slice';
import { TierCard } from './tier-card';
import { Toast } from './toast';
import { classes, style } from './widget.st.css';

export type ControllerProps = {
  hasError: boolean;
  isWidgetPlugin: boolean;
  rewardsTabState: RewardsTabState;
  accountConfig: AccountConfigProps;
  accountHandlers: ReturnType<typeof getActionHandlers>['accountHandlers'];
  couponsConfig: CouponsConfigProps;
  couponsHandlers: ReturnType<typeof getActionHandlers>['couponsHandlers'];
  rewardsConfig: RewardsConfigProps;
  rewardsHandlers: ReturnType<typeof getActionHandlers>['rewardsHandlers'];
  navigationHandlers: ReturnType<typeof getActionHandlers>['navigationHandlers'];
  loyaltyProgramConfig: LoyaltyProgramConfigProps;
  toastConfig: ToastConfigProps;
  toastHandlers: ReturnType<typeof getActionHandlers>['toastHandlers'];
  tiersProgramConfig: TiersProgramConfigProps;
  appInstallStatusConfig: AppInstallStatusProps;
  localeConfig: LocaleProps;
  navigationConfig: NavigationProps;
};

const Widget: React.FC<WidgetProps<ControllerProps>> = (props) => {
  const settings = useSettings();
  const { isRTL, isMobile } = useEnvironment();
  const { hasError, isWidgetPlugin, appInstallStatusConfig, tiersProgramConfig, rewardsConfig, rewardsTabState } =
    props;

  if (hasError) {
    return <ErrorState />;
  }

  const { isLoyaltyAppInstalled } = appInstallStatusConfig;
  const showTiers = tiersProgramConfig?.programSettings?.status === Status.ACTIVE;
  const showEmptyState = rewardsConfig.simpleRewards.length === 0 || rewardsTabState === RewardsTabState.Empty;
  const pageTitleTag = settings.get(settingsParams.pageTitleTag);
  const pageDescriptionTag = settings.get(settingsParams.pageDescriptionTag);

  return (
    <div dir={isRTL ? 'rtl' : undefined}>
      <WidgetContextProvider {...props}>
        <ModalProvider>
          <Toast />
          <div className={style(classes.root, { isWidgetPlugin, isMobile })} data-hook={DataHook.MyRewardsWrapper}>
            <Text tagName={pageTitleTag} data-hook={DataHook.PageTitle} className={classes.pageTitle}>
              {settings.get(settingsParams.pageTitle)}
            </Text>
            <Text tagName={pageDescriptionTag} data-hook={DataHook.PageDescription} className={classes.pageDescription}>
              {settings.get(settingsParams.pageDescription)}
            </Text>
            {isLoyaltyAppInstalled && (
              <div
                data-hook={DataHook.PointsSection}
                className={style(classes.pointsSection, {
                  showTiers,
                })}
              >
                {showTiers && <TierCard />}
                <PointsCard />
              </div>
            )}
            {!isLoyaltyAppInstalled && !showEmptyState && <Divider className={classes.divider} />}
            <div data-hook={DataHook.RewardsSection}>{showEmptyState ? <EmptyState /> : <RewardsList />}</div>
          </div>
        </ModalProvider>
      </WidgetContextProvider>
    </div>
  );
};

export default Widget;
