import {
  ParticipantSection,
  ParticipantStep,
  ParticipantStepStateTransition,
  Feedback,
  QuizSubmission,
} from '@wix/ambassador-challenges-v1-participant/types';
import { useSections } from '../ParticipantSections/ParticipantSectionsContext';

export type UpdateParticipantStepStatusPayload = {
  sections: ParticipantSection[];
  steps: ParticipantStep[];
  stepId: string;
  transitions?: ParticipantStepStateTransition[];
  feedbackItems?: Feedback;
  quizSubmission?: QuizSubmission;
  replace?: boolean;
};

export const useParticipantSteps = () => {
  return useSections();
};
