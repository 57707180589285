import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import Link from '../../../common/components/link/internal-link';
import RecentPostCarousel from '../recent-post-carousel';
import type { Props } from './types';
import styles from './recent-posts-mobile.scss';

const RecentPostsMobile: React.FC<Props> = ({
  posts,
  title,
  showAllPostsLink,
}) => {
  const { t } = useTranslation();
  return posts.length === 0 ? null : (
    <div
      className={classNames(
        styles.container,
        'blog-card-background-color',
        'blog-text-color',
      )}
    >
      {title && (
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          {showAllPostsLink && (
            <Link
              to="/"
              className={styles.link}
              data-hook="recent-posts-mobile__link"
            >
              {t('recent-posts.all-posts-link')}
            </Link>
          )}
        </div>
      )}
      <RecentPostCarousel posts={posts} />
    </div>
  );
};

export default RecentPostsMobile;
