import {
  arrayToObjectAsync,
  getBaseProvidersData,
} from '../../contexts/main/getBaseProvidersData';
import { Pages } from '../../contexts/Location/LocationContext';
import {
  ControllerFlowAPI,
  ControllerParams,
  CreateControllerFn,
} from '@wix/yoshi-flow-editor';
import { renderSeoMetatagsPage } from '../../services/SeoMetatagsPage';
import { userProviderPropsMap } from '../../contexts/User/userProviderPropsMap';
import { getChallengeInitialData } from '../../contexts/storage-contexts/Challenge';
import { Referrer } from '../../contexts/storage/referrer';
import { getProgramSlug } from '../../selectors/getProgramSlug';
import { hasCoupons } from '@wix/ambassador-challenges-v1-challenge/http';
import { getPaymentTermsAndConditionsPageInfo } from './Settings/Tabs/Display/getPaymentTermsAndConditionsPageInfo';
import { getLocationFromFlowAPI } from '../../contexts/Location/locationProviderPropsMap';

const coupons = async (flowAPI: ControllerFlowAPI) => {
  const resp = await flowAPI.httpClient.request(hasCoupons({}));
  return {
    hasCouponsForPrograms: resp.data.result,
  };
};

const getTermsAndConditionsUrl = async (flowAPI: ControllerFlowAPI) => {
  const pageInfo = getPaymentTermsAndConditionsPageInfo(flowAPI.settings);
  if (!pageInfo.id) {
    return '';
  }
  const siteStructure =
    await flowAPI.controllerConfig.wixCodeApi.site.getSiteStructure();
  const location = getLocationFromFlowAPI(flowAPI);
  const url =
    siteStructure.pages.find((p) => p?.name === pageInfo?.title)?.url ||
    pageInfo.pageUriSEO;

  return { termsAndConditionsUrl: location.baseUrl + url };
};

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  return {
    async pageReady() {
      const initialProps = {
        ...(await arrayToObjectAsync([
          getBaseProvidersData({
            flowAPI,
          }),
          getChallengeInitialData(flowAPI, Referrer.PAYMENT_PAGE),
          userProviderPropsMap(flowAPI),
          coupons(flowAPI),
          getTermsAndConditionsUrl(flowAPI),
        ])),
      };

      const slug = getProgramSlug(initialProps?.challengeData?.challenge);

      if (!initialProps.participant && flowAPI.environment.isViewer) {
        initialProps.goToPage({
          pageId: Pages.Details,
          challengeId: slug,
        });
      } else {
        flowAPI.controllerConfig.setProps({ ...initialProps });
        renderSeoMetatagsPage(
          flowAPI,
          initialProps?.challengeData?.challenge,
          'PAYMENT_PAGE',
        );
      }
    },
  };
};

export default createController;
