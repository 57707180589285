import { ParticipantSection, ParticipantStep } from '../../types/v3Types';
import groupBy from 'lodash/groupBy';
import { getFormattedDate } from '../../components/ParticipantPage/Widget/components/StepsListAsTiles/GetFormattedDate';

export function convertV1ScheduledParticipantStepsToSections(
  steps: ParticipantStep[],
  language: string,
) {
  const groupedSteps = groupBy(steps, (step) => {
    return step.dateFrame.start;
  });

  return Object.entries(groupedSteps).map(
    ([startDate, calendarSteps]): ParticipantSection => {
      return {
        id: startDate,
        withoutDetails: true,
        source: {
          id: startDate,
          description: {
            title: getFormattedDate(language, startDate),
          },
          steps: calendarSteps,
          revision: '0',
        },
        steps: calendarSteps,
      };
    },
  );
}
