import type { ControllerParams } from '@wix/yoshi-flow-editor';

import {
  STORES_APP_DEFINITION_ID,
  EVENTS_APP_DEFINITION_ID,
  MEMBERS_APP_DEFINITION_ID,
  BOOKINGS_APP_DEFINITION_ID,
} from '../../constants';

export function VerticalsVM(params: ControllerParams) {
  return {
    verticals$: {
      hasMembersInstalled: isApplicationInstalled(MEMBERS_APP_DEFINITION_ID),
      hasEventsInstalled: isApplicationInstalled(EVENTS_APP_DEFINITION_ID),
      hasBookingsInstalled: isApplicationInstalled(BOOKINGS_APP_DEFINITION_ID),
      hasStoresInstalled: isApplicationInstalled(STORES_APP_DEFINITION_ID),
    },
  };

  function isApplicationInstalled(appDefinitionId: string) {
    const { wixCodeApi } = params.flowAPI.controllerConfig;

    try {
      return !!wixCodeApi.site.getAppToken?.(appDefinitionId);
    } catch {
      return false;
    }
  }
}

export type IVerticalsVM = ReturnType<typeof VerticalsVM>;
