import wixBlogs from '@wix/communities-universal/dist/src/constants/wix-blogs';
import {
  createAction,
  EXPERIMENTS,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { type CommonThunkAction } from '@app/types/common-thunk-action.type';
import { getHomepage } from '../../services/get-homepage';
import {
  getBlogFeedSectionUrl,
  getBlogPostPageSectionUrl,
} from '../../services/get-section-url';

const { WP_BLOGS, SITE_URLS, POST_PAGE_URLS, FEED_PAGE_URLS } = wixBlogs;

export const FETCH_TOPOLOGY_SUCCESS = 'topology/FETCH_SUCCESS';

const fetchTopologySuccess = createAction(FETCH_TOPOLOGY_SUCCESS);

export const fetchTopology =
  (instanceId: string | undefined = ''): CommonThunkAction<void> =>
  async (dispatch, getState, { platformApi }) => {
    if (WP_BLOGS.includes(instanceId)) {
      dispatch(
        fetchTopologySuccess({
          isHomePage: SITE_URLS[instanceId] === FEED_PAGE_URLS[instanceId],
          baseUrl: SITE_URLS[instanceId],
          sectionUrl: FEED_PAGE_URLS[instanceId],
          postPageSectionUrl: POST_PAGE_URLS[instanceId],
          postPagePath: '/',
        }),
      );

      return;
    }

    const useBaseUrlAsSection = isExperimentEnabled(
      getState(),
      EXPERIMENTS.USE_SITE_ROOT_LINKS,
    );
    const baseUrl = platformApi.location.baseUrl;
    const [sectionUrl, postPageSectionUrl, homepage] = await Promise.all([
      getBlogFeedSectionUrl(platformApi),
      getBlogPostPageSectionUrl(platformApi),
      getHomepage(platformApi),
    ]);

    dispatch(
      fetchTopologySuccess({
        baseUrl,
        isHomePage: homepage
          ? `${baseUrl}${homepage.url}` === sectionUrl
          : false,
        sectionUrl: useBaseUrlAsSection ? baseUrl : sectionUrl,
        postPageSectionUrl: useBaseUrlAsSection ? baseUrl : postPageSectionUrl,
        postPagePath: useBaseUrlAsSection
          ? '/'
          : postPageSectionUrl.replace(baseUrl, ''),
      }),
    );
  };
