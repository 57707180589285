import {
  createPageUrl,
  SECTION_CATEGORY,
  getLastPage,
} from '@wix/communities-blog-client-common';
import {
  getEntityCount,
  getPaginationPageSize,
} from '../../selectors/pagination-selectors';
import { getSectionUrl } from '../../store/topology/topology-selectors';
import { type AppState } from '../../types';

export const getPaginationUrls = (
  url: string | undefined,
  page: number,
  lastPage: number,
  state: AppState,
  multilingualQueryParam = '',
) => {
  const urls: {
    prevUrl?: string;
    nextUrl?: string;
  } = {};
  if (page > 1) {
    urls.prevUrl = `${createPageUrl(
      page - 1,
      url || getSectionUrl(state),
    )}${multilingualQueryParam}`;
  }
  if (page < lastPage) {
    urls.nextUrl = `${createPageUrl(
      page + 1,
      url || getSectionUrl(state),
    )}${multilingualQueryParam}`;
  }
  return urls;
};

export const getPaginationItemData = <T>({
  itemData,
  state,
  url,
  page,
  multilingualQueryParam,
}: {
  itemData: T;
  state: AppState;
  url: string;
  page: number;
  multilingualQueryParam?: string;
}) => {
  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);

  const urls = getPaginationUrls(
    url,
    page,
    lastPage,
    state,
    multilingualQueryParam,
  );

  return { ...itemData, ...urls };
};
