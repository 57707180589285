import React from 'react';
import { SECTION_BLOG_PAGE } from '@wix/communities-blog-client-common';
import Link from '../../../common/components/link/internal-link';
import styles from './link-list.scss';

type LinkListProps = {
  links?: {
    key: string;
    path: string;
    text: React.JSX.Element;
  }[];
  emptyState?: { text: string };
  sectionId?: string;
};

const LinkList: React.FC<LinkListProps> = ({
  links = [],
  emptyState = { text: '' },
  sectionId = SECTION_BLOG_PAGE,
}) => {
  return (
    <ul data-hook="link-list" className={styles.list}>
      {links.map(({ key, path, text }) => (
        <li data-hook="link-list-item" key={key} className={styles.item}>
          <Link
            to={path}
            sectionId={sectionId}
            addHoverClasses={false}
            className={styles.link}
          >
            <span className={styles.text}>{text}</span>
          </Link>
          <span className={styles.message}>
            <span className={styles.text}>{emptyState.text}</span>
          </span>
        </li>
      ))}
    </ul>
  );
};

export default LinkList;
