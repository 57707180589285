import {
  FORM_FACTOR_DESKTOP,
  FORM_FACTOR_MOBILE,
} from '../constants/wix-code-api';
import { type PlatformApi } from '../controller/platform-api';

export const resolveDeviceType = (
  platformApi: PlatformApi,
  isCreatedWithResponsiveEditor = false,
) => {
  const formFactor = platformApi.window.formFactor ?? FORM_FACTOR_DESKTOP;
  const isMobileFriendly = platformApi.window.isMobileFriendly ?? true;

  if (
    formFactor !== FORM_FACTOR_MOBILE ||
    (!isCreatedWithResponsiveEditor && !isMobileFriendly)
  ) {
    return FORM_FACTOR_DESKTOP;
  }

  return FORM_FACTOR_MOBILE;
};
