import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Section, Step } from '../../../types/v3Types';
import { getStartDate } from '../../../selectors/GetStartDate';
import { getChallengeData } from './data';
import groupBy from 'lodash/groupBy';
import { toParticipantSteps } from '../../ParticipantStepsDataProvider/toParticipantSteps';
import { getFormattedDate } from '../../../components/ParticipantPage/Widget/components/StepsListAsTiles/GetFormattedDate';

export async function convertScheduledStepsToSections(
  flowAPI: ControllerFlowAPI,
  challengeSteps: Step[],
) {
  const language = flowAPI.environment.language;
  const programStartDate = getStartDate(
    (await getChallengeData(flowAPI)).challenge,
  );
  const stepsByStartDate = groupBy(
    toParticipantSteps({
      ownerSteps: { steps: challengeSteps || [] },
      initialDate: programStartDate,
    }),
    (step) => {
      return step?.dateFrame?.start;
    },
  );

  return Object.entries(stepsByStartDate).map(
    ([startDate, calendarSteps]): Section => {
      return {
        id: startDate,
        description: {
          title: getFormattedDate(language, startDate),
        },
        steps: calendarSteps.map((step) => ({
          id: step.id,
          ...step.source,
        })),
        revision: '0',
      };
    },
  );
}
