import type { ICentralFeedListResponse } from 'api/central-feed/types';

import type { StateDeclarationFn } from 'controller/types';
import type { IApplicationUser } from 'store/application';

import { ELayoutType } from 'settings/constants';

import settingsParams from '../settingsParams';

export default (function (vm, store, params) {
  const { settings } = params.flowAPI;
  const { isMobile, isSSR } = params.flowAPI.environment;

  return {
    url: '',
    name: 'groups',
    parent: 'social-groups',
    abstract: true,
    data: {
      sectionId: 'groups',
    },
    resolve: [
      {
        token: 'user',
        async resolveFn() {
          return vm.application$.fetchUserProfile().unwrap();
        },
      },
      {
        token: 'isLoggedIn',
        deps: ['user'],
        resolveFn(user: IApplicationUser) {
          return user.loggedIn;
        },
      },
      {
        token: 'layout',
        resolveFn() {
          return settings.get(settingsParams.groupListLayout);
        },
      },
      {
        token: 'useFeed',
        deps: ['layout'],
        resolveFn(layout: ELayoutType) {
          return (
            layout === ELayoutType.Sidebar ||
            settings.get(settingsParams.showFeed)
          );
        },
      },
      {
        token: 'application-settings',
        resolveFn() {
          return vm.application$.fetchSettings();
        },
      },
      {
        token: 'group-requests',
        deps: ['isLoggedIn'],
        resolveFn(isLoggedIn: boolean) {
          if (!isLoggedIn) {
            return;
          }

          return vm.groupRequests$.fetch({
            limit: 5,
            offset: 0,
          });
        },
      },
      {
        token: 'feed',
        deps: ['useFeed'],
        async resolveFn(useFeed: boolean) {
          if (useFeed) {
            return vm.centralFeed$.fetch();
          }
        },
      },
      {
        token: 'comments',
        deps: ['feed'],
        async resolveFn(feed?: ICentralFeedListResponse) {
          if (isMobile) {
            return;
          }

          return vm._.comments.fetch(feed?.items);
        },
      },
    ],
  };
} as StateDeclarationFn);
