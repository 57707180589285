import { type ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { GLOBALS } from '@wix/communities-blog-client-common';
import {
  appendOriginInBackend,
  createRequestWithBaseUrl,
} from '../controller/helpers';
import type { PlatformApi } from '../controller/platform-api';
import { type AppStore } from '../types';
import getEnvironment from './get-environment';

interface CreateRequestsParams {
  platformApi: PlatformApi;
  bundleName: string;
  getStore: () => AppStore;
  flowAPI: ControllerFlowAPI;
}

export default function createRequests({
  platformApi,
  bundleName,
  getStore,
  flowAPI,
}: CreateRequestsParams) {
  const { isEditor, isPreview } = getEnvironment(platformApi);
  const createRequest = createRequestWithBaseUrl({
    platformApi,
    getStore,
    bundleName,
    flowAPI,
  });

  const blogApiBaseUrl =
    isEditor || isPreview
      ? GLOBALS.API_BASE_URL_CLIENT
      : `${platformApi.location.baseUrl}${GLOBALS.API_BASE_URL_CLIENT}`;

  return {
    blogApiBaseUrl,
    request: createRequest(blogApiBaseUrl),
    platformizedRequest: createRequest(
      appendOriginInBackend({
        platformApi,
        baseUrl: GLOBALS.API_PLATFORMIZED_BASE_URL_CLIENT,
      }),
    ),
    paywallRequest: createRequest(
      appendOriginInBackend({
        platformApi,
        baseUrl: GLOBALS.API_PAYWALL_BASE_URL,
      }),
    ),
    aggregatorRequest: createRequest(
      appendOriginInBackend({
        platformApi,
        baseUrl: GLOBALS.API_AGGREGATOR_BASE_URL,
      }),
    ),
  };
}
