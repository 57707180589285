import { fetchTPASettings } from '@wix/communities-blog-client-common';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { getInstance } from '../../common/controller/helpers';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setCommunitiesContext } from '../../common/store/communities-context/communities-context-actions';
import { setCustomRoutes } from '../../common/store/custom-routes/custom-routes-actions';
import { initializeInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import {
  setLocale,
  setQueryLocaleAction,
} from '../../common/store/locale/locale-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { type AppStore, type ControllerConfig } from '../../common/types';
import { fetchRelatedPostsRenderModel } from './widget-data';

export {
  initializeActions,
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

type InitializeStoreBaseDataParams = {
  store: AppStore;
  language: string;
  bundleName: string;
  controllerConfig: ControllerConfig;
  postId: string | undefined;
  platformApi: PlatformApi;
};

export async function initializeStoreBaseData({
  store,
  language,
  bundleName,
  controllerConfig,
  postId,
  platformApi,
}: InitializeStoreBaseDataParams) {
  const { appParams, config, platformAPIs } = controllerConfig;
  const viewMode = platformApi.window.viewMode.toLowerCase();

  store.dispatch(initializeInstanceValues(getInstance(platformApi)()));
  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(platformApi, appParams));

  const instanceId = getInstanceId(store.getState());

  await Promise.all([
    store.dispatch(
      fetchTPASettings({
        language: platformApi.window.multilingual.isEnabled
          ? language
          : undefined,
        shouldUseFallbackOnError: true,
      }),
    ),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(
      setBasicParams({
        viewMode,
        language,
        biPageNumber: platformAPIs.bi?.pageNumber,
        isRendered: true,
      }),
    ),
    store.dispatch(setAppConfig({ bundleName })),
    store.dispatch(setCustomRoutes()),
    store.dispatch(fetchTopology(instanceId)),
  ]);

  await store.dispatch(fetchRelatedPostsRenderModel(postId));
}
