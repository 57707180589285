import {
  approveJoinGroupRequests,
  rejectJoinGroupRequests,
} from '@wix/ambassador-social-groups-v2-join-group-request/http';
import {
  followMember,
  unfollowMember,
} from '@wix/ambassador-members-v3-follow/http';
import * as inviteApi from '@wix/ambassador-social-groups-v1-email-invite/http';
import {
  Role,
  SortOrder,
} from '@wix/ambassador-social-groups-v2-group-member-with-profile/types';

import { GroupRole } from '@wix/ambassador-socialgroups-v3-group-member/types';

import * as membersApi from 'api/members/members.api';
import * as groupsApi from 'api/groups/groups.api';
import * as membershipApi from 'api/membership/membership.api';

import * as application from '../application';
import { createAsyncThunk } from '../utils';

import { selectSuggestedMembersMetadata } from './selectors';
import type {
  IListGroupMembersParams,
  IQueryGroupMembersParams,
} from './types';
import { selectGroupName } from 'store/selectors';
import { Group } from '@wix/ambassador-social-groups-v2-group-request/types';

export const fetchProfile = createAsyncThunk(
  'members:fetchProfile',
  async function (memberId: string, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      membersApi.getMember(memberId),
    );

    return data;
  },
);

export const list = createAsyncThunk(
  'members:list',
  async function (params: IListGroupMembersParams, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      membersApi.listGroupMembers(params.groupId, params.limit, params.offset),
    );

    return data;
  },
);

export const query = createAsyncThunk(
  'members:query',
  async function (params: IQueryGroupMembersParams, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      membersApi.queryGroupMembers(
        params.groupId,
        {
          paging: {
            offset: params.offset,
            limit: params.limit || 20,
          },
          sort: [
            {
              fieldName: params.query?.sort || 'profile.nickname',
              order: SortOrder.ASC,
            },
          ],
          filter: !params.query?.nickname
            ? {}
            : {
                'profile.nickname': {
                  $contains: params.query?.nickname,
                },
              },
        },
        params.query?.role,
      ),
    );

    return data;
  },
);

export const querySuggested = createAsyncThunk(
  'members:queryNonGroupMembers',
  async function (params: IQueryGroupMembersParams, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      membersApi.queryNonGroupMembers(params.groupId, {
        paging: {
          offset: params.offset,
          limit: params.limit || 20,
        },
        sort: [
          {
            fieldName: params.query?.sort || 'profile.nickname',
            order: SortOrder.ASC,
          },
        ],
        filter: {
          'profile.nickname': {
            $contains: params.query?.nickname || '',
          },
        },
      }),
    );

    return data;
  },
);

export const inviteByEmail = createAsyncThunk(
  'members:inviteByEmail',
  async function (params: { groupId: string; emails: string[] }, thunkAPI) {
    const { emails, groupId } = params;
    const groupName = selectGroupName(thunkAPI.getState(), groupId);

    const { data } = await thunkAPI.extra.httpClient.request(
      inviteApi.batchInviteByEmail({
        groupId,
        emails,
      }),
    );

    thunkAPI.dispatch(
      application.actions.showToast({
        type: 'success',
        i18nParams: {
          i18nKey: 'groups-web.toast.members.inviteByEmail.success',
          values: {
            count: emails.length,
            groupName: groupName ?? '',
          },
        },
      }),
    );

    return data;
  },
);

export const addToGroup = createAsyncThunk(
  'members:add',
  async function (params: { groupId: string; memberIds: string[] }, thunkAPI) {
    const { groupId, memberIds } = params;
    const { httpClient } = thunkAPI.extra;

    const { metadata } = selectSuggestedMembersMetadata(thunkAPI.getState());
    const groupName = selectGroupName(thunkAPI.getState(), groupId);

    const response = await httpClient.request(
      membershipApi.addMembersToGroup(groupId, memberIds),
    );

    if (metadata.hasNext) {
      thunkAPI.dispatch(
        querySuggested({
          groupId,
          limit: 5,
          offset: metadata.count! + metadata.offset!,
        }),
      );
    }

    thunkAPI.dispatch(
      application.actions.showToast({
        type: 'success',
        i18nParams: {
          i18nKey: 'groups-web.toast.members.add-members.success',
          values: {
            count: memberIds.length,
            groupName: groupName ?? '',
          },
        },
      }),
    );

    return response.data;
  },
);

export const remove = createAsyncThunk(
  'members:remove',
  async function (params: { groupId: string; memberId: string }, thunkAPI) {
    const { groupId, memberId } = params;

    await thunkAPI.extra.httpClient.request(
      membershipApi.removeFromGroup(groupId, memberId),
    );
  },
);

export const changeRole = createAsyncThunk(
  'members:changeRole',
  async function (
    params: { groupId: string; contactId: string; role: GroupRole },
    thunkAPI,
  ) {
    const { groupId, contactId, role } = params;

    await thunkAPI.extra.httpClient.request(
      role === GroupRole.ADMIN
        ? membershipApi.addAdminRole(groupId, contactId)
        : membershipApi.removeAdminRole(groupId, contactId),
    );
  },
);

export const fetchAnswers = createAsyncThunk(
  'members:fetchAnswers',
  async function (params: { groupId: string; memberId: string }, thunkAPI) {
    const { groupId, memberId } = params;

    const { data } = await thunkAPI.extra.httpClient.request(
      membershipApi.listMemberAnswers(groupId, memberId),
    );

    return data.answers;
  },
);

export const fetchJoinRequests = createAsyncThunk(
  'members:joinRequests:fetch',
  async function (groupId: string, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      groupsApi.listPendingRequests(groupId),
    );

    return data;
  },
);

export const approve = createAsyncThunk(
  'members:joinRequests:approve',
  async function (params: { groupId: string; memberId: string }, thunkAPI) {
    const { groupId, memberId } = params;

    await thunkAPI.extra.httpClient.request(
      approveJoinGroupRequests({
        groupId,
        memberIds: [memberId],
      }),
    );

    const { data } = await thunkAPI.extra.httpClient.request(
      membersApi.getGroupMember(groupId, memberId),
    );

    return data;
  },
);

export const reject = createAsyncThunk(
  'members:joinRequests:reject',
  async function (
    params: { groupId: string; memberId: string; reason?: string },
    thunkAPI,
  ) {
    const { groupId, memberId, reason } = params;

    const { data } = await thunkAPI.extra.httpClient.request(
      rejectJoinGroupRequests({
        groupId,
        rejections: [
          {
            reason,
            memberId,
          },
        ],
      }),
    );

    return data;
  },
);

type FollowParams = {
  memberId: string;
  feedItemId?: string;
};

export const follow = createAsyncThunk<string, FollowParams>(
  'application:follow',
  async ({ memberId }: FollowParams, thunkAPI) => {
    await thunkAPI.extra.httpClient.request(followMember({ memberId }));

    return memberId;
  },
);

export const unfollow = createAsyncThunk(
  'application:unfollow',
  async ({ memberId }: FollowParams, thunkAPI) => {
    await thunkAPI.extra.httpClient.request(unfollowMember({ memberId }));

    return memberId;
  },
);
