import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ResolvedStep } from '@wix/ambassador-online-programs-v3-resolved-step/types';
import { listResolvedSteps } from '@wix/ambassador-online-programs-v3-resolved-step/http';
import { request } from '../../../../services/request';

export async function requestResolvedSteps(
  participantId: string,
  flowAPI: ControllerFlowAPI,
): Promise<ResolvedStep[]> {
  if (!participantId) {
    return [];
  }
  try {
    return (
      await request(
        flowAPI,
        listResolvedSteps({
          participantId,
        }),
      )
    )?.data?.resolvedSteps;
  } catch (e) {
    console.error('Failed to request resolved steps', e);
    return [];
  }
}
