import React from 'react';

export const Back = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="24"
      viewBox="0 0 8 24"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill="currentColor">
          <g>
            <path
              d="M2.999 9L-3 14.241 -2.343 15 2.999 10.334 8.343 15 9 14.241z"
              transform="translate(-19 -73) translate(20 73) matrix(0 1 1 0 -9 9)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
