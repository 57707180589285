import { EditableImage } from './types';

export function isFile(coverImage?: EditableImage): coverImage is File {
  return coverImage instanceof File;
}

export function getPreview(value?: EditableImage) {
  if (isFile(value)) {
    return URL.createObjectURL(value);
  }

  return value?.image?.mediaId as string | undefined;
}
