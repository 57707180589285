import { type ControllerParams } from '@wix/yoshi-flow-editor';

export function isOptimizationEnabled(params: ControllerParams) {
  const { environment, experiments } = params.flowAPI;

  if (!environment.isSSR) {
    return false;
  }

  return experiments.enabled('specs.groups.OOIOptimization');
}
