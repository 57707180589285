import React from 'react';
import {
  Image as WixImage,
  type FittingType,
  type SocialAttributes,
} from '@wix/image';
import {
  getImageUri,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { isSeo as getIsSeo } from '../../store/basic-params/basic-params-selectors';
import { useSelector } from '../runtime-context';

type Props = {
  image: any;
  width?: number;
  height?: number;
  displayMode?: FittingType;
  socialAttrs?: SocialAttributes;
  className?: string;
};

export const Image: React.FC<Props> = (props) => {
  const isSeo = useSelector(getIsSeo);

  const uri = getImageUri(props.image);

  const isAvifEncodingEnabled = useSelector((state) =>
    isExperimentEnabled(state, OOI_EXPERIMENTS.USE_AVIF_ENCODING),
  );

  if (!uri) {
    return null;
  }

  return (
    <WixImage
      uri={uri}
      width={props.width ?? props.image.width}
      height={props.height ?? props.image.height}
      alt={props.image.alt}
      placeholderTransition={isSeo ? undefined : 'blur'}
      socialAttrs={props.socialAttrs}
      className={props.className}
      displayMode={props.displayMode ?? 'fill'}
      encoding={isAvifEncodingEnabled ? 'AVIF' : undefined}
    />
  );
};
