import type { TFunction } from '@wix/yoshi-flow-editor';
import {
  createPageUrl,
  getLastPage,
  getMultilingualQueryParam,
  SECTION_HOMEPAGE,
  urijs,
} from '@wix/communities-blog-client-common';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import {
  getEntityCount,
  getPaginationPageSize,
  getShowPagination,
} from '@app/external/common/selectors/pagination-selectors';
import { getTopology } from '@app/external/common/store/topology/topology-selectors';
import { type AppState } from '@app/external/common/types';
import { getPaginationUrls } from './get-pagination-item-data';

type GenerateFeedSEOTagsParams = {
  state: AppState;
  t: TFunction;
  platformApi: PlatformApi;
  initialSiteTitle: string;
  page?: number;
};

export const generateFeedSEOTags = ({
  state,
  initialSiteTitle,
  platformApi,
  page = 1,
  t,
}: GenerateFeedSEOTagsParams) => {
  const canonicalUrl = prepareCanonicalUrl({ page, platformApi, state });
  const preparedTitle = prepareTitle({ initialSiteTitle, page, state, t });

  return {
    title: preparedTitle,
    links: prepareLinks({ canonicalUrl, page, platformApi, state }),
    metaTags: prepareMetaTags({ canonicalUrl, platformApi, preparedTitle }),
  };
};

type PrepareCanonicalUrlParams = {
  state: AppState;
  page: number;
  platformApi: PlatformApi;
};

function prepareCanonicalUrl({
  state,
  page,
  platformApi,
}: PrepareCanonicalUrlParams) {
  const topology = getTopology(state);
  const multilingualQueryParam = getMultilingualQueryParam(
    platformApi.window.multilingual,
  );
  let url = topology.sectionUrl!;
  if (page === 1) {
    url = topology.isHomePage ? topology.baseUrl! : topology.sectionUrl!;
  } else {
    url = createPageUrl(page, topology.sectionUrl!);
  }

  return `${url}${multilingualQueryParam}`;
}

type TitleParams = {
  state: AppState;
  initialSiteTitle: string;
  page: number;
  t: TFunction;
};

function prepareTitle({
  state,
  page,
  initialSiteTitle,
  t,
}: TitleParams): string {
  const lastPage = getLastPage(
    getEntityCount(state, 'posts'),
    getPaginationPageSize(state, SECTION_HOMEPAGE),
  );

  if (getShowPagination(state, SECTION_HOMEPAGE)) {
    if (page < 2) {
      return initialSiteTitle;
    }

    return `${initialSiteTitle} | ${t('pagination.current-position-tile', {
      page,
      lastPage,
    })}`;
  }

  return initialSiteTitle;
}

type MetaTagsParams = {
  preparedTitle: string;
  platformApi: PlatformApi;
  canonicalUrl: string;
};

function prepareMetaTags({
  platformApi,
  preparedTitle,
  canonicalUrl,
}: MetaTagsParams): PlatformApi['seo']['metaTags'] {
  let hasOpenGraphUrl = false;

  const preparedMetaTags = platformApi.seo.metaTags.map((tag) => {
    if (tag.property === 'og:title' || tag.name === 'twitter:title') {
      return { ...tag, content: preparedTitle };
    }

    if (tag.property === 'og:url') {
      hasOpenGraphUrl = true;
      return { ...tag, content: urijs(tag.content).readable() };
    }

    return tag;
  });

  if (!hasOpenGraphUrl) {
    preparedMetaTags.push({
      property: 'og:url',
      content: canonicalUrl,
      name: '',
    });
  }

  return preparedMetaTags;
}

type LinksParams = {
  state: AppState;
  platformApi: PlatformApi;
  canonicalUrl: string;
  page: number;
};

function prepareLinks({
  canonicalUrl,
  page,
  platformApi,
  state,
}: LinksParams): PlatformApi['seo']['links'] {
  const lastPage = getLastPage(
    getEntityCount(state, 'posts'),
    getPaginationPageSize(state, SECTION_HOMEPAGE),
  );

  const topology = getTopology(state);
  const preparedLinks = [...platformApi.seo.links];
  const paginationUrls = getPaginationUrls(
    topology.sectionUrl,
    page,
    lastPage,
    state,
    getMultilingualQueryParam(platformApi.window.multilingual),
  );

  if (preparedLinks.every((item) => item.rel !== 'canonical')) {
    preparedLinks.push({ rel: 'canonical', href: canonicalUrl });
  }
  if (paginationUrls.prevUrl) {
    preparedLinks.push({ rel: 'prev', href: paginationUrls.prevUrl });
  }
  if (paginationUrls.nextUrl) {
    preparedLinks.push({ rel: 'next', href: paginationUrls.nextUrl });
  }

  return preparedLinks;
}
