import React from 'react';
import { CashierPaymentsWidgetLazy } from '@wix/cashier-payments-widget/lazy';
import {
  ICashierPaymentsApi,
  ICashierPaymentsConfiguration,
} from '@wix/cashier-payments-widget';
import type { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import { GetMyMemberResponse } from '@wix/ambassador-members-ng-api/types';

import { DeviceType } from '../../../../editor/types/common';
import type { MFieldValuePairs } from '@wix/cashier-payments-widget/dist/src/types/MandatoryFields';
import { ISettingsContextValue, IStylesContextValue } from '@wix/tpa-settings';
import paymentSettings from '../../settingsParams';
import paymentStyles from '../../stylesParams';

export interface ISingPaymentIntegrationProps {
  appId: string;
  userId: string;
  instanceId: string;
  deviceType: DeviceType;
  locale: string;
  termsAndConditionsUrl?: string;
  siteOwnerId: string;
  orderId: string;
  amount?: string;
  currency?: string;
  onApiReady(api: ICashierPaymentsApi): void;
  paymentComplete(): void;
  onWidgetLoaded(): void;
  pendingOwnerApprove(): void;
  instance: string;
  host: IHostProps;
  msid: string;
  isMyWalletInstalled: boolean;
  domain: string;
  currentMember?: GetMyMemberResponse['member'];
  settings?: ISettingsContextValue;
  styles?: IStylesContextValue;
}

interface IPaymentWidgetState {}

export class PaymentWidget extends React.Component<
  ISingPaymentIntegrationProps,
  IPaymentWidgetState
> {
  static displayName = 'CashierPaymentWidget';
  private widgetAPI: ICashierPaymentsApi;

  private readonly handleWidgetRef = async (ref) => {
    if (ref) {
      this.widgetAPI = await ref.getApi();
      this.props.onApiReady(this.widgetAPI);
      await this.widgetAPI.expand();
    }
  };

  render() {
    const configuration: ICashierPaymentsConfiguration = {
      appId: this.props.appId as any,
      appInstanceId: this.props.instanceId,
      locale: this.props.locale,
      visitorId: this.props.userId,
      snapshotId: this.props.orderId,
      siteOwnerId: this.props.siteOwnerId,
      viewMode: this.props.host.viewMode as any,
      isSignedInUser: true,
      appInstance: this.props.instance,
      msid: this.props.msid,
      amount: '0',
      currency: 'USD',
    };

    if (this.props.amount) {
      configuration.amount = this.props.amount;
    }

    if (this.props.currency) {
      configuration.currency = this.props.currency;
    }

    const styleSettings = {
      ...this.props.host.style,
      siteColors: [
        ...this.props.host.style.siteColors,
        // field label & input color
        {
          name: 'color_15',
          value: this.props.styles.get(paymentStyles.paymentTextColor).value,
          reference: 'color-5',
        },
      ],
      siteTextPresets: {
        // field label font & style
        'Body-M': {
          ...this.props.host.style.siteTextPresets['Body-M'],
          value: this.props.styles.get(paymentStyles.paymentTextFont).value,
        },
      },
    };

    return (
      <CashierPaymentsWidgetLazy
        ref={this.handleWidgetRef}
        shouldApplySiteStyles
        uiLibrary="tpa"
        debug={process.env.NODE_ENV === 'development'}
        allowManualPayment={true}
        siteStyles={styleSettings}
        onError={(...args) => console.info('onError', args)}
        onPaymentMethodsLoaded={() => {}}
        onWalletPaymentComplete={() => {}}
        onIsValidChanged={() => {}}
        onPaymentStart={(...args) => console.info('onPaymentStart', ...args)}
        onPaymentComplete={(paymentMethod, info) => {
          if (info.clientStatus === 'Pending') {
            this.props.pendingOwnerApprove();
          }
          if (info.clientStatus === 'Approved') {
            this.props.paymentComplete();
          }
        }}
        deviceType={this.props.deviceType}
        paymentMethodChanged={() => {}}
        configuration={configuration}
        isSaveCCEnabled={this.props.isMyWalletInstalled}
        mandatoryFieldsPrefilled={{
          ...getMandatoryFields(this.props.currentMember),
        }}
        useTermsAndConditions={Boolean(
          this.props.settings.get(paymentSettings.displayTermsLink),
        )}
        termsAndConditionsLink={this.props.termsAndConditionsUrl}
        onFullLoad={this.props.onWidgetLoaded}
      />
    );
  }
}

function getMandatoryFields(
  member: ISingPaymentIntegrationProps['currentMember'],
): MFieldValuePairs {
  if (!member) {
    return {};
  }
  // tmp workaround for Cashier
  const country = member?.contact?.addresses[0]?.country;
  let state = member?.contact?.addresses[0]?.subdivision;

  if (country === 'US' && state) {
    state = state.split('-')[1];
  }

  return {
    email: member.loginEmail,
    city: member?.contact?.addresses[0]?.city,
    countryCode: country,
    state: state || member?.contact?.addresses[0]?.subdivision,
    address: member?.contact?.addresses[0]?.addressLine,
    zipCode: member?.contact?.addresses[0]?.postalCode,
    firstName: member?.contact?.firstName,
    lastName: member?.contact?.lastName,
    phone: member?.contact?.phones[0],
  };
}

export default PaymentWidget;
