import { ResolutionStatus } from '@wix/ambassador-challenges-v1-participant/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IResolveStepContext, ResolveStepParams } from './ResolveStepContext';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { ActionTypes } from '../main/biInterfaces';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { biBeforeAndAfter } from '../../services/biHelpers';
import {
  resolveStep as rStep,
  updateStepFeedback,
} from '@wix/ambassador-challenges-v1-participant/http';
import { request } from '../../services/request';

import { FedopsInteraction } from '../../config/fedopsInteraction';
import { monitoringPropsMap } from '../Monitoring/MonitoringPropsMap';
import { showToastFromController } from '../ToastContext/toastPropsMap';
import { patchParticipantStep } from '@wix/challenges-web-library';
import { getChallengeId } from '../storage-contexts/Challenge/helpers/getChallengeId';
import { isV3enabled } from '../../experiments/isV3enabled';
import {
  resolveParticipantStepV3,
  ResolveStepData,
  undoStepV3,
} from '../storage-contexts/Challenge/api/resolveStep';
import { isFailed } from '../../selectors/step';

const getActionType = (data: {
  status: ResolutionStatus;
  hasFeedback: boolean;
  isUpdate: boolean;
}) => {
  const { status, hasFeedback, isUpdate } = data;
  if (status === ResolutionStatus.UNDO) {
    return ActionTypes.STEP_UNDO;
  }

  const actionTypes = !isUpdate
    ? [ActionTypes.STEP_COMPLETE]
    : [ActionTypes.STEP_FEEDBACK_UPDATE];

  if (hasFeedback) {
    actionTypes.push(ActionTypes.FEEDBACK_SEND);
  }
  return actionTypes;
};

const resolveStep = async (
  flowAPI: ControllerFlowAPI,
  payload: ResolveStepParams,
): Promise<void> => {
  const {
    stepId,
    feedback,
    quizSubmission,
    unresolvedMessage,
    status = ResolutionStatus.COMPLETED,
    isUpdate = false,
  } = payload;
  const { participant, participantV3 } = await userProviderPropsMap(flowAPI);
  const participantOrV3 = participantV3 || participant;
  const hasFeedback = !!feedback?.items?.length || !!feedback?.quiz?.length;

  flowAPI.controllerConfig.setProps({
    isResolveStepRequestInProgress: true,
  });

  const sendRequest = async (actionId: string) => {
    const requestData: any = {
      actionId,
      challengeId: await getChallengeId(flowAPI),
      participantId: participant?.id,
      status,
      stepId,
    };

    if (isV3enabled(flowAPI)) {
      if (status === ResolutionStatus.UNDO) {
        return undoStepV3(
          {
            participantId: participantOrV3.id,
            stepId,
          },
          flowAPI,
        );
      }
      return resolveParticipantStepV3(
        {
          participantId: participantOrV3.id,
          stepId,
          quizSubmissionId: quizSubmission?.quizSubmissionId,
        },
        flowAPI,
      );
    }

    if (feedback) {
      requestData.feedback = {
        createdAt: new Date(),
        items: feedback?.items,
        quiz: feedback?.quiz,
      };

      if (isUpdate) {
        delete requestData.feedback.createdAt;
        delete requestData.status;

        requestData.feedback.updatedAt = new Date();
      }
    } else if (quizSubmission) {
      requestData.quizSubmissionId = quizSubmission.quizSubmissionId;
      requestData.status = ResolutionStatus.QUIZ_SUBMIT;
    }

    return isUpdate
      ? (await request(flowAPI, updateStepFeedback(requestData)))?.data
      : (await request(flowAPI, rStep(requestData)))?.data;
  };

  try {
    const { step: originalStep } = await biBeforeAndAfter(
      flowAPI.bi,
      getActionType({ status, isUpdate, hasFeedback }),
      async (actionId) => {
        return sendRequest(actionId);
      },
    );
    const step = patchParticipantStep(originalStep);

    if (isFailed(step)) {
      showToastFromController(flowAPI, unresolvedMessage);
    }

    const resolveStepData: ResolveStepData = {
      id: step?.id,
      quizSubmission: step?.quizSubmission,
      transitions: step?.transitions,
    };

    flowAPI.controllerConfig.setProps({
      resolveStepData,
    });
    // participantSectionsPropsMap?.cache?.clear(); // the cache needs to be cleared because sections and steps data is changed now

    if (!isUpdate && !isFailed(step)) {
      const userProvider = await userProviderPropsMap(flowAPI);
      // backend progress update is async, so we do optimistic update
      await userProvider.incrementParticipantsCompletedStepSummary(
        status !== ResolutionStatus.UNDO ? 1 : -1,
      );
    }

    const { endInteraction } = await monitoringPropsMap(flowAPI);
    // eslint-disable-next-line no-nested-ternary
    const interactionName = isUpdate
      ? FedopsInteraction.UpdateFeedbackData
      : FedopsInteraction.CompleteStepWithoutFeedback;
    endInteraction(interactionName, stepId);
  } catch (error) {
    handleError({
      error,
      context: 'resolveStep',
    });

    flowAPI.controllerConfig.setProps({
      resolveStepError: error.toString(),
    });

    console.error(error);
  }

  flowAPI.controllerConfig.setProps({
    isResolveStepRequestInProgress: false,
  });
};

export const resolveStepDataProviderPropsMap = async function (
  flowAPI: ControllerFlowAPI,
): Promise<Partial<IResolveStepContext>> {
  return {
    isResolveStepRequestInProgress: false,
    resolveStep: async (payload) => resolveStep(flowAPI, payload),
  };
};
