import React from 'react';

import { FCWithChildren } from '@wix/challenges-web-library';
import { classes } from './StepUndo.st.css';
import classNames from "classnames";
import { Challenges } from '../../../../../../editor/types/Experiments';
import {
  ButtonSize,
  ButtonPriority as ButtonPRIORITY,
} from 'wix-ui-tpa/cssVars';
import { SidebarLayoutButton } from '../../Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { ResolutionStatus } from '@wix/ambassador-challenges-v1-participant/types';
import { useResolveStep } from '../../../../../../contexts/ResolveStep/ResolveStepContext';
import { useSidebarLayoutBase } from '../../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { useUser } from '../../../../../../contexts/User/UserContext';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { isUserCompleted } from '../../../../../../contexts/User/helpers/userTypeHandlers';
import { isStepResolved } from '../../../views/utils';
import { sidebarLayoutDataHooks } from '../../../views/SidebarLayout/sidebarLayoutDataHooks';
import {
  FocusPoint,
  useFocusManager,
} from '../../../../../../contexts/FocusManager/useFocusManager';

export const StepUndo: FCWithChildren<{
  className?: string;
  children: React.ReactNode;
}> = (props) => {
  const { isMobile } = useEnvironment();
  const { resolveStep, isResolveStepRequestInProgress } = useResolveStep();
  const { currentStep } = useSidebarLayoutBase();
  const { participant } = useUser();
  const { experiments } = useExperiments();
  const { setNextFocusPoint, focusPoint } = useFocusManager();

  if (
    !isStepResolved(currentStep) ||
    isUserCompleted(participant?.transitions?.[0]?.state) ||
    !experiments.enabled(Challenges.enableUndoButton)
  ) {
    return null;
  }

  return (
    <SidebarLayoutButton
      dataHook={sidebarLayoutDataHooks.stepUndo()}
      className={classNames(classes.stepUndoButton, props.className )}
      disabled={isResolveStepRequestInProgress}
      fullWidth={isMobile}
      priority={ButtonPRIORITY.secondary}
      size={isMobile ? ButtonSize.small : ButtonSize.medium}
      withDefaultTheme={true}
      onClick={() => {
        setNextFocusPoint(FocusPoint.Top);
        void resolveStep({
          stepId: currentStep.id,
          status: ResolutionStatus.UNDO,
        });
      }}
      moveFocus={focusPoint === FocusPoint.Bottom}
    >
      {props.children}
    </SidebarLayoutButton>
  );
};
