import type { Member } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import { type AppState } from '../../types';

export const getProfileUrls = (state: AppState) => state.profileUrls;

export const getProfileUrlByUser = (
  state: AppState,
  user: Member | undefined,
) =>
  user?.siteMemberId ? getProfileUrls(state)?.[user?.siteMemberId] : undefined;
