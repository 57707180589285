import { get } from 'lodash';
import {
  createAction,
  GLOBALS,
  urijs,
} from '@wix/communities-blog-client-common';
import { type CommonThunkAction } from '@app/types/common-thunk-action.type';
import { getInstance } from '../../controller/helpers';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import { resolveDeviceType } from '../../services/device-type';
import getEnvironment from '../../services/get-environment';
import { isRtlLanguage } from '../../services/is-rtl-language';

export const SET_BASIC_PARAMS = 'SET_BASIC_PARAMS';
export const setBasicParamsAction = createAction(SET_BASIC_PARAMS);

export const SET_INSTANCE = 'SET_INSTANCE';
export const setInstance = createAction(SET_INSTANCE);

interface SetBasicParamsParams {
  viewMode: string;
  language: string;
  biPageNumber?: number;
  isRendered?: boolean;
}

export const setBasicParams =
  ({
    viewMode,
    language,
    biPageNumber,
    isRendered,
  }: SetBasicParamsParams): CommonThunkAction<void> =>
  async (dispatch, getState, { platformApi }) => {
    const showMobileView =
      platformApi.location.url &&
      urijs(platformApi.location.url).hasQuery('showMobileView', 'true');
    const isCreatedWithResponsiveEditor = getIsCreatedWithResponsiveEditor(
      getState(),
    );
    const { isSSR, isDebug } = getEnvironment(platformApi);
    const isRTL = isRtlLanguage(language);
    const currentPage = platformApi.site.currentPage;
    const apiBaseUrl = isSSR
      ? GLOBALS.API_BASE_URL
      : GLOBALS.API_BASE_URL_CLIENT;

    const basicParams = {
      viewMode,
      instance: getInstance(platformApi)(),
      formFactor: showMobileView
        ? 'Mobile'
        : resolveDeviceType(platformApi, isCreatedWithResponsiveEditor),
      isSSR,
      isRTL,
      isSeo: platformApi.seo.isInSEO(),
      language,
      apiBaseUrl,
      isDebug,
      isProduction: process.env.NODE_ENV === 'production',
      biPageNumber,
      page: platformApi.location.query.page,
      commentId: get(platformApi, 'location.query.commentId'),
      url: platformApi.location.url,
      currentPage,
      timeZone: get(platformApi, 'site.timezone'),
      isRendered,
    };

    dispatch(setBasicParamsAction(basicParams));
  };
