import React from 'react';

import { useRichContent } from 'common/utils/ricos';
import { RicosViewer } from 'common/components/Ricos/RicosViewer';
import type { IAboutGroupChangedActivity } from 'api/feed/types';

import { GROUPS_APP_DEFINITION_ID } from '../../../../../constants';

import { IActivityContentProps } from './types';

export function DescriptionChanged(props: IActivityContentProps) {
  const { item } = props;

  const data = item?.activity?.data as IAboutGroupChangedActivity;
  const groupId = item.applicationContext?.contextId as string;

  const content = useRichContent(data.content);

  return (
    <RicosViewer
      usage="FeedItem"
      content={content}
      postId={item.feedItemId ?? ''}
      containerId={GROUPS_APP_DEFINITION_ID}
      groupId={groupId}
      contentId={item.feedItemId}
    />
  );
}

DescriptionChanged.displayName = 'DescriptionChanged';
