import { ButtonData_Type, Link_Target, type RichContent } from '@wix/ricos';
import type { I$W, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { fileDownloaded } from '@wix/bi-logger-blog-data/v2';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { GLOBALS } from '@wix/communities-blog-client-common';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import { postContentElementIds } from './elementIds';

const PAYWALL_BUTTON_ID = 'c3gvh';

const isMediaFile = (mimeType: string) =>
  ['archive', 'document'].includes(mimeType);

export class PostContentController {
  private readonly ui = {
    richContentViewer: this.$w(`#${postContentElementIds.richContentViewerId}`),

    paywallRichContentViewer: this.$w(
      `#${postContentElementIds.paywallRichContentViewerId}`,
    ),

    multiStateBox: this.$w(`#${postContentElementIds.multiStateBoxId}`),

    paywallRichContentWrapper: this.$w(
      `#${postContentElementIds.paywallRichContentWrapper}`,
    ),
  };

  private readonly facade = new BlocksWidgetFacade(
    this.flowAPI,
    this.platformApi,
  );

  constructor(
    private readonly $w: I$W,
    private readonly flowAPI: PlatformControllerFlowAPI,
    private readonly platformApi: PlatformApi,
  ) {}

  async applyStateToUi(model: PostPageRenderModel) {
    const { post } = model;
    const postContent = post.richContent as RichContent;
    const paywallRichContent = post.paywallRichContent as
      | RichContent
      | undefined;
    const metaSiteId =
      this.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId ?? '';
    const isMobileView = this.flowAPI.environment.isMobile;
    const isEditor = this.flowAPI.environment.isEditor;

    const blogApiBaseUrl =
      isEditor || post.preview
        ? GLOBALS.API_BASE_URL_CLIENT
        : `${this.platformApi.location.baseUrl}${GLOBALS.API_BASE_URL_CLIENT}`;

    if (isEditor || !post.preview) {
      this.ui.paywallRichContentWrapper.delete();
    }

    const resolveFileUrl = async (file: any) => {
      const Authorization = this.platformApi.site?.getAppToken?.(BLOG_APP_ID);

      const reportFileDownloaded = (success: boolean) =>
        this.flowAPI.bi?.report(
          fileDownloaded({
            file_ext: file.type,
            file_id: file.id,
            file_size: file.size,
            mime_type: file.mimeType,
            post_stable_id: post.id,
            is_demo: post.isDemo ?? false,
            origin: 'post',
            success,
          }),
        );

      try {
        const getFileDownloadUrl = async () => {
          if (isMediaFile(file.mimeType)) {
            const response = await this.flowAPI.httpClient.post<{
              url: string;
            }>(
              `${blogApiBaseUrl}/v2/media/download-url`,
              {
                postId: post.id,
                fileId: file.id,
              },
              {
                headers: {
                  Authorization,
                },
              },
            );

            return response.data.url;
          } else {
            const response = await this.flowAPI.httpClient.post<{
              url: string;
            }>(
              `${blogApiBaseUrl}/v2/files/download-url`,
              {
                postId: post.id,
                filePath: file.path,
              },
              { headers: { Authorization } },
            );

            return response.data.url;
          }
        };

        const url = await getFileDownloadUrl();

        reportFileDownloaded(true);

        return url;
      } catch (error) {
        reportFileDownloaded(false);

        return '';
      }
    };

    this.ui.richContentViewer.metaSiteId = metaSiteId;
    this.ui.richContentViewer.isMobileView = isMobileView;
    this.ui.richContentViewer.content = postContent;
    this.ui.richContentViewer.plugins = {
      file: {
        resolveFileUrl,
      },
      hashtag: {
        enabled: true,
        // TODO: this href needs to be resolved correctly
        hrefTemplate: `${model.getBaseURL()}/blog/hashtags/{{hashtag}}`,
      },
    };

    if (paywallRichContent && !isEditor && post.preview) {
      this.ui.paywallRichContentViewer.metaSiteId = metaSiteId;
      this.ui.paywallRichContentViewer.isMobileView = isMobileView;

      const content = paywallRichContent;
      const url = await this.facade.getUrlToPricingPage(post);
      const buttonNode = content.nodes[2];

      if (
        buttonNode.type === 'BUTTON' &&
        buttonNode.buttonData?.type === 'ACTION' &&
        buttonNode.id === PAYWALL_BUTTON_ID
      ) {
        buttonNode.buttonData.type = ButtonData_Type.LINK;
        buttonNode.buttonData.link = {
          url: new URL(model.getBaseURL() + url).href,
          anchor: undefined,
          target: Link_Target.SELF,
          rel: {
            nofollow: true,
            sponsored: false,
          },
        };
      }

      this.ui.paywallRichContentViewer.content = content;
    }
  }
}
