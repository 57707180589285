import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import {
  POST_EXCERPT_MAX_LENGTH,
  isLayoutNameOneOfProGallery,
  isLayoutNameTextOnImage,
  LAYOUT_NAME_SIDE_BY_SIDE,
  isLayoutNameMobile,
  getPostCover,
  resolveId,
} from '@wix/communities-blog-client-common';
import ContentExcerpt from '../../../common/components/content-excerpt';
import Link from '../../../common/components/link/internal-link';
import { PostFooter } from '../../../common/components/post-footer';
import PostListItemCategoryLabel from '../../../common/components/post-list-item-category-label';
import PostListItemCover from '../../../common/components/post-list-item-cover';
import PostListItemHeader from '../../../common/components/post-list-item-header';
import PostTitle from '../../../common/components/post-title';
import RatingsDisplay, {
  RatingsDisplayLayout,
} from '../../../common/components/ratings-display';
import ResponsiveContentExcerpt from '../../../common/components/responsive-content-excerpt';
import { connect } from '../../../common/components/runtime-context';
import { HorizontalSeparatorForPostCard } from '../../../common/components/separator';
import withDeviceType from '../../../common/hoc/with-device-type';
import withFeedBorderWidth from '../../../common/hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withPermissions from '../../../common/hoc/with-permissions';
import withPostFontSize from '../../../common/hoc/with-post-font-size';
import useIsFeedDesignEnabled from '../../../common/hooks/use-is-feed-design-enabled';
import {
  getIsMoreButtonEnabled,
  getMobileContentAlignment,
} from '../../../common/selectors/app-settings-selectors';
import { getPostCoverImageSrc } from '../../../common/selectors/post-selectors';
import { getSection } from '../../../common/selectors/section-selectors';
import { getContentAlignmentStyles } from '../../../common/services/content-alignment-helpers';
import {
  getLineCounts,
  getFeedColorClassName,
} from '../../../common/services/layout-config';
import { getPostActions } from '../../../common/services/post-actions';
import { getIsRTL } from '../../../common/store/basic-params/basic-params-selectors';
import { getIsMemberAreaInstalled } from '../../../common/store/communities-context/communities-context-selectors';
import {
  getPostAverageRating,
  getPostTotalRatings,
} from '../../../common/store/post-ratings/post-ratings-selector';
import alignmentStyles from '../../../common/styles/post-list-item-alignment.scss';
import styles from '../../../common/styles/post-list-item.scss';

export const PostListItem = ({
  type,
  post,
  onLikeClick,
  itemConfig,
  borderWidth,
  titleFontClassName,
  contentFontClassName,
  contentFontClassNameWithStyle,
  postTitleFontSize,
  postDescriptionFontSize,
  postMetadataFontSize,
  isMetadataFooterVisible = true,
  isMetadataHeaderVisible = true,
  showMoreButton,
  showPostDescription,
  index,
  isMoreButtonEnabled,
  can,
  navigateProGalleryWithinPostPage,
  alignment,
  isRTL,
  showCategoryLabel,
  showPostRating,
  isMobile,
  postAverageRating,
  postTotalRatings,
}) => {
  const { applyFeedDesign, getPostClassName, section } =
    useIsFeedDesignEnabled();
  const { shouldRender: withCover } = getPostCover(post);
  const lineCounts = getLineCounts(itemConfig, withCover);
  const postLink = `/${post.slug}`;
  const autoFit = type === LAYOUT_NAME_SIDE_BY_SIDE && withCover;

  const isProGallery = isLayoutNameOneOfProGallery(type);

  const isMobileLayout = isLayoutNameMobile(type);
  const withoutFooter = !isMetadataFooterVisible;
  const withoutHeaderMetadata = !isMetadataHeaderVisible;
  const withoutDescription = !showPostDescription;
  const useResponsive = type === LAYOUT_NAME_SIDE_BY_SIDE;
  const containerClassName = classNames(
    styles.container,
    isProGallery && styles.proGallery,
    styles[type],
    alignmentStyles[alignment],
    withCover && styles.withCover,
    withoutFooter && styles.withoutFooter,
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    getPostClassName(
      'border-color',
      'post-container',
      getFeedColorClassName(type, 'background-color'),
    ),
    isMobileLayout && index > 0 && styles.newPadding,
  );

  const contentWrapperClassName = classNames(
    styles.contentWrapper,
    styles[type],
    isRTL && alignmentStyles.isRTL,
    !applyFeedDesign && isLayoutNameTextOnImage(type) && styles.withBackground,
    withCover && styles.withCover,
    withoutFooter && styles.withoutFooter,
    'post-list-item-wrapper',
    isLayoutNameTextOnImage(type) &&
      getPostClassName('overlay-background-color'),
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
      getFeedColorClassName(type, 'description-fill'),
    ),
    isMobileLayout ? styles.newPadding : styles.boxShadow,
  );

  const linkContainerClassName = classNames(
    isMobileLayout ? styles.mobileWrapper : styles.textWrapper,
    getPostClassName('link-hashtag-hover-color'),
    styles[type],
  );
  const linkClassName = classNames(
    styles.titleWrapper,
    isProGallery && styles.proGallery,
    styles[type],
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    post.isPinned && styles.withIcons,
    showMoreButton && isMoreButtonEnabled
      ? styles.withShowMoreButton
      : styles.withoutShowMoreButton,
  );
  const titleContainerClassName = classNames(
    styles.title,
    titleFontClassName,
    styles[type],
    alignmentStyles.textAlign,
    withCover && styles.withCover,
    withoutFooter && styles.withoutFooter,
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    withoutDescription && withoutFooter && styles.withoutBottomMargin,
    withoutDescription && styles.withoutDescription,
    post.isPinned && styles.withIcons,
    showMoreButton && isMoreButtonEnabled
      ? styles.withShowMoreButton
      : styles.withoutShowMoreButton,
    showCategoryLabel && styles.withCategoryLabel,
  );
  const titleClassName = classNames(
    getPostClassName('title-font', getFeedColorClassName(type, 'title-color')),
    useResponsive && styles.responsiveTitle,
  );
  const categoryContainerClassName = classNames(
    styles.category,
    styles[type],
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
  );
  const contentClassName = classNames(
    styles.content,
    styles[type],
    alignmentStyles.textAlign,
    withoutFooter && styles.withoutFooter,
    contentFontClassNameWithStyle,
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    getPostClassName('description-style-font'),
    styles.excerptLink,
  );
  const headerClassName = classNames(
    contentFontClassName,
    getPostClassName('description-font'),
    alignmentStyles.headerContainer,
  );
  const footerClassName = classNames(
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
    ),
  );
  const titleStyle = isMobileLayout ? { fontSize: postTitleFontSize } : {};
  const contentStyle =
    !applyFeedDesign || isMobileLayout
      ? { fontSize: postDescriptionFontSize }
      : {};

  const renderCover = () => {
    const coverProps = {
      post,
      postLink,
      type,
      canPlayVideo: true,
      isPublic: can('share', 'post', post),
      videoClassName: classNames(styles.videoEmbed),
      imageClassName: classNames(styles.image),
      useAlternateDimesions: withoutFooter,
      ...(useResponsive
        ? {
            height: 518,
            width: 690,
            withoutContainerStyle: true,
          }
        : {}),
    };

    const cover = <PostListItemCover {...coverProps} />;

    return useResponsive ? (
      <div className={styles.coverContainer}>
        <div className={styles.coverWrapper}>{cover}</div>
      </div>
    ) : (
      cover
    );
  };

  const containerStyle = isProGallery
    ? {
        ...(getPostCoverImageSrc(post) && { background: 'transparent' }),
        borderWidth: 0,
      }
    : {
        borderWidth,
      };

  return (
    <article
      className={containerClassName}
      tabIndex="0"
      style={containerStyle}
      data-hook="post-list-item"
    >
      {withCover && renderCover()}
      <div className={contentWrapperClassName}>
        <PostListItemHeader
          className={headerClassName}
          post={post}
          showMoreButton={isMoreButtonEnabled}
          showProfileImage={!isLayoutNameTextOnImage(type)}
          style={{ fontSize: postMetadataFontSize }}
          type={type}
        />
        {showCategoryLabel && (
          <div className={categoryContainerClassName}>
            <PostListItemCategoryLabel
              className={alignmentStyles.categoryLabel}
              post={post}
              postListLayout={type}
            />
          </div>
        )}
        <div
          className={linkContainerClassName}
          style={{ display: 'flex-inline' }}
        >
          <Link
            fullRoute={post.link}
            to={postLink}
            className={linkClassName}
            addHoverClasses={false}
          >
            <div
              style={{ fontSize: postTitleFontSize }}
              className={titleContainerClassName}
              data-hook="post-list-item__title"
            >
              <PostTitle
                fullRoute={post.link}
                type={type}
                title={post.title}
                style={titleStyle}
                className={titleClassName}
                {...(useResponsive ? {} : { lineCount: lineCounts.title })}
                showCategoryLabel={showCategoryLabel}
              />
              {showPostRating && postTotalRatings ? (
                <RatingsDisplay
                  className={classNames(
                    styles.rating,
                    styles[section],
                    styles[type],
                    alignmentStyles.rating,
                    {
                      [styles.withoutFooter]: withoutFooter,
                      [styles.withTextOnImageStyles]:
                        isLayoutNameTextOnImage(type),
                    },
                  )}
                  rating={postAverageRating}
                  count={postTotalRatings}
                  layout={
                    isMobile
                      ? RatingsDisplayLayout.with_range
                      : RatingsDisplayLayout.count_only
                  }
                />
              ) : null}
            </div>
          </Link>
          {showPostDescription && lineCounts.description ? (
            <div
              style={contentStyle}
              className={contentClassName}
              data-hook="post-description"
              onClick={() => navigateProGalleryWithinPostPage(post.slug)}
            >
              {useResponsive ? (
                <ResponsiveContentExcerpt post={post} />
              ) : (
                <ContentExcerpt
                  type={type}
                  content={post.content}
                  excerpt={post.excerpt}
                  lineCount={lineCounts.description}
                  autoFit={autoFit}
                  maxLength={POST_EXCERPT_MAX_LENGTH}
                />
              )}
            </div>
          ) : null}
        </div>
        {isMetadataFooterVisible && (
          <div
            className={classNames(
              styles.footer,
              styles[type],
              !withCover && styles.withoutCover,
            )}
          >
            {!isMobileLayout && (
              <HorizontalSeparatorForPostCard
                className={classNames(styles.separator, styles[type])}
              />
            )}
            <div style={{ fontSize: postMetadataFontSize }}>
              <PostFooter
                post={post}
                onLikeClick={onLikeClick}
                displayIcons={itemConfig.displayFooterIcons}
                className={footerClassName}
                commentClassName={classNames(
                  getPostClassName('link-hashtag-hover-color'),
                )}
              />
            </div>
          </div>
        )}
        {isMobileLayout && (
          <HorizontalSeparatorForPostCard
            className={classNames(
              styles.separator,
              styles.mobileSeparator,
              styles[type],
              isMetadataFooterVisible && styles.withMetadata,
            )}
          />
        )}
      </div>
    </article>
  );
};

PostListItem.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  post: PropTypes.object,
  type: PropTypes.string.isRequired,
  itemConfig: PropTypes.object.isRequired,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  postTitleFontSize: PropTypes.number.isRequired,
  postDescriptionFontSize: PropTypes.number.isRequired,
  postMetadataFontSize: PropTypes.number.isRequired,
  isMetadataFooterVisible: PropTypes.bool,
  isMetadataHeaderVisible: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showPostDescription: PropTypes.bool,
  index: PropTypes.number,
  isMoreButtonEnabled: PropTypes.bool,
  navigateProGalleryWithinPostPage: PropTypes.func.isRequired,
  showCategoryLabel: PropTypes.bool,
  alignment: PropTypes.string,
  showPostRating: PropTypes.bool,
  isMobile: PropTypes.bool,
  postAverageRating: PropTypes.number,
  postTotalRatings: PropTypes.number,
};

const mapRuntimeToProps = (state, { post, canSee }, actions) => {
  const isRTL = getIsRTL(state);
  const contentAlignmentMobile = getMobileContentAlignment(state, isRTL);
  const postActions = getPostActions({
    post,
    canSee,
    enableShare: true,
    enableSubscribe: getIsMemberAreaInstalled(state),
  });
  const showMoreButton = Boolean(postActions.length);

  return {
    showMoreButton,
    section: getSection(state),
    isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
    navigateProGalleryWithinPostPage: actions.navigateProGalleryWithinPostPage,
    alignment: getContentAlignmentStyles(contentAlignmentMobile, isRTL),
    isRTL,
    postAverageRating: getPostAverageRating(state, resolveId(post)),
    postTotalRatings: getPostTotalRatings(state, resolveId(post)),
  };
};

// prettier-ignore
export default flowRight(
  withPermissions,
  withFontClassName,
  withPostFontSize,
  withFeedMetadataSettings,
  withFeedBorderWidth,
  withDeviceType,
  connect(mapRuntimeToProps),
)(PostListItem);
