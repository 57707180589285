import React from 'react';

import {
  Toast as ToastWUT,
  ToastPlacement,
  ToastProps,
  ToastSkin,
} from 'wix-ui-tpa';

import { FCWithChildren } from '@wix/challenges-web-library';

import { st, classes } from './Toast.st.css';
import { ToastType } from '../../contexts/ToastContext/interfaces';

interface IToastProps extends Omit<ToastProps, 'skin'> {
  isMobile: boolean;
  theme: ToastType;
}
export const Toast: FCWithChildren<IToastProps> = ({
  isMobile,
  theme,
  onClose,
  isShown,
  children,
}) => {
  React.useEffect(() => {
    let timer;

    if (isShown) {
      timer = setTimeout(() => {
        onClose(null);
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isShown, onClose]);
  if (!isShown) {
    return null;
  }
  return (
    <>
      <ToastWUT
        className={st(classes.root, {})}
        placement={
          isMobile ? ToastPlacement.bottomFullWidth : ToastPlacement.inline
        }
        shouldAnimate={true}
        shouldShowCloseButton={true}
        onClose={onClose}
        // done because of casting styles in WUT.
        // if include type from WUT it throws errors to console because of mixing imports ts<>tsx
        skin={theme as unknown as ToastSkin}
        isShown={isShown}
      >
        {children}
      </ToastWUT>
    </>
  );
};
