import React from 'react';

import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { st, classes } from './Widget.st.css';
import { useUser } from '../../../contexts/User/UserContext';
import settingsParams from '../settingsParams';
import { IChallengeSettings } from '../Settings/challengeSettings/challengeSettings.types';
import { ChallengeCompletedModal } from '../../../components-shared/ChallengeCompletedModal/ChallengeCompletedModal';
import { withProviders } from '../../../contexts/main/withProviders';
import { FCWithChildren } from '@wix/challenges-web-library';
import SidebarLayoutForParticipant from './views/SidebarLayout/SidebarLayoutForParticipant';
import { IChallengePageProps } from '../../ChallengesPage/Widget/interfaces';
import { useFullscreen } from '../../../contexts/Fullscreen/FullscreenContext';
import { LeaveChallengeModalProvider } from '../../../contexts/LeaveChallengeModal/LeaveChallengeModalProvider';
import { FocusManagerProvider } from '../../../contexts/FocusManager/useFocusManager';

export const ParticipantPage: FCWithChildren<IChallengePageProps> = (props) => {
  const { t } = useTranslation();
  const { isEditor, isViewer, isMobile, language } = useEnvironment();
  const user = useUser();

  const settings = useSettings();
  const pageRef = React.useRef<HTMLDivElement>();

  const settingsProp: IChallengeSettings = {} as any;
  Object.keys(settingsParams).map((key) => {
    settingsProp[key] = settings.get(settingsParams[key]);
  });
  const { isFullscreen } = useFullscreen();

  const { challengeData } = props;

  React.useEffect(() => {
    props.hideLoader && props.hideLoader();
  }, []);

  if (props.isForcedBlankPage) {
    return null;
  }
  if (!challengeData?.challenge && isViewer) {
    return <p className={classes.noAccess}>{t('challenge.no-access')}</p>;
  }

  return (
    <div
      ref={pageRef}
      data-hook="program-page-main"
      className={st(classes.root)}
    >
      <div data-hook="participant-page">
        <LeaveChallengeModalProvider
          leaveProgram={user.leaveTheChallenge}
          showModal={null}
        >
          <FocusManagerProvider>
            <SidebarLayoutForParticipant
              {...props}
              isEditor={isEditor}
              isMobile={isMobile}
              t={t}
              pageRef={pageRef}
              settings={settingsProp as any}
              lng={language}
              isFullscreen={isFullscreen}
            />
          </FocusManagerProvider>
        </LeaveChallengeModalProvider>

        <ChallengeCompletedModal />
      </div>
    </div>
  );
};

ParticipantPage.displayName = 'ParticipantContainer';

export default withProviders(ParticipantPage);
