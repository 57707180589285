import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FAQService } from '../../../../../utils/FAQService';
import { Text } from 'wix-ui-tpa';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import settingsParams from '../../../settingsParams';
import { AppContext } from '../SettingsProvider/SettingsProvider';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import { parseDraftJS } from '../helpers/ParseDraftJS';
import { st, classes } from '../../Widget.st.css';
import SelectedLayout from './SelectedLayout';

const QuestionsWrapper = (props: { searchTerm?: string }) => {
  const { searchTerm } = props;
  const settings: ISettingsContextValue = useSettings();

  const { instance, selectedCategory, setFirstQuestionId } =
    useContext(AppContext);
  const { t } = useTranslation();
  const api = useMemo(() => new FAQService(instance), [instance]);
  const [questionsList, setQuestionsList] = useState([]);

  useEffect(() => {
    const asyncFun = async () => {
      if (searchTerm) {
        return;
      }
      const { questionEntries } = await api.getListQuestions({
        categoryId: selectedCategory,
      });
      setQuestionsList(questionEntries as []);
    };
    asyncFun();
  }, [api, searchTerm, selectedCategory]);

  let filteredQuestions: QuestionEntry[] = questionsList;
  if (searchTerm) {
    filteredQuestions = questionsList.filter((item: QuestionEntry) => {
      const questionText = item.question?.toLowerCase() || '';
      const answerText = parseDraftJS(item?.draftjs || '').toLowerCase();
      const searchQuery = searchTerm.toLowerCase();
      return (
        questionText.includes(searchQuery) || answerText.includes(searchQuery)
      );
    });
  }
  const showFirstAnswer: boolean = settings.get(settingsParams.openFirstAnswer);
  const assignFirstQuesionId = useCallback(() => {
    if (setFirstQuestionId) {
      if (!showFirstAnswer) {
        setFirstQuestionId(undefined);
      } else if (filteredQuestions.length > 0 && filteredQuestions[0].id) {
        setFirstQuestionId(filteredQuestions[0].id);
      }
    }
  }, [filteredQuestions, setFirstQuestionId, showFirstAnswer]);
  useEffect(() => {
    assignFirstQuesionId();
  }, [assignFirstQuesionId]);
  return filteredQuestions.length > 0 ? (
    <SelectedLayout filteredQuestions={filteredQuestions} />
  ) : (
    <div className={st(classes.Body)}>
      <Text>{t('widget.search.tab.no.results.found')}</Text>
    </div>
  );
};

export default QuestionsWrapper;
