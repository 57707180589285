import { includes, trimStart, values } from 'lodash';
import type {
  INavigateToSectionOptions,
  NavOptions,
} from '@wix/yoshi-flow-editor';
import { SEARCH_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { INSTANCE_ID_WIX } from '@wix/communities-universal/dist/src/constants/wix-blogs';
import {
  encodeQuery,
  getCustomPostUrl,
  SECTION_BLOG_PAGE,
  SECTION_BLOG_POST_PAGE,
} from '@wix/communities-blog-client-common';
import { type FeedPageThunkAction } from '../../feed-page/types';
import { type PlatformApi } from '../controller/platform-api';
import { encodeURIComponentIfNeeded } from '../services/uri';
import { isSite } from '../store/basic-params/basic-params-selectors';
import { getTopology } from '../store/topology/topology-selectors';
import type { AppState, AppStore } from '../types';

export const navigateInPreview =
  (link: string, sectionId: string): FeedPageThunkAction =>
  (dispatch, getState: () => AppState, { platformApi, compId }) => {
    const { baseUrl = '', sectionUrl = '' } = getTopology(getState());
    const path = link.replace(baseUrl, '');

    if (platformApi.location.navigateToSection) {
      let sectionOptionsState: INavigateToSectionOptions['state'] | undefined;

      if (sectionId === SECTION_BLOG_POST_PAGE) {
        const post = Object.values(getState().posts).find(
          (_post) => _post.link === link,
        );

        sectionOptionsState = trimStart(post?.slug, '/');
      } else {
        const sectionPath = getSectionPath({ getState, sectionId });
        const strippedPath = path.replace(sectionPath, '');

        sectionOptionsState = trimStart(strippedPath, '/');
      }

      return platformApi.location.navigateToSection({
        // @ts-expect-error
        compId,
        sectionId,
        noTransition: true,
        state: sectionOptionsState,
      });
    }

    if (path === '' || path === '/') {
      const sectionPath = sectionUrl.replace(baseUrl, '');
      return platformApi.location.to?.(sectionPath);
    }

    return platformApi.location.to?.(path);
  };

export const navigateToSectionInPreview =
  (path: string, sectionId: string): FeedPageThunkAction =>
  (_dispatch, getState, { platformApi, compId }) => {
    return platformApi.location.navigateToSection({
      // @ts-expect-error
      compId,
      sectionId,
      noTransition: true,
      state: trimStart(path, '/'),
    });
  };

export const navigateToSearch =
  (path: string): FeedPageThunkAction =>
  async (dispatch, getState, { platformApi }) => {
    const isSiteSearchInstalled = await platformApi.site.isAppSectionInstalled({
      appDefinitionId: SEARCH_APP_ID,
      sectionId: 'search_results',
    });

    if (isSiteSearchInstalled) {
      const query = encodeQuery(path);
      const queryParams = {
        q: query,
        type: 'blogs',
      };

      navigate({
        platformApi,
        getState,
        sectionPath: '/search',
        path: '',
        queryParams,
      });
    } else {
      navigate({
        platformApi,
        getState,
        path: path ? `/search/${encodeQuery(path)}` : '/search',
        sectionId: SECTION_BLOG_PAGE,
      });
    }
  };

export const navigateWithinBlog =
  (path: string, options?: NavOptions): FeedPageThunkAction =>
  (dispatch, getState, { platformApi }) =>
    navigate({
      platformApi,
      getState,
      sectionId: SECTION_BLOG_PAGE,
      path,
      options,
    });

export const navigateWithinPostPage =
  (path: string): FeedPageThunkAction =>
  (dispatch, getState, { platformApi }) =>
    navigate({
      platformApi,
      getState,
      sectionId: SECTION_BLOG_POST_PAGE,
      path,
    });

export const navigateToCustomPostUrl =
  (customUrl: string, fallbackPath: string): FeedPageThunkAction =>
  (dispatch, getState, { platformApi }) => {
    if (customUrl) {
      const { baseUrl = '' } = getTopology(getState());
      return platformApi.location.to?.(customUrl.replace(baseUrl, ''));
    }

    return dispatch(navigateWithinPostPage(fallbackPath));
  };

export const navigateProGalleryWithinPostPage =
  (postSlug: string): FeedPageThunkAction =>
  (dispatch, getState, { platformApi, appParams }) => {
    const state = getState();
    const { baseUrl = '', postPageSectionUrl: sectionUrl = '' } =
      getTopology(state);
    const sectionPath = sectionUrl.replace(baseUrl, '');

    const customPostUrl = getCustomPostUrl(state, postSlug).replace(
      baseUrl,
      '',
    );
    const path = customPostUrl || `${sectionPath}/${postSlug}`;

    if (!isSite(state)) {
      return platformApi.location.to?.(path);
    }

    /** Wix Code navigation doesn't work properly on marketing sites */
    const isMarketingBlog = includes(
      values(INSTANCE_ID_WIX),
      appParams.instanceId,
    );

    const finalPath = isMarketingBlog
      ? // Temporary hack, because wix code navigation doesn't work properly
        customPostUrl || `${sectionUrl}/${postSlug}`
      : path;

    return platformApi.location.to?.(finalPath);
  };

function navigate({
  platformApi,
  getState,
  sectionId,
  path,
  options,
  sectionPath,
  queryParams = {},
}: {
  platformApi: PlatformApi;
  getState: AppStore['getState'];
  sectionId?: string;
  path: string;
  options?: NavOptions;
  sectionPath?: string;
  queryParams?: Record<string, any>;
}) {
  sectionPath ||= getSectionPath({ getState, sectionId });
  return platformApi.location.to?.(
    `${sectionPath}${path}${queryObjectToString(queryParams)}`,
    options,
  );
}

function getSectionPath({
  getState,
  sectionId,
}: {
  getState: AppStore['getState'];
  sectionId?: string;
}) {
  const { baseUrl, sectionUrl, postPageSectionUrl } = getTopology(getState());
  const _sectionUrl =
    sectionId === SECTION_BLOG_PAGE ? sectionUrl : postPageSectionUrl;

  return _sectionUrl?.replace(baseUrl ?? '', '') ?? '';
}

function queryObjectToString(queryParams: Record<string, any>) {
  if (
    typeof queryParams !== 'object' ||
    Object.keys(queryParams).length === 0
  ) {
    return '';
  }

  return `?${Object.keys(queryParams)
    .map((key) => `${key}=${encodeURIComponentIfNeeded(queryParams[key])}`)
    .join('&')}`;
}
