import React from 'react';
import cls from 'classnames';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  Button as TPAButton,
  ButtonPriority,
  ButtonProps as TPAButtonProps,
  ButtonSize,
} from 'wix-ui-tpa/cssVars';

import { Spinner } from 'wui/Spinner';

import {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';

import classes from './Button.scss';

export interface IButtonProps
  extends Omit<TPAButtonProps, 'priority' | 'size'> {
  loading?: boolean;
  fullWidth?: boolean;

  size?: 'tiny' | 'small' | 'medium' | 'large';

  /**
   * Button skin, use `primary` for wired variant and `basic` for Black & White (dialogs)
   */
  variant?: 'primary' | 'basic';

  /**
   * Weather or not to use "filled" style
   */
  outlined?: boolean;
}

export const Button = React.forwardRef(
  (props: OverridableComponentProps<IButtonProps, 'button'>, ref) => {
    const {
      variant = 'primary',
      className,
      children,
      loading,
      fullWidth,
      size,
      outlined = false,
      ...rest
    } = props;

    const { isMobile } = useEnvironment();

    return (
      <TPAButton
        upgrade
        ref={ref}
        fullWidth={fullWidth}
        priority={getPriority(props.variant, outlined)}
        data-loading={loading}
        size={size as ButtonSize}
        className={cls(classes.root, className, {
          [classes.mobile]: isMobile,
          [classes.loading]: loading,
          [classes.fullWidth]: fullWidth,
          [classes.primary]: variant === 'primary',
          [classes.basic]: variant === 'basic',
          [classes.filled]: !outlined,
          [classes.outlined]: outlined,
          [classes.tiny]: size === 'tiny',
        })}
        {...rest}
      >
        {loading ? <Spinner className={classes.spinner} size={20} /> : children}
      </TPAButton>
    );
  },
) as OverridableComponent<IButtonProps, 'button'>;

Button.displayName = 'wui/Button';

export { ButtonSize };

function getPriority(
  variant?: IButtonProps['variant'],
  outlined?: boolean,
): ButtonPriority {
  switch (variant) {
    case 'primary':
      return outlined ? ButtonPriority.secondary : ButtonPriority.primary;

    case 'basic':
    default:
      return outlined ? ButtonPriority.basicSecondary : ButtonPriority.basic;
  }
}
