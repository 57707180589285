import React from 'react';

import { useSettings } from '@wix/tpa-settings/react';
import { Video } from '../../../../../../../../components-shared/Video';
import challengeSettings from '../../../../../../settingsParams';
import {
  FCWithChildren,
  IImageData,
  imageDataToMediaUrl,
} from '@wix/challenges-web-library';
import { classes, st } from './VisitorPageMedia.st.css';
import { getMediaAspectRatio } from '../../../../../../../../utils/getMediaAspectRatio';
import { useChallengeData } from '../../../../../../../../contexts/storage-contexts/Challenge';
import { useCSSPBStyle } from '../../../../../../../../hooks/useCSSPBStyle';
import { visitorPageMediaDataHooks } from '../VisitorPageMediaDataHooks';
import { getProgramAltImageText } from '../../../../../../../../selectors/getProgramImageAltText';
import { WowImage } from 'wix-ui-tpa';

export const VisitorPageMediaMobile: FCWithChildren<{}> = () => {
  const settings = useSettings();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const challengeDescriptionMedia = challenge?.settings?.description?.media;
  const getStyle = useCSSPBStyle();
  const cssPBStyle = getStyle({ displayVar: '--opgDisplayHeaderImage' });
  const displayHeader = cssPBStyle
    ? true
    : settings.get(challengeSettings.displayHeaderImage);
  const isShouldBeRendered = displayHeader && challengeDescriptionMedia;

  const isVideo = challengeDescriptionMedia?.video;
  const mediaRationPercents = (
    100 /
    getMediaAspectRatio(
      challengeDescriptionMedia?.image || challengeDescriptionMedia?.video,
    )
  ).toFixed(2);

  return isShouldBeRendered ? (
    <figure
      className={`${st(classes.root)} `}
      data-hook={visitorPageMediaDataHooks.mobileMedia()}
      style={cssPBStyle}
    >
      <div
        className={classes.imageRatioBox}
        style={{
          paddingTop: `${mediaRationPercents}%`,
        }}
      >
        <div className={classes.imageRatioBoxInner}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isVideo ? (
            <Video
              altText={getProgramAltImageText(challenge)}
              className={classes.mediaItem}
              thumbnail={challengeDescriptionMedia?.video?.thumbnail}
              video={challengeDescriptionMedia?.video}
            />
          ) : (
            <WowImage
              alt={getProgramAltImageText(challenge)}
              src={imageDataToMediaUrl({
                ...(challengeDescriptionMedia?.image as IImageData),
                width: 1000,
                height: 800,
              })}
              stretchImage={true}
            />
          )}
        </div>
      </div>
    </figure>
  ) : null;
};
