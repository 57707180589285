import type { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import { TagOrder, TagAlignment } from '@wix/communities-blog-client-common';

export const getTagTextAlignment = (tagAlignment: TagAlignment) => {
  switch (tagAlignment) {
    case TagAlignment.Left:
      return 'left';
    case TagAlignment.Center:
      return 'center';
    case TagAlignment.Right:
      return 'right';
    default:
      return 'left';
  }
};

export const sortTags = (tags: BlogTag[], tagOrder: TagOrder): BlogTag[] => {
  switch (tagOrder) {
    case TagOrder.Alphabetical:
      return [...tags].sort((a, b) =>
        (a?.label || '').localeCompare(b?.label || ''),
      );
    case TagOrder.MostUsed:
      return [...tags].sort((a, b) => {
        const aCount = a?.postCount || 0;
        const bCount = b?.postCount || 0;

        if (aCount < bCount) {
          return 1;
        }
        if (aCount > bCount) {
          return -1;
        }

        return (a?.label || '').localeCompare(b?.label || '');
      });
    default:
      return tags;
  }
};
