import { find, includes } from 'lodash';
import { createSelector } from 'reselect';
import {
  getInternalLinkRoute,
  getMixedIds,
  getMixedIdsMap,
  type NormalizedCategory,
  type PostListWidgetPost,
  ROUTE_PREFIX_CATEGORIES,
} from '@wix/communities-blog-client-common';
import type { AppState } from '../../types';

type State = { categories?: NormalizedCategory[] };

export const getCategories = (state: State): NormalizedCategory[] =>
  state.categories ?? [];

export const getCategoriesSlice = createSelector(
  [getCategories, (_, size: number) => size],
  (categories, size) => categories.slice(0, size),
);

export const getCategoryIds = createSelector([getCategories], (categories) => {
  return getMixedIds(categories);
});

export const getCategoriesMap = createSelector([getCategories], (categories) =>
  getMixedIdsMap(categories),
);

export const getCategory = (state: State, id: string) =>
  getCategoriesMap(state)[id];

export const getCategoryCount = (state: State) => getCategories(state).length;

export const getMainCategory = (state: State, post: PostListWidgetPost = {}) =>
  post.mainCategory ?? resolveMainCategory(state, post);

export const getMainCategoryLink = (
  state: State,
  post: PostListWidgetPost = {},
  topology = {},
) => {
  if (post.mainCategory) {
    return post.mainCategory.link;
  }

  const mainCategory = resolveMainCategory(state, post);
  if (mainCategory) {
    return getInternalLinkRoute({
      topology,
      to: `/${ROUTE_PREFIX_CATEGORIES}/${mainCategory.slug}`,
      shouldNavigateToFeed: true,
    });
  }

  return undefined;
};

export const getMainCategoryDisplayLabel = (
  state: AppState,
  post: PostListWidgetPost = {},
) => {
  if (post.mainCategory?.label) {
    return post.mainCategory?.label;
  }

  const mainCategory = resolveMainCategory(state, post);

  if (mainCategory) {
    return mainCategory.menuLabel ?? mainCategory.label;
  }

  return undefined;
};

export const getCategoryBySlug = createSelector(
  [getCategories, (_, slug: string | undefined) => slug],
  (categories, categorySlug) =>
    find(
      categories,
      (category) =>
        includes(category.slugs, categorySlug) ||
        includes(category.slugs, `${categorySlug}/`),
    ),
);

export const getCategoryByTranslationSlug = createSelector(
  [
    getCategories,
    (_, slug: string) => slug,
    (_, __, language: string) => language,
  ],
  (categories, translationSlug, language) =>
    categories.find(
      (category) =>
        category.language === language &&
        category.translations?.find(({ url }) =>
          url?.path?.includes(translationSlug),
        ),
    ),
);

export function resolveMainCategory(
  state: State,
  post: { categoryIds?: string[] } = {},
) {
  const mainCategoryId = post.categoryIds?.[0];

  if (mainCategoryId) {
    return getCategory(state, mainCategoryId);
  }

  return undefined;
}
