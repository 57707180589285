import { resolveProgramSlug } from '@wix/ambassador-challenges-v1-challenge/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getLocationFromFlowAPI } from '../../Location/locationProviderPropsMap';
import { getChallengeData } from './data';
import { getChallengeSlugFromLocation } from '../../Location/helpers/getChallengeSlugFromLocation';
import { isMockedChallenge } from '../../main/getMockedChallenges';
import { WarmupData } from '../../../services/WarmupData';

let resolveSlugResponsePromise: Promise<{ data?: { programId?: string } }>;

const withCache = <T extends Function>(fn: T): T => {
  return (async (flowAPI: ControllerFlowAPI, ...rest: any[]) => {
    const warmupData = new WarmupData(flowAPI);
    if (!resolveSlugResponsePromise) {
      const warmupProgramId = warmupData.get('resolvedProgramId');
      if (warmupProgramId) {
        resolveSlugResponsePromise = Promise.resolve(warmupProgramId);
      } else {
        resolveSlugResponsePromise = fn(flowAPI, ...rest);
        if (flowAPI.environment.isSSR) {
          warmupData.set('resolvedProgramId', await resolveSlugResponsePromise);
        }
      }
    }

    return resolveSlugResponsePromise;
  }) as any as T;
};

export const resolveProgramId = withCache(
  async (
    flowAPI: ControllerFlowAPI,
  ): Promise<{
    programId: string;
  }> => {
    const programId = getProgramIdFromQueryParams(flowAPI);
    if (isMockedChallenge(programId, flowAPI)) {
      return { programId };
    }
    if (programId) {
      return { programId };
    }
    if (!flowAPI.environment.isViewer) {
      return { programId: (await getChallengeData(flowAPI)).challenge.id };
    }
    const challengeSlugFromLocation = await getChallengeSlugFromLocation(
      flowAPI,
    );
    if (isMockedChallenge(challengeSlugFromLocation, flowAPI)) {
      return { programId: challengeSlugFromLocation };
    }
    if (!challengeSlugFromLocation) {
      return { programId: '' };
    }
    const resolveSlugResponse = await flowAPI.httpClient.request(
      resolveProgramSlug({ slug: challengeSlugFromLocation }),
    );
    return { programId: resolveSlugResponse?.data?.programId };
  },
);

function getProgramIdFromQueryParams(flowAPI: ControllerFlowAPI) {
  return getLocationFromFlowAPI(flowAPI).query?.programId;
}

export const clearResolvedProgramIdCache = () => {
  resolveSlugResponsePromise = undefined;
};
