import React from 'react';
import {
  LAYOUT_NAME_SIDE_BY_SIDE_MOBILE,
  LAYOUT_NAME_TEXT_ON_IMAGE_MOBILE,
  type LayoutName,
} from '@wix/communities-blog-client-common';
import {
  DEFAULT_MOBILE_VIEWPORT_WIDTH,
  getMobileScaleFactor,
} from '../../../services/viewport';

const isSquareImage = (layoutName: LayoutName) => {
  return [
    LAYOUT_NAME_SIDE_BY_SIDE_MOBILE,
    LAYOUT_NAME_TEXT_ON_IMAGE_MOBILE,
  ].includes(layoutName);
};

type Params = {
  container: {
    width: number;
    height: number;
  };
  component: {
    width: number | undefined;
  };
  layoutName: LayoutName;
};

export const useImageDimensions = ({
  container,
  component,
  layoutName,
}: Params) => {
  const width =
    container.width ?? component.width ?? DEFAULT_MOBILE_VIEWPORT_WIDTH;

  const [result, setResult] = React.useState<{
    width: number;
    height: number | undefined;
  }>({
    width,
    height: isSquareImage(layoutName) ? width : undefined,
  });

  React.useEffect(() => {
    const scaleFactor = getMobileScaleFactor();

    setResult((prev) => ({
      width: Math.floor(prev.width * scaleFactor),
      height: isSquareImage(layoutName)
        ? Math.floor(prev.width * scaleFactor)
        : undefined,
    }));
  }, [layoutName]);

  return result;
};
