const constants = {
  APP_NAME: 'faqApp',
  // APP_ID: config.APP_DEFINITION_ID,
  // APP_PRODUCTION_ID: config.APP_DEFINITION_ID,
  ORIGIN: 'app_settings',
  WIDGET_ORIGIN: 'widget',
  DEFAULT_LANGUAGE: 'en',
  LAYOUT: {
    BASIC: 'basic',
    COLLAPSE: 'collapse',
    STRIPES: 'stripes',
  },
  HEIGHT_APP: {
    FIXED: 'fixed',
    ADJUST: 'adjust',
  },
  MAX_DIMENSION_SIZE: 300,
  DISPLAY_CATEGORIES: {
    ABOVE: 'above',
    TOP: 'top',
    SIDE: 'side',
  },
  DISPLAY_CONTENT: {
    SPLIT_INTO_CATEGORIES: 'splitIntoCategories',
    ONE_LIST_OF_QUESTIONS: 'oneListOfQuestions',
  },
  SPACES: {
    LOOSE: 'loose',
    TIGHT: 'tight',
  },
  ALIGNMENT: {
    RIGHT: 'right',
    CENTER: 'center',
    LEFT: 'left',
  },
  QUESTION_OPEN_ICON: {
    ARROW: 'arrow',
    PLUS: 'plus',
  } as const,
  RTL_LANGUAGE: ['he', 'ar'],

};

export default constants;
