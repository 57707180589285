import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { SECTION_RELATED_POSTS } from '@wix/communities-blog-client-common';
import AppLoaded from '../../../common/components/app-loaded';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { useSelector } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { useI18n } from '../../../common/hooks/use-i18n';
import {
  getIsCreatedWithResponsiveEditor,
  getLayoutMargins,
  getUseMobileDesignSettings,
  getUseMobileLayoutSettings,
} from '../../../common/selectors/app-settings-selectors';
import { getLayoutType } from '../../../common/selectors/layout-selectors';
import { getIsMobile } from '../../../common/store/basic-params/basic-params-selectors';
import type { AppState } from '../../../common/types';
import RelatedPosts from '../related-posts';
import styles from './app-root.scss';

const AppRoot: React.FC = () => {
  useI18n();
  const { isEditor } = useEnvironment();
  const createdWithResponsiveEditor = useSelector(
    getIsCreatedWithResponsiveEditor,
  );
  const useMobileDesign = useSelector(getUseMobileDesignSettings);
  const isMobile = useSelector(getIsMobile);
  const layoutMargins = useSelector((state: AppState) =>
    getLayoutMargins({
      state,
      section: SECTION_RELATED_POSTS,
      layoutType: getLayoutType(
        state,
        SECTION_RELATED_POSTS,
        getUseMobileLayoutSettings(state),
      ),
    }),
  );

  const padding = React.useMemo(() => {
    if (
      (isMobile && !getUseMobileLayoutSettings) ||
      createdWithResponsiveEditor
    ) {
      return 0;
    } else {
      return layoutMargins;
    }
  }, [createdWithResponsiveEditor, isMobile, layoutMargins]);

  useEffect(() => {
    if (!isEditor) {
      document.documentElement.classList.add('enable-scroll');
    }
    if (isEditor && createdWithResponsiveEditor) {
      document.body.style.height = 'auto';
    }
  }, [createdWithResponsiveEditor, isEditor]);

  return (
    <ResponsiveListener dataHook="related-posts-root">
      <div
        style={{ padding }}
        className={classNames(
          styles.root,
          useMobileDesign ? styles.mobileBaseStyles : styles.baseStyles,
        )}
      >
        <RelatedPosts />
        <AppLoaded />
      </div>
    </ResponsiveListener>
  );
};

export default withReduxStore(AppRoot);
