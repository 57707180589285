import React from 'react';
import {
  ErrorMonitorBoundary,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { TransitionGroup } from 'react-transition-group';

import type { PagingMetadata } from '@wix/ambassador-social-groups-v2-group/types';
import type { IGroup, IGroupListOperationStatus } from 'store/groups';

import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { List } from 'wui/List';
import { Divider } from 'wui/Divider';
import { Fade } from 'wui/Fade';
import { Show } from 'wui/Show';
import { ErrorState } from 'wui/ErrorState';

import { GroupListItem, GroupListItemSkeleton } from './GroupListItem';

import styleParams from 'Groups/stylesParams';

const { groupCardListTopBottomSpacing } = styleParams;

function getPlaceholders(count: number) {
  return new Array(count).fill(0).map((_, index) => (
    <Fade key={index}>
      <GroupListItemSkeleton key={index} />
    </Fade>
  ));
}

interface IGroupListProps {
  groups: IGroup[];
  meta: PagingMetadata;
  status: IGroupListOperationStatus;

  emptyState: React.ReactElement;

  batchLength?: number;

  onRetry(): void;
  onLoadMore?(): void;
}

export function GroupList(props: IGroupListProps) {
  const { groups, meta, status, batchLength = 5 } = props;

  const { t } = useTranslation();
  const styles = useStyles();
  const { isMobile } = useEnvironment();

  const hasMore = groups.length < (meta.total as number);

  const gap = isMobile ? undefined : styles.get(groupCardListTopBottomSpacing);

  if (status.fetch?.loading) {
    return (
      <List
        gap={gap}
        disablePadding
        separator={isMobile ? undefined : <Divider />}
      >
        {getPlaceholders(batchLength)}
      </List>
    );
  }

  if (status.fetch?.error) {
    return (
      <ErrorState
        onRetry={props.onRetry}
        variant="page-borderless"
        title={t('groups-web.error-state.title')}
        subtitle={t('groups-web.toast.error.groups.query')}
        actionLabel={t('groups-web.error-state.retry.label')}
      />
    );
  }

  if (!groups.length) {
    return props.emptyState;
  }

  return (
    <ErrorMonitorBoundary
      fallback={
        <ErrorState
          title={t('groups-web.error-state.title')}
          subtitle={t('groups-web.error-state.subtitle')}
          actionLabel={t('groups-web.error-state.retry.label')}
          variant="page-borderless"
        />
      }
    >
      <List
        gap={gap}
        disablePadding
        data-hook="groups-list"
        data-loading={status.fetchMore?.loading}
        separator={isMobile ? undefined : <Divider />}
      >
        <TransitionGroup component={null}>
          {groups.map((group) => (
            <Fade key={group.id}>
              <GroupListItem
                group={group}
                key={group.id}
                data-hook="group-list-item"
              />
            </Fade>
          ))}
        </TransitionGroup>
        <Show if={status.fetchMore?.loading}>
          {getPlaceholders(batchLength)}
        </Show>
      </List>

      <Show if={hasMore && !!props.onLoadMore}>
        <Box align="center">
          <Button outlined data-hook="load-more" onClick={props.onLoadMore}>
            {t('groups-web.showMore')}
          </Button>
        </Box>
      </Show>
    </ErrorMonitorBoundary>
  );
}

GroupList.displayName = 'GroupList';
