import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import Link from '../../../common/components/link/internal-link';
import RecentPostList from '../recent-posts-list';
import type { Props } from './types';
import styles from './recent-posts-desktop.scss';

const RecentPostsDesktop: React.FC<Props> = ({
  posts,
  title,
  showAllPostsLink,
}) => {
  const { t } = useTranslation();

  return posts.length === 0 ? null : (
    <div
      className={classNames(styles.container, 'blog-text-color')}
      data-hook="recent-posts"
    >
      {title && (
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          {showAllPostsLink && (
            <Link
              to="/"
              className={styles.link}
              data-hook="recent-posts-desktop__link"
            >
              {t('recent-posts.all-posts-link')}
            </Link>
          )}
        </div>
      )}
      <div className={title ? styles.list : ''}>
        <RecentPostList posts={posts} />
      </div>
    </div>
  );
};

export default RecentPostsDesktop;
