import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getMockedChallenge } from '../../../main/getMockedChallenges';
import { request } from '../../../../services/request';
import { listInstructors } from '@wix/ambassador-achievements-instructors-v2-instructor/http';
import { ListInstructorsResponse } from '@wix/ambassador-achievements-instructors-v2-instructor/types';

export default async function requestInstructorsList(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
): Promise<ListInstructorsResponse> {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (!slugOrChallengeId) {
    return null;
  } else if (mockedChallenge) {
    return { instructors: [] };
  }

  return (
    await request(
      flowAPI,
      listInstructors({
        programIdsFilter: [slugOrChallengeId],
      }),
    )
  )?.data;
}
