import React, { useMemo } from 'react';

import type {
  PluginContainerData_Alignment,
  PluginContainerData_Width_Type,
} from '@wix/ricos';
import { setupContentBuilder, generateKey } from '@wix/ricos';

import type { IGroupCoverChangedActivity } from 'api/feed/types';

import { RicosViewer } from 'common/components/Ricos/RicosViewer';

import { GROUPS_APP_DEFINITION_ID } from '../../../../../constants';

import { IActivityContentProps } from './types';

export function CoverImageChanged(props: IActivityContentProps) {
  const { item } = props;

  const data = item?.activity?.data as IGroupCoverChangedActivity;
  const groupId = item.applicationContext?.contextId as string;

  const content = useMemo(() => {
    const builder = setupContentBuilder(generateKey);

    return builder.addImage({
      content: { nodes: [] },
      data: {
        containerData: {
          alignment: 'CENTER' as PluginContainerData_Alignment,
          width: {
            size: 'FULL_WIDTH' as PluginContainerData_Width_Type,
          },
        },
        image: {
          width: 1920,
          height: 1080,
          src: { id: data.src },
        },
      },
    });
  }, [data.src]);

  return (
    <RicosViewer
      usage="FeedItem"
      content={content}
      groupId={groupId}
      postId={item.feedItemId ?? ''}
      containerId={GROUPS_APP_DEFINITION_ID}
    />
  );
}

CoverImageChanged.displayName = 'CoverImageChanged';
