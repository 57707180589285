import { useMemo } from 'react';
import { ensureRicosContent } from '@wix/ricos';

export function useRicosContent(details?: string, isRightAlignment?: boolean) {
  return useMemo(() => {
    return getRicosContent(details, isRightAlignment);
  }, [details, isRightAlignment]);
}

export const getRicosContent = (
  details?: string,
  isRightAlignment?: boolean,
) => {
  if (!details) {
    return null;
  }

  return ensureRicosContent(JSON.parse(details), {
    ignoreUnsupportedValues: true,
  });
};
