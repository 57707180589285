import {
  IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH,
  IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH,
  IS_MOBILE_DESIGN_SETTINGS_ENABLED_PATH,
  SECTION_POST_LIST,
  SECTION_RELATED_POSTS,
  getLayoutName,
  LAYOUT_SLIDER,
  type Section,
} from '@wix/communities-blog-client-common';

import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import { type AppState } from '../types';
import { getAppSettingsValue } from './app-settings-base-selectors';
import { getLayoutType } from './layout-selectors';
import { getSection } from './section-selectors';

export const getIsMobileSettingEnabled = (
  state: AppState,
  key: `style.booleans.${string}`,
) =>
  getAppSettingsValue({
    state,
    key,
    fallback: false,
  });

export const getIsMobileDisplaySettingsEnabled = (state: AppState) =>
  getIsMobileSettingEnabled(state, IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH);

export const getIsMobileLayoutSettingsEnabled = (state: AppState) =>
  getIsMobileSettingEnabled(state, IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH);

export const getIsMobileDesignSettingsEnabled = (state: AppState) =>
  getIsMobileSettingEnabled(state, IS_MOBILE_DESIGN_SETTINGS_ENABLED_PATH);

export const getShouldUsePostListMobileTitleFontSize = (state: AppState) => {
  const section = getSection(state);
  return (
    (section === SECTION_POST_LIST || section === SECTION_RELATED_POSTS) &&
    getIsMobile(state)
  );
};
export const getShouldUsePostListMobileDescriptionFontSize = (
  state: AppState,
) => {
  const section = getSection(state);
  return (
    (section === SECTION_POST_LIST || section === SECTION_RELATED_POSTS) &&
    getIsMobile(state)
  );
};
export const getPostListMobileLayoutNameForTitleFontColor = (
  state: AppState,
) => {
  const section = getSection(state);

  if (!getIsMobile(state)) {
    return null;
  }

  if (section === SECTION_POST_LIST || section === SECTION_RELATED_POSTS) {
    const layoutType = getLayoutType(state, section, true)!;
    return getLayoutName(layoutType);
  }

  return null;
};

export const getShouldUsePostListMobileSliderArrowColor = (state: AppState) => {
  return (
    getIsMobile(state) &&
    getIsMobileLayoutSettingsEnabled(state) &&
    (isMobileSliderLayout(state, SECTION_POST_LIST) ||
      isMobileSliderLayout(state, SECTION_RELATED_POSTS))
  );
};

export const getShouldUsePostListMobileSliderBorderStyles = (state: AppState) =>
  getIsMobile(state) &&
  getIsMobileDesignSettingsEnabled(state) &&
  (isMobileSliderLayout(state, SECTION_POST_LIST) ||
    isMobileSliderLayout(state, SECTION_RELATED_POSTS));

const isMobileSliderLayout = (state: AppState, section: Section) =>
  getLayoutType(state, section, true) === LAYOUT_SLIDER ||
  (!getLayoutType(state, section, true) &&
    getLayoutType(state, section) === LAYOUT_SLIDER);
