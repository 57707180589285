import React from 'react';
import { useTPAComponentsContext } from 'wix-ui-tpa';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { LocaleProvider } from '../hooks/useLocale';
import { ClientSideRenderMark } from './ClientSideRenderMark';

export const PagesContext: React.FC<{ children: React.ReactNode; locale?: string }> = ({ children, locale }) => {
  const tpaComponentsContext = useTPAComponentsContext();
  const { isRTL } = useEnvironment();

  return (
    <ClientSideRenderMark>
      <TPAComponentsProvider value={tpaComponentsContext}>
        <LocaleProvider locale={locale}>
          <div dir={isRTL ? 'rtl' : 'ltr'}>{children}</div>
        </LocaleProvider>
      </TPAComponentsProvider>
    </ClientSideRenderMark>
  );
};
