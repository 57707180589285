export  const parseDraftJS = (draftJSString: string) => {
  try {
    const parsed = JSON.parse(draftJSString);
    return parsed.blocks
      .map((block: { text: string }) => block.text)
      .join('\n');
  } catch (err) {
    console.log('Error parsing answer', err);
    return '';
  }
};
