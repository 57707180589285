import React from 'react';
import usePermissions from '@app/external/common/hooks/use-permissions';

const forPostWriter = (
  ComponentWriter: React.ElementType,
  ComponentUser: React.ElementType,
) => {
  return (props: any) => {
    const { can } = usePermissions();
    const Component = can('create', 'post') ? ComponentWriter : ComponentUser;
    return <Component {...props} />;
  };
};

export default forPostWriter;
