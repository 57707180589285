import { get } from 'lodash';
import {
  DESKTOP_LAYOUT_TYPES_TO_MOBILE,
  getLayoutTypePath,
  isLayoutPGOneColumn,
  isLayoutPGSideBySide,
  IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH,
  type LayoutType,
  LAYOUT_PG_GRID,
  type MixedLayoutType,
  type MobileLayoutType,
  SECTION_POST_LIST,
} from '@wix/communities-blog-client-common';
import { getAppSettings } from '../../common/selectors/app-settings-base-selectors';
import { getDefaultLayout } from '../../common/selectors/layout-selectors';
import { getIsMobileSettingEnabled } from '../../common/selectors/mobile-settings-selectors';
import { type AppState } from '../../common/types';

export const getPostListLayoutType = ({
  state,
  isMobile,
}: {
  state: AppState;
  isMobile?: boolean;
}): MixedLayoutType => {
  const isMobileLayoutSettingsEnabled = getIsMobileSettingEnabled(
    state,
    IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH,
  );
  const mobileLayoutType = isMobileLayoutSettingsEnabled
    ? getLayoutTypeParamForMobile(state)
    : undefined;
  const desktopLayoutType =
    getLayoutTypeParamForDesktop(state) ?? getDefaultLayout(state);

  if (isMobile && desktopLayoutType && !mobileLayoutType) {
    return DESKTOP_LAYOUT_TYPES_TO_MOBILE[
      desktopLayoutType as keyof typeof DESKTOP_LAYOUT_TYPES_TO_MOBILE
    ];
  }

  let layoutType = (isMobile && mobileLayoutType) || desktopLayoutType;

  if (
    isMobile &&
    (isLayoutPGSideBySide(layoutType) || isLayoutPGOneColumn(layoutType))
  ) {
    layoutType = LAYOUT_PG_GRID;
  }

  return layoutType;
};

const getLayoutTypeParamForDesktop = (state: AppState): LayoutType => {
  return get(
    getAppSettings(state),
    getLayoutTypePath(SECTION_POST_LIST, false),
  ) as LayoutType;
};

const getLayoutTypeParamForMobile = (state: AppState): MobileLayoutType => {
  return get(
    getAppSettings(state),
    getLayoutTypePath(SECTION_POST_LIST, true),
  ) as MobileLayoutType;
};
