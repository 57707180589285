import {
  routerDataServiceFactory,
  type IMembersAreaWidgetPluginService,
} from '@wix/members-area-widget-plugin-lib/viewer';
import { createAction } from '@wix/communities-blog-client-common';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import getEnvironment from '../../../common/services/get-environment';
import { type MyPostsThunkAction } from '../../types';

export const setViewedUser = createAction('viewedUser/SET');

export const fetchViewedUser =
  (widgetPluginService: IMembersAreaWidgetPluginService): MyPostsThunkAction =>
  async (dispatch, getState, { platformApi }) => {
    const { isPreview, isEditor } = getEnvironment(platformApi);

    const memberIds = await getMemberIds(platformApi, widgetPluginService);
    const userId =
      isEditor || isPreview
        ? memberIds.currentMemberId
        : memberIds.viewedMemberId;
    dispatch(setViewedUser(userId));
  };

const getMemberIds = async (
  platformApi: PlatformApi,
  widgetPluginService: IMembersAreaWidgetPluginService,
) => {
  const { window, user } = platformApi;
  const routerDataService = routerDataServiceFactory(
    widgetPluginService,
    user,
    window,
  );

  const routeData = await routerDataService.getRouteData();

  const currentMemberId = routeData?.currentMemberIdentifier?.id ?? null;
  const viewedMemberId = routeData?.viewedMemberIdentifier?.id ?? null;

  return { currentMemberId, viewedMemberId };
};
