import {
  getLastPage,
  PaginationType,
  type Section,
} from '@wix/communities-blog-client-common';
import { useSelector } from '../../common/components/runtime-context';

import {
  getCurrentPage,
  getEntityCount,
  getLastPageIndex,
  getPaginationPageSize,
  getPaginationType,
} from '../../common/selectors/pagination-selectors';

export default function usePaginationSettings(
  section: Section,
  pageProp?: string,
) {
  return useSelector((state) => {
    const paginationType = getPaginationType(state, section);
    const showPagination = paginationType === PaginationType.MultiplePages;

    const page = showPagination
      ? Number(pageProp ?? getCurrentPage(state, 'posts'))
      : getLastPageIndex(state, 'posts');

    const pageSize = getPaginationPageSize(state, section);
    const entityCount = getEntityCount(state, 'posts');
    const lastPage = getLastPage(entityCount, pageSize);
    return {
      paginationType,
      page,
      pageSize,
      lastPage,
      isValidPage: page === 1 || (page > 1 && page <= lastPage),
      entityCount,
      showPagination,
    };
  });
}
