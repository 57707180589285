import React, { FC, useCallback, useState } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import HeaderWrapper from './Components/HeaderWrapper/HeaderWrapper';
import TabsWrapper from './Components/Tabs/TabsWrapper';
import { AppContext } from './Components/SettingsProvider/SettingsProvider';
import settingsParams from '../settingsParams';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import { st, classes } from './Widget.st.css';
import QuestionsWrapper from './Components/QuestionsWrapper/QuestionsWrapper';
import SearchWrapper from './Components/SearchWrapper/SearchWrapper';
import { CategoriesPosition } from '../../../enums';
import constants from '../../../constants';
import { AccordionCSS } from './Components/QuestionsWrapper/Interfaces';

const Widget: FC<WidgetProps> = (props) => {
  const instance = props.instance;
  const baseURL = props.baseURL;
  const questionId = props.questionId;
  const settings: ISettingsContextValue = useSettings();
  const textAlignment = settings.get(settingsParams.textAlignment);
  const isRTL = textAlignment === 'right';
  const { vertical, horizontal } = settings.get(settingsParams.spacing);
  const customStyle = {
    '--item-top-bottom-padding': `${vertical}px`,
    '--item-left-right-padding': `${horizontal}px`,
  } as AccordionCSS;
  const categoriesPosition: CategoriesPosition = settings.get(
    settingsParams.categoriesPosition,
  );
  const { DISPLAY_CATEGORIES, DISPLAY_CONTENT } = constants;
  const sideCategoriesPosition = categoriesPosition === DISPLAY_CATEGORIES.SIDE;
  const topCategoriesPosition = categoriesPosition === DISPLAY_CATEGORIES.TOP;
  const showCategories =
    settings.get(settingsParams.displayContent) ===
    DISPLAY_CONTENT.SPLIT_INTO_CATEGORIES;
  const [selectedCategory, setSelectedCategory] = useState<
    (string | null | undefined)[] | undefined | string | null
  >(null);
  const [searchTerm, setSearchTerm] = React.useState('');
  const handleSearchChange = useCallback((term: string) => {
    setSearchTerm(term);
  }, []);
  const { show: showSearch } = settings.get(settingsParams.searchBar);
  const [firstQuestionId, setFirstQuestionId] = useState<string>();

  return (
    <AppContext.Provider
      value={{
        instance,
        baseURL,
        selectedCategory,
        setSelectedCategory,
        firstQuestionId,
        setFirstQuestionId,
        questionId,
      }}
    >
      <TPAComponentsProvider value={{ rtl: isRTL }}>
        <div
          data-hook="faq-root"
          dir={isRTL ? 'rtl' : 'ltr'}
          style={customStyle}
          className={st(classes.container, {
            isSideCategoriesPosition: sideCategoriesPosition,
            isTopCategoriesPosition: topCategoriesPosition,
            hideSearch: !showSearch,
            hideCategories: !showCategories,
          })}
        >
          <HeaderWrapper />
          <TabsWrapper searchTerm={searchTerm} />
          <QuestionsWrapper />
          <SearchWrapper onSearchChange={handleSearchChange} />
        </div>
      </TPAComponentsProvider>
    </AppContext.Provider>
  );
};

export default Widget;
