import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getMockedChallenge } from '../../../main/getMockedChallenges';
import { request } from '../../../../services/request';
import { listSections } from '@wix/ambassador-challenges-v1-challenge/http';
import { DescriptionFieldSet } from '@wix/ambassador-challenges-v1-challenge/types';
import { Section } from '../../../../types/v3Types';
import { isV3enabled } from '../../../../experiments/isV3enabled';
import {
  getSection,
  listSections as listSectionsV3,
} from '@wix/ambassador-online-programs-v3-section/http';
import { type GetSectionRequest } from '@wix/ambassador-online-programs-v3-section/types';

export default async function requestChallengeSections(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
  limit?: number,
): Promise<Section[]> {
  if (limit === 0) {
    return [];
  }
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (!slugOrChallengeId) {
    return null;
  } else if (mockedChallenge) {
    return [];
  }
  if (isV3enabled(flowAPI)) {
    const sectionsResponse = await request(
      flowAPI,
      listSectionsV3({
        programId: slugOrChallengeId,
      }),
    );
    return sectionsResponse?.data?.sections;
  }
  return (
    await request(
      flowAPI,
      listSections({
        challengeId: slugOrChallengeId,
        descriptionFieldSet: DescriptionFieldSet.STANDARD,
      }),
    )
  )?.data?.sections;
}

export async function requestSectionV3(
  { sectionId }: GetSectionRequest,
  flowAPI: ControllerFlowAPI,
) {
  return (
    await request(
      flowAPI,
      getSection({
        sectionId,
        descriptionFieldSet: DescriptionFieldSet.EXTENDED,
      }),
    )
  )?.data?.section;
}
