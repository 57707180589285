import React from 'react';

import {
  Image,
  ImageProps,
  ImageHoverEffectOptions,
  ImageLoadingBehaviorOptions,
  ImageResizeOptions,
} from 'wix-ui-tpa/cssVars';

interface IProps
  extends Omit<ImageProps, 'resize' | 'hoverEffect' | 'loadingBehavior'> {
  skipMeasure?: boolean;
  resize?: keyof typeof ImageResizeOptions;
  hoverEffect?: keyof typeof ImageHoverEffectOptions;
  loadingBehavior?: keyof typeof ImageLoadingBehaviorOptions;
}

export function Media(props: IProps) {
  const { resize, hoverEffect, loadingBehavior, ...rest } = props;

  return (
    <Image
      resize={resize as ImageResizeOptions}
      hoverEffect={hoverEffect as ImageHoverEffectOptions}
      loadingBehavior={loadingBehavior as ImageLoadingBehaviorOptions}
      {...rest}
    />
  );
}

Media.displayName = 'wui/Media';

export type { ImageFocalPoint } from 'wix-ui-tpa/cssVars';
