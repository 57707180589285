import {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  createAsyncThunk as reduxCreateAsyncThunk,
} from '@reduxjs/toolkit';

import { HttpClient } from '@wix/yoshi-flow-editor';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';

import { monitor } from 'controller/error-monitor';

import type {
  ITokenPayload,
  AsyncThunkConfig,
  I18NextTransProps,
} from './types';

type ThunkOptions<Arg> = AsyncThunkOptions<Arg, AsyncThunkConfig> & {
  hideErrorMessage?: boolean | ((error: unknown) => boolean);

  formatError?(arg: Arg): I18NextTransProps;
};

type ReduxAsyncThunkConfig = {
  pendingMeta?: unknown;
  serializedErrorType?: unknown;
};

export function createAsyncThunk<
  Returned,
  Arg = void,
  Config extends ReduxAsyncThunkConfig = {},
>(
  prefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, Arg, AsyncThunkConfig>,
  options: ThunkOptions<Arg> = {},
) {
  const create = reduxCreateAsyncThunk.withTypes<AsyncThunkConfig>();

  return create(
    prefix,
    async function (arg, thunkApi) {
      const { dispatch, extra: flowApi } = thunkApi;

      const errorMonitor = monitor(flowApi);

      const {
        hideErrorMessage = false,
        formatError = defaultErrorFormatter(prefix),
      } = options;

      try {
        const result = await payloadCreator(arg, thunkApi);

        return result;
      } catch (error) {
        const hideToast =
          typeof hideErrorMessage === 'function'
            ? hideErrorMessage(error)
            : hideErrorMessage;

        if (!hideToast) {
          dispatch({
            type: 'application/showToast',
            payload: {
              type: 'error',
              action: prefix,
              i18nParams: formatError(arg),
              requestId: HttpClient.isHttpError(error)
                ? error.requestId
                : undefined,
              description: HttpClient.isHttpError(error)
                ? error.message
                : undefined,
            },
          });
        }

        errorMonitor.captureException(error as Error, {
          transaction: prefix,
          tags: { thunk: true },
        });

        if (HttpClient.isHttpError(error)) {
          console.error(prefix, error.requestId, error.response?.data);
        } else {
          console.error(prefix, error);
        }

        throw error;
      }
    },
    options,
  ) as AsyncThunk<Returned, Arg, Config & AsyncThunkConfig>;
}

export function defaultErrorFormatter(name: string) {
  const key = name.replace(/:/g, '.');

  return () => ({
    i18nKey: `groups-web.toast.error.${key}`,
  });
}

export function is404(error: unknown) {
  if (!HttpClient.isHttpError(error)) {
    return false;
  }

  return error?.response?.status === 404;
}

export function getCurrentUser(user: IUser, instance?: string) {
  if (!instance) {
    return user;
  }

  const payload = parseJWT(instance);

  return {
    ...user,
    instance,
    id: user.id || payload.siteMemberId || payload.uid,
    loggedIn: user.loggedIn || !!payload.siteMemberId || !!payload.uid,
  };
}

export function serializeHttpError(error: unknown) {
  if (!HttpClient.isHttpError(error)) {
    return error;
  }

  return error.response?.data;
}

function parseJWT(jwt: string): ITokenPayload {
  try {
    const [, payload] = jwt.split('.');
    return JSON.parse(atob(payload));
  } catch {
    return {};
  }
}
