import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { bindRouter } from '@wix/tpa-router/plugins';

import {
  initializeRouter,
  registerApplicationRoutes,
  reloadOnLogin,
} from 'controller/router';
import { resolvables } from 'controller/resolvables';
import { initializeStore, persistStore } from 'controller/store';
import { migrateStyles } from 'controller/tpa-styles-migration';
import { bindViewModel, initializeViewModel } from 'controller/view-model';
import { bindIntegrations } from 'controller/integrations';
import { addLogger } from 'controller/logger';
import { initializePlatformApi } from 'controller/viewer-platform';
import { defer } from 'common/utils';

import { resolve } from './resolve';
import { configureRouter } from './config';

export const controller: CreateControllerFn = function (params) {
  const { flowAPI } = params;

  const ready = defer();
  const router = initializeRouter(params);

  flowAPI.bi?.updateDefaults({ userEntry: 'site' });

  return {
    async pageReady() {
      const store = initializeStore(params);
      const vm = initializeViewModel(router, store, params);

      addLogger(router, params);
      configureRouter(router);
      reloadOnLogin(router, params);
      registerApplicationRoutes(router, vm, store, params);

      migrateStyles(params);
      bindViewModel(vm, store, params);
      bindRouter(router, params.flowAPI);

      await resolvables(params, resolve(router, params));
      await bindIntegrations(vm);

      persistStore(store, params);

      ready.resolve(initializePlatformApi(router, store, vm));
    },
    async onBeforeUnLoad() {
      router.dispose();
    },
    exports() {
      return {
        ready: ready.promise,
      };
    },
  };
};
