import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { planPurchaseClick } from '@wix/bi-logger-pricing-plans-data/v2';
import { PlanWidgetRole } from '@wix/pricing-plans-common/blocks';
import { getPricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import { getSetupFeeAmount, hasSetupFee } from '@wix/pricing-plans-utils';
import { getCoverImage, CoverImage } from '@wix/pricing-plans-utils/client-data';
import { TFunction } from '@wix/yoshi-flow-editor';
import { FormattedPriceData, getFormattedPriceData } from '../../hooks';
import { Analytics } from '../../services/analytics';
import { getFreeTrialDaysLabel, getPeriodLabel, getPlanDuration, resolveLocale } from '../../utils';
import { getFormattedAdditionalFees } from '../../utils/get-formatted-additional-fees';
import { PricingData, PricingElementOptions } from '../Pricing/viewer.controller';
import model from './model';

export type CtaClickHandler = (plan: PublicPlan) => void;

export type PlanElementOptions = PricingElementOptions & {
  forceExpandFreeTrial?: boolean;
  defaultCoverImage?: CoverImage;
};

export default model.createController(({ $w, flowAPI }) => {
  let externalOnSelectHandler: CtaClickHandler | undefined;

  const updatePlanData = (plan: PublicPlan, elementOptions?: PlanElementOptions) => {
    const t = flowAPI.translations.t as TFunction;
    const coverImage = getCoverImage(plan.clientData);
    $w(`#${PlanWidgetRole.Image}`).src = getImageSrc(coverImage ?? elementOptions?.defaultCoverImage);
    // Setting alt text to empty string to make image invisible to screen readers
    $w(`#${PlanWidgetRole.Image}`).alt = '';
    $w(`#${PlanWidgetRole.Image}`).expand();

    setDescription(plan);

    const freeTrial = getFreeTrialDaysLabel(plan, t);
    const duration = getPlanDuration(plan, t);
    let planDuration = freeTrial ? `${duration}\n${freeTrial}` : duration;
    if (!freeTrial && elementOptions?.forceExpandFreeTrial) {
      planDuration += '\n\n';
    }

    $w(`#${PlanWidgetRole.PlanName}`).text = plan.name!;
    $w(`#${PlanWidgetRole.PlanDuration}`).text = planDuration;

    const { price, currency, fullPrice } = getPriceData(plan);
    const paymentFrequency = getPeriodLabel(plan.pricing?.subscription, t) ?? '';
    const setupFee = getSetupFee(plan);
    $w(`#${PlanWidgetRole.PricingWidget}`).setPricing(
      {
        price,
        currency,
        paymentFrequency,
        setupFee,
      } satisfies PricingData,
      elementOptions,
    );
    setA11yAttributes(plan, fullPrice);

    // setPerks always needs to be called, to make benefits repeater dynamic
    // viewer currently doesn't support static repeater inside dynamic repeater serialization https://wix.slack.com/archives/C9N00LLE6/p1689155061640139
    $w(`#${PlanWidgetRole.PerksWidget}`).setPerks(plan.perks?.values ?? []);

    if (plan.perks?.values?.length) {
      $w(`#${PlanWidgetRole.ContentDividerContainer}`).show();
      $w(`#${PlanWidgetRole.PerksWidget}`).expand();
    } else {
      $w(`#${PlanWidgetRole.ContentDividerContainer}`).hide();
      $w(`#${PlanWidgetRole.PerksWidget}`).collapse();
    }
  };

  const setDescription = (plan: PublicPlan) => {
    if (plan.description) {
      $w(`#${PlanWidgetRole.Description}`).text = plan.description;
      $w(`#${PlanWidgetRole.Description}`).expand();
    } else {
      $w(`#${PlanWidgetRole.Description}`).text = '';
      $w(`#${PlanWidgetRole.Description}`).collapse();
    }
  };

  const setA11yAttributes = (plan: PublicPlan, formattedPrice: string) => {
    // @ts-expect-error
    $w(`#${PlanWidgetRole.PlanContainer}`).accessibility.ariaAttributes.label = plan.name;

    if ($w(`#${PlanWidgetRole.RibbonWidget}`).isVisible) {
      const ribbonTextElement = $w(`#${PlanWidgetRole.RibbonWidget}`).getTextElement();
      // @ts-expect-error
      $w(`#${PlanWidgetRole.PlanContainer}`).accessibility.ariaAttributes.describedBy = ribbonTextElement;
    }

    if ($w(`#${PlanWidgetRole.PricingWidget}`).isVisible && !flowAPI.environment.isEditor) {
      // @ts-expect-error
      $w(`#${PlanWidgetRole.PlanName}`).accessibility.screenReader.suffix = formattedPrice;
    }
  };

  const getPriceData = (plan: PublicPlan): FormattedPriceData => {
    const { value, currency } = plan.pricing?.price!;
    return getFormattedPriceData({
      createCurrencyFormatter: flowAPI.getCurrencyFormatter,
      locale: resolveLocale(flowAPI.controllerConfig.wixCodeApi),
      value,
      currency,
      hideTrailingFractionZeroes: true,
    });
  };

  const getSetupFee = (plan: PublicPlan): string => {
    return getFormattedAdditionalFees({
      plan,
      locale: resolveLocale(flowAPI.controllerConfig.wixCodeApi),
      createCurrencyFormatter: flowAPI.getCurrencyFormatter,
      hideTrailingFractionZeroes: true,
    })
      .map(({ amount, name }) => `+${amount} ${name}`)
      .join('\n');
  };

  const reportPerformanceMark = (callback: (params: any) => void) => {
    if (typeof performance !== 'undefined') {
      performance.mark && setTimeout(() => performance.mark('pricing-plans-rendered'), 1000);
    }
    return callback;
  };

  const registerCtaHandler = async (plan: PublicPlan, widgetType: string) => {
    const api = await getPricingPlansPublicAPI(flowAPI.controllerConfig.wixCodeApi);
    $w(`#${PlanWidgetRole.Button}`).onClick(
      reportPerformanceMark(() => {
        if (typeof externalOnSelectHandler === 'function') {
          return externalOnSelectHandler(plan);
        }

        const analytics = new Analytics(flowAPI.controllerConfig.wixCodeApi.window);
        analytics.clickProduct(plan);
        flowAPI?.bi?.report(
          planPurchaseClick({
            planGuid: plan.id ?? '',
            widgetType,
            setupFeeAmount: hasSetupFee(plan) ? Math.round(Number(getSetupFeeAmount(plan)) * 100) : undefined,
          }),
        );

        if (flowAPI.controllerConfig.wixCodeApi.window.viewMode !== 'Site') {
          return flowAPI.openModal('AlertModal', { width: 504, height: 364 });
        }

        api.navigateToCheckout({ planId: plan.id! });
      }),
    );
  };

  return {
    pageReady() {
      if (!flowAPI.environment.isEditor) {
        /*
          Collapse image on initializing, so default image
          doesn't show up before image from plan data is set
        */
        $w(`#${PlanWidgetRole.Image}`).collapse();
      }
    },
    exports: {
      setPlan: async (plan: PublicPlan, elementOptions?: PlanElementOptions) => {
        updatePlanData(plan, elementOptions);
      },
      onSelect: (cb: CtaClickHandler) => {
        externalOnSelectHandler = cb;
      },
      registerCtaHandler: async (plan: PublicPlan, widgetType: string) => {
        registerCtaHandler(plan, widgetType);
      },
    },
  };
});

const DEFAULT_IMAGE_SRC =
  'wix:image://v1/11062b_916e6674cff64736acbf49ae13678e99~mv2.jpeg/Purple%20Podiums.jpeg#originWidth=6000&originHeight=2725';

function getImageSrc(coverImage?: CoverImage): string {
  if (coverImage) {
    const { uri, width, height } = coverImage;
    return `wix:image://v1/${uri}/${uri}#originWidth=${width}&originHeight=${height}`;
  }

  return DEFAULT_IMAGE_SRC;
}
