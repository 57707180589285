import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Image } from '../image/image';
import styles from './post-list-item-image.scss';

type Props = {
  image: object;
  url?: string;
  isPublic?: boolean;
  height?: number;
  width?: number;
};

export const PostListItemImageDesktop: React.FC<Props> = ({
  image,
  url,
  isPublic = false,
  height,
  width,
}) => {
  const socialAttrs = useMemo(
    () => (isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true }),
    [isPublic, url],
  );

  return (
    <div
      className={classNames(styles.container)}
      data-hook="post-list-item-image"
    >
      <Image
        width={width}
        height={height}
        socialAttrs={socialAttrs}
        className={styles.imageFit}
        image={image}
      />
    </div>
  );
};

export default PostListItemImageDesktop;
