import React from 'react';

import { StepsListAsTiles } from '../StepsListAsTiles';

import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { SectionsAsTilesAccordion } from '../../../../../components-shared/SectionsAsTilesAccordion';
import { isStepResolved } from '../../views/utils';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import {
  getSectionDetails,
  getSectionTitle,
  isSectionLocked,
  sectionCannotBeOpenedInView,
} from '../../../../../selectors/sections';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import LockedIcon from '../../../../../assets/icons/locked-fill.svg';
import { useUser } from '../../../../../contexts/User/UserContext';
import { memberWebAppButtonClick } from '@wix/bi-logger-challenges-member-web/v2';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa/cssVars';
import { ParticipantSection } from '../../../../../types/v3Types';
import { getRicosContent } from '../../../../../components-shared/Ricos/UseRicosContent';
import { isRichContentEmpty, toPlainText } from '@wix/ricos';
import { useChallengeData } from '../../../../../contexts/storage-contexts/Challenge';
import { useV3 } from '../../../../../experiments/useV3';

import { classes } from './SectionsListAsTiles.st.css';

export interface ISectionsListAsTilesProps {
  dataHook?: string;
  dataHookForStepsList?: string;
  currentStepId: string;
  onSectionChosen?: Function;
  chosenSectionTileId?: string;
  onStepChosen(step: ParticipantStep): void;
}

export const SectionsListAsTiles: React.FunctionComponent<
  ISectionsListAsTilesProps
> = (props: ISectionsListAsTilesProps) => {
  const { onSectionChosen, onStepChosen, chosenSectionTileId } = props;
  const [pendingSectionId, setPendingSectionId] = React.useState(null);
  const bi = useBi();
  const settings = useSettings();
  const { t } = useTranslation();
  const isV3 = useV3();
  const { experiments } = useExperiments();
  const { isMobile } = useEnvironment();
  const { challengeData } = useChallengeData();
  const {
    participant,
    isParticipantInSuspendedState,
    isParticipantInLockedState,
  } = useUser();
  const { changeSectionsWithoutDescriptionEnabled } = useSidebarLayoutBase();
  const {
    isListParticipantSectionsRequestInProgress,
    listParticipantSections = [],
    requestParticipantSection,
    requestParticipantSectionStatus,
  } = useSections();

  React.useEffect(() => {
    if (requestParticipantSectionStatus === 'SUCCESS' && pendingSectionId) {
      const section = listParticipantSections.find(
        (s) => s.id === pendingSectionId,
      );

      if (section && !isMobile) {
        void openSection(section, false);
      }
      setPendingSectionId(null);
    }
  }, [isMobile, requestParticipantSectionStatus]);

  const handleSectionClick = React.useCallback(
    (section: ParticipantSection, isLocked: boolean = false) => {
      const details = section?.source && getSectionDetails(section?.source);
      const isDelayedDetails = details === undefined; // only `undefined` is suitable here

      void bi.report(
        memberWebAppButtonClick({
          buttonName: 'toggleSectionDropdown',
          sectionId: section.id,
        }),
      );
      setPendingSectionId(section?.id);

      if (sectionCannotBeOpenedInView(section)) {
        return;
      }

      if (isDelayedDetails && !isLocked) {
        void requestParticipantSection?.(section?.id, listParticipantSections);
      } else if (!isMobile) {
        void openSection(section, isLocked);
      }
    },
    [isMobile, listParticipantSections],
  );

  const openSection = React.useCallback(
    async (section: ParticipantSection, isLocked: boolean = false) => {
      const details = section?.source && getSectionDetails(section?.source);
      const textContent = details
        ? (await toPlainText(getRicosContent(details, false))).trim()
        : '';
      const isContentExists =
        details &&
        !isRichContentEmpty(getRicosContent(details, false)) &&
        textContent; // pretty weird check, need to remove it after a/b test and migration

      if (isContentExists || isLocked) {
        void bi.report(
          memberWebAppButtonClick({
            buttonName: 'openSectionInView',
            sectionId: section.id,
          }),
        );

        onSectionChosen?.(section.id);
      } else {
        changeSectionsWithoutDescriptionEnabled(true);
        if (section?.steps?.[0]) {
          onStepChosen?.(section.steps[0]);
        }
      }
    },
    [],
  );

  if (isListParticipantSectionsRequestInProgress) {
    return null;
  }

  return (
    <div data-hook={props.dataHook || null}>
      <ul className={classes.sectionsList}>
        {listParticipantSections.map((section, ind: number, arr) => {
          const steps = section.steps;
          const key = `section-${section.id}`;
          const sectionSteps = steps || [];
          const resolvedLength = sectionSteps.filter((step) =>
            isStepResolved(step),
          ).length;
          const isOpenSection = !!steps.find(
            (step) => step?.id === props.currentStepId,
          );

          const isActiveSection = chosenSectionTileId === section.id;
          const isLocked = isSectionLocked(section);

          const subtitle = (
            <>
              {isLocked ? <LockedIcon /> : null}
              {isParticipantInSuspendedState || isParticipantInLockedState
                ? t('live.challenges-page.section-steps-subtitle-short', {
                    total: sectionSteps.length,
                  })
                : t('live.challenges-page.section-steps-subtitle', {
                    resolved: resolvedLength,
                    total: sectionSteps.length,
                  })}
            </>
          );
          const details = section?.source && getSectionDetails(section?.source);

          return (
            <li key={`section-${ind}`} className={classes.sectionsListItem}>
              <SectionsAsTilesAccordion
                id={key}
                key={key}
                lastItem={arr.length - 1 === ind}
                className={isActiveSection ? classes.sectionActive : ''}
                data-hook={
                  props.dataHook ? `${props.dataHook}__accordion-button` : null
                }
                opened={isOpenSection || false}
                title={getSectionTitle(section.source)}
                subtitle={subtitle}
                emptyChildren={!!steps.length}
                align={settings.get(
                  challengeSettings.sidebarLayoutTextAlignment,
                )}
                onClick={() => {
                  handleSectionClick(section, isLocked);
                }}
                isEmpty={!steps?.length}
              >
                {sectionCannotBeOpenedInView(section) ||
                !isMobile ||
                !details ? null : (
                  <TextButton
                    className={classes.viewSectionDetails}
                    priority={TextButtonPriority.primary}
                    onClick={() => {
                      openSection(section, isLocked);
                    }}
                  >
                    {t('challenge-card.view-button')}
                  </TextButton>
                )}
                {!isLocked ? (
                  <StepsListAsTiles
                    dataHook={props.dataHookForStepsList}
                    isSection={true}
                    currentStepId={props.currentStepId}
                    steps={sectionSteps}
                    isSPC={true}
                    onStepChosen={onStepChosen}
                  />
                ) : null}
              </SectionsAsTilesAccordion>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
SectionsListAsTiles.displayName = 'SectionsListAsTiles';
