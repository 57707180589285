import { useExperiments } from '@wix/yoshi-flow-editor';

export const useV3 = () => {
  const { experiments } = useExperiments();
  return experiments.enabled('specs.programs.OOINileMigration');
};

export const isV3Enabled = (experiments: any) => {
  return experiments.enabled('specs.programs.OOINileMigration');
};
