import { UrlMappingsKeys } from '@wix/url-mapper-utils';
import {
  createAction,
  CUSTOM_ROUTE_PLACEHOLDERS,
} from '@wix/communities-blog-client-common';
import { type CommonThunkAction } from '@app/types/common-thunk-action.type';

export const SET_CUSTOM_ROUTES = 'customRoutes/SET';
const setCustomRoutesAction = createAction(SET_CUSTOM_ROUTES);

export const setCustomRoutes =
  (): CommonThunkAction<void> =>
  async (dispatch, getState, { platformApi }) => {
    const routes = {
      [UrlMappingsKeys.BLOG_POST]: { slug: CUSTOM_ROUTE_PLACEHOLDERS.postSlug },
      [UrlMappingsKeys.BLOG_POST_EDIT]: {
        slug: CUSTOM_ROUTE_PLACEHOLDERS.postId,
      },
    };

    const customRoutes = Object.fromEntries(
      await Promise.all(
        Object.entries(routes).map(async ([key, route]) => {
          const url =
            (await platformApi.location.buildCustomizedUrl?.(key, route, {})) ??
            '';

          return [key, url] as const;
        }),
      ),
    );

    dispatch(setCustomRoutesAction(customRoutes));
  };
