import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { biBeforeAndAfter, biChangePage } from '../../../services/biHelpers';
import { ActionTypes, ScreenNames } from '../../main/biInterfaces';
import { request } from '../../../services/request';
import { monitoringPropsMap } from '../../Monitoring/MonitoringPropsMap';
import { FedopsInteraction } from '../../../config/fedopsInteraction';
import { deleteParticipant } from '@wix/ambassador-challenges-v1-participant/http';

export const leaveProgram = async (
  flowAPI: ControllerFlowAPI,
  participantId: string,
  challengeId: string,
) => {
  await biBeforeAndAfter(
    flowAPI.bi,
    ActionTypes.LEAVE_THE_CHALLENGE,
    async (actionId) => {
      return (
        await request(
          flowAPI,
          deleteParticipant({
            actionId,
            challengeId,
            participantId,
          }),
        )
      )?.data;
    },
  );

  await biChangePage(flowAPI.bi, ScreenNames.ChallengePage);
  const { endInteraction } = monitoringPropsMap(flowAPI);
  endInteraction(FedopsInteraction.LeaveFromProgram, challengeId);
};
