import type { Transition, UIRouter } from '@wix/tpa-router';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { IGroup } from 'api/groups/types';

export function defaultTab(router: UIRouter, params: ControllerParams) {
  const { isSSR } = params.flowAPI.environment;

  if (isSSR) {
    router.transitionService.onEnter({ to: 'group' }, hook);
  } else {
    router.transitionService.onFinish({ to: 'group' }, hook);
  }

  async function hook(transition: Transition) {
    const injector = transition.injector();
    const group: IGroup = await injector.getAsync('group');

    const target = group.permissions?.feed?.canViewPosts
      ? 'group.discussion.feed'
      : 'group.about';

    return transition
      .targetState()
      .withState(target)
      .withOptions({
        location: 'replace',
        custom: { retain: true },
      });
  }
}
