import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import Link from '../../../common/components/link/internal-link';
import { PostFooter } from '../../../common/components/post-footer';
import { HorizontalSeparator } from '../../../common/components/separator';
import withIsFeedDesignEnabled from '../../../common/hoc/with-is-feed-design-enabled';
import RecentPostListItemCover from '../recent-post-list-item-cover';
import styles from './recent-posts-list-item.scss';

const RecentPostsListItemWithCover = ({
  post,
  postLink,
  onLikeClick,
  titleFontClassName,
  contentFontClassName,
  isMetadataFooterVisible,
  applyFeedDesign,
  getPostClassName,
  imageMetadata,
  videoMetadata,
}) => {
  const titleContainerClassName = classNames(
    !applyFeedDesign && titleFontClassName,
    getPostClassName('title-font'),
  );
  const titleClassName = classNames(
    styles.titleWrapper,
    'blog-post-title-color',
  );
  const containerClassName = classNames(
    styles.containerWithCover,
    !isMetadataFooterVisible && styles.withoutFooter,
    !applyFeedDesign && contentFontClassName,
    getPostClassName('description-font'),
  );

  const renderTitle = (
    <div className={titleContainerClassName} data-hook="recent-post__title">
      <p className={titleClassName}>
        <Link
          className={classNames(styles.title, styles.titleClamp)}
          to={postLink}
          fullRoute={post.link}
        >
          {post.title}
        </Link>
      </p>
    </div>
  );

  const renderFooter = (
    <div className={styles.footerWrapper}>
      <HorizontalSeparator className={styles.separator} />
      <div className={styles.footer}>
        <PostFooter post={post} onLikeClick={onLikeClick} displayIcons={true} />
      </div>
    </div>
  );

  return (
    <div className={containerClassName}>
      <RecentPostListItemCover
        post={post}
        postLink={postLink}
        imageMetadata={imageMetadata}
        videoMetadata={videoMetadata}
      />
      <div className={classNames(styles.main, 'with-cover')}>
        {renderTitle}
        {isMetadataFooterVisible && renderFooter}
      </div>
    </div>
  );
};

RecentPostsListItemWithCover.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  getPostClassName: PropTypes.func,
  applyFeedDesign: PropTypes.bool.isRequired,
  isMetadataFooterVisible: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  postLink: PropTypes.string.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  imageMetadata: PropTypes.object,
  videoMetadata: PropTypes.object,
};

export default flowRight(withIsFeedDesignEnabled)(RecentPostsListItemWithCover);
