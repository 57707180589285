import React from 'react';

import { st, classes } from './Modal.st.css';
import { ButtonNames } from '../../contexts/main/biInterfaces';
import { useEnvironment, useBi } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { FCWithChildren } from '@wix/challenges-web-library';

import { Dialog } from 'wix-ui-tpa';

export interface IModalProps {
  opened?: boolean;
  onClose(): void;
  className?: string;
  contentClassName?: string;
  sticky?: boolean;
  closeAriaLabel?: string;
  isRTL?: boolean;
  dataHook?: string;
  dialogClassName?: string;
}

export const Modal: FCWithChildren<IModalProps> = (props) => {
  const { isMobile } = useEnvironment();
  const { className, sticky = false, contentClassName } = props;

  const bi = useBi();

  const handleClose = () => {
    bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.CloseModalButton,
      }),
    );

    props.onClose();
  };

  const modalContent = (
    <div data-hook={props.dataHook}>
      <section
        className={st(
          classes.root,
          {
            mobile: isMobile,
            sticky,
            bottomRelated: isMobile,
            isRTL: props.isRTL,
          },
          className,
        )}
      >
        <div className={`${classes.content} ${contentClassName || ''}`}>
          {props.children}
        </div>
      </section>
    </div>
  );

  return (
    <Dialog
      isOpen={props.opened}
      onClose={handleClose}
      className={props.dialogClassName}
    >
      {modalContent}
    </Dialog>
  );
};
