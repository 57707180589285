import { get } from 'lodash';
import type { PlatformApi } from '../controller/platform-api';

const isProduction = process.env.NODE_ENV === 'production';

const getIsDebug = (platformApi: PlatformApi) => {
  const { debug, ssrDebug } = get(
    platformApi,
    'location.query',
    {} as Record<string, string>,
  );

  return (!!debug && debug !== 'false') || (!!ssrDebug && ssrDebug !== 'false');
};

export default function getEnvironment(platformApi: PlatformApi) {
  const isEditor = platformApi.window.viewMode === 'Editor';
  const isPreview = platformApi.window.viewMode === 'Preview';

  return {
    isEditor,
    isPreview,
    isEditorSegment: isEditor || isPreview,
    isSite: platformApi.window.viewMode === 'Site',
    isSSR: platformApi.window.rendering.env === 'backend',
    isDebug: getIsDebug(platformApi),
    isProduction,
  };
}
