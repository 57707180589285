import React from 'react';
import {
  ComponentsContext,
  type ComponentsContextValue,
} from './components-context';

const Fallback: React.FC = () => null;

const ComponentsProvider: React.FC<
  React.PropsWithChildren<ComponentsContextValue>
> = ({
  children,
  PostListProGallery = Fallback as any,
  PostListSimple = Fallback as any,
  PostListMasonry = Fallback as any,
  PostListMobile = Fallback as any,
  PostListItem = Fallback as any,
  Post = Fallback as any,
  SideBySideMobile = Fallback as any,
  TextOnImageMobile = Fallback as any,
}) => (
  <ComponentsContext.Provider
    value={{
      PostListProGallery,
      PostListSimple,
      PostListMasonry,
      PostListMobile,
      PostListItem,
      Post,
      SideBySideMobile,
      TextOnImageMobile,
    }}
  >
    {React.Children.only(children)}
  </ComponentsContext.Provider>
);

export default ComponentsProvider;
