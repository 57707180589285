import React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';
import AppLoaded from '@app/external/common/components/app-loaded';
import EmptyStates from '@app/external/common/components/empty-states';
import {
  FullHeightLayout,
  SimpleLayout,
} from '@app/external/common/components/layout';
import Page from '@app/external/common/components/page';
import forDevice from '@app/external/common/hoc/for-device';
import styles from './no-post-found.scss';

const Layout = forDevice(FullHeightLayout, SimpleLayout);

const NoPostFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page noSpacing>
      <Layout>
        <EmptyStates
          title={t('no-post-found.title')}
          content={t('no-post-found.message')}
          className={styles.container}
          titleClassName={styles.title}
        />
      </Layout>
      <AppLoaded />
    </Page>
  );
};

export default NoPostFound;
