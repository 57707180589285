import React, { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';

import { classes } from './StepsListAsTiles.st.css';

import { StepSidebar } from './StepSidebar';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { FCWithChildren } from '@wix/challenges-web-library';
import type { IParticipantSectionsContext } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { getFormattedDate } from './GetFormattedDate';

export interface IScheduledStepsList {
  steps: IParticipantSectionsContext['participantSteps']['steps'];
  isSPC: boolean;
  currentStepId: string;
  onStepChosen(step: ParticipantStep): void;
}

export const ScheduledStepsList: FCWithChildren<IScheduledStepsList> = (
  props,
) => {
  const { language } = useEnvironment();
  const stepsByStartDate = useMemo(
    () =>
      groupBy(props.steps, (step) => {
        return step.dateFrame.start;
      }),
    [props.steps],
  );

  return (
    <ul className={classes.stepsList}>
      {Object.keys(stepsByStartDate).map((startDate, parentId: number) => {
        const formattedStepDate = getFormattedDate(language, startDate);

        return (
          <>
            <li className={`${classes.stepsTitle}`} key={`title-${parentId}`}>
              {formattedStepDate}
            </li>

            {stepsByStartDate[startDate].map((step, ind: number) => {
              return (
                <StepSidebar
                  key={`step-${parentId}-${ind}`}
                  step={step}
                  isSPC={props.isSPC}
                  currentStepId={props.currentStepId}
                  onStepChosen={props.onStepChosen}
                />
              );
            })}
          </>
        );
      })}
    </ul>
  );
};
