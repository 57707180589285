import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward as LoyaltyReward } from '@wix/ambassador-loyalty-v1-reward/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyRewards(flowAPI: PlatformControllerFlowAPI): Promise<LoyaltyReward[]> {
  const { httpClient } = flowAPI;

  const response = await httpClient.request(
    listRewards({
      redemptionContext: true,
    }),
  );

  return response.data.rewards!;
}
