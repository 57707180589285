import { ActionTypes } from '../../main/biInterfaces';
import { Status } from '@wix/ambassador-challenges-v1-approval-request/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { biBeforeAndAfter } from '../../../services/biHelpers';
import {
  listApprovalRequests,
  resolveApprovalRequest,
} from '@wix/ambassador-challenges-v1-approval-request/http';
import { request } from '../../../services/request';

async function getApprovalRequestId(
  flowAPI: ControllerFlowAPI,
  challengeId: string,
) {
  try {
    const approvals = (
      await request(
        flowAPI,
        listApprovalRequests({
          challengeId,
          creatorId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
          includeResolved: false,
        }),
      )
    )?.data;

    return approvals?.requests?.['0']?.id;
  } catch (error) {
    handleError({ error, context: 'cancelInvite.listApprovalRequests' });
  }
}

export async function cancelInvite(
  flowAPI: ControllerFlowAPI,
  challengeId: string,
) {
  const approvalRequestId = await getApprovalRequestId(flowAPI, challengeId);

  if (approvalRequestId) {
    await biBeforeAndAfter(
      flowAPI.bi,
      ActionTypes.CHALLENGE_JOIN,
      async (actionId) => {
        return (
          await request(
            flowAPI,
            resolveApprovalRequest({
              actionId,
              requestId: approvalRequestId,
              resolutionStatus: Status.CANCELLED,
            }),
          )
        )?.data;
      },
    );
  } else {
    console.error("[Challenges]: Can't find approval request id.", {
      flowAPI,
    });
    return;
  }
}
