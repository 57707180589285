import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { Experiments, IHttpClient } from '@wix/yoshi-flow-editor';

type IsOrderProvisionedResponse = {
  isProvisioned: boolean;
  hasGroup: boolean;
};

export class BenefitPrograms {
  constructor(private httpClient: IHttpClient, private experiments: Experiments) {}

  public isInPopulation() {
    return this.experiments.enabled(TPA_EXPERIMENTS.BENEFIT_PROGRAMS_ENABLED);
  }

  public async isOrderProvisioned(orderId: string) {
    const response = await this.httpClient.get<IsOrderProvisionedResponse>(
      `/_serverless/pricing-plans-bpp/is-order-provisioned/${orderId}`,
    );
    const { isProvisioned, hasGroup } = response.data;
    return isProvisioned && hasGroup;
  }
}
