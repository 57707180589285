import React from 'react';

import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import {
  Text,
  TextTypography,
  ButtonSize,
  Spinner,
  SpinnerTypes,
} from 'wix-ui-tpa/cssVars';
import { StepUndo, useStepIsUnavailable } from '../StepControls';
import { ActionBlockerAppearance } from '../../../../../components-shared/ActionBlocker/ActionBlocker';
import { PreviewActionBlockerContainer } from '../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';
import { SidebarLayoutButton } from '../Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { useQuiz } from '../../../../../contexts/Quiz/QuizContext';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { useSettings } from '@wix/tpa-settings/react';
import { useResolveStep } from '../../../../../contexts/ResolveStep/ResolveStepContext';
import utils, {
  ChallengeEntityType,
  IFollowingEntity,
  isStepResolved,
  useNextEntity,
} from '../../views/utils';
import { memberWebAppButtonClick } from '@wix/bi-logger-challenges-member-web/v2';
import { ButtonNames } from '../../../../../contexts/main/biInterfaces';
import { sidebarLayoutDataHooks } from '../../views/SidebarLayout/sidebarLayoutDataHooks';

import { SidebarControlsContainer } from '../SidebarControlsContainer/SidebarControlsContainer';
import { StepInTheFutureGuard } from '../StepInTheFutureGuard/StepInTheFutureGuard';
import { FCWithChildren } from '@wix/challenges-web-library';
import { StepsNavigation } from '../StepControls/components/StepsNavigation';

import { st, classes } from './FormQuizControls.st.css';
import { Challenges } from '../../../../../editor/types/Experiments';
import {
  FocusPoint,
  useFocusManager,
} from '../../../../../contexts/FocusManager/useFocusManager';

export interface IFormQuizProps {
  formId: string;
  completeBtnDataHook?: string;
  isDisabled?: boolean;
  onNextEntity(nextEntity: IFollowingEntity): Promise<void>;
}

export const FormQuizControls: FCWithChildren<IFormQuizProps> = ({
  // formId,
  // completeBtnDataHook,
  isDisabled,
  onNextEntity,
}) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const { experiments } = useExperiments();
  const settings = useSettings();
  const alignment = utils.getContentAlignByType(settings, isRTL);
  const {
    controllerIsReady,
    submission,
    sendSubmissionInProgress,
    earnedGrade,
    resubmitTheQuiz,
  } = useQuiz();
  const { currentStep } = useSidebarLayoutBase();
  const { nextEntity } = useNextEntity(currentStep?.id);
  const { isResolveStepRequestInProgress } = useResolveStep();
  const bi = useBi();
  const isStepUndoEnabled = experiments.enabled(Challenges.enableUndoButton);

  const { formQuizRef } = useSidebarLayoutBase();

  const isFilled = !!submission;
  const passingGrade = utils.getPassingGrade(currentStep, isFilled);
  const hasPassingGrade = !!passingGrade;
  const isQuizPassed = !hasPassingGrade || earnedGrade >= passingGrade;
  const isCompleted = isStepResolved(currentStep) && isFilled && isQuizPassed;

  const isUnresolved = !isStepResolved(currentStep);
  const isResubmitAvailable = isFilled && hasPassingGrade && !isQuizPassed;
  const showNextBtn =
    isCompleted &&
    !!nextEntity &&
    nextEntity?.type !== ChallengeEntityType.VOID;
  const isUnavailable = useStepIsUnavailable();
  const { setNextFocusPoint } = useFocusManager();
  const submit = React.useCallback(() => {
    setNextFocusPoint(FocusPoint.Bottom);
    if (formQuizRef?.current) {
      formQuizRef.current?.validate().then(
        (isValid) => {
          if (isValid) {
            void bi.report(
              memberWebAppButtonClick({
                buttonName: ButtonNames.QuizSubmit,
              }),
            );
            void formQuizRef.current?.submitTheForm();
          }
        },
        (err) => {
          console.error("Can't submit the form.", err);
        },
      );
    } else {
      console.error("Can't find the form component.");
    }
  }, [formQuizRef?.current?.validate, formQuizRef?.current?.submitTheForm]);

  const resubmit = React.useCallback(() => {
    void bi.report(
      memberWebAppButtonClick({
        buttonName: ButtonNames.QuizResubmit,
      }),
    );

    resubmitTheQuiz();
  }, []);

  const BaseCTAButton = (
    <PreviewActionBlockerContainer
      appearance={ActionBlockerAppearance.PopoverAppearance}
      content={t('action-blocker.preview-mode.complete-step')}
    >
      {sendSubmissionInProgress || isResolveStepRequestInProgress ? (
        <Spinner type={SpinnerTypes.slim} diameter={40} />
      ) : (
        <SidebarLayoutButton
          className={classes.completeBtn}
          dataHook={sidebarLayoutDataHooks.quizSubmitBtn({
            resubmit: isResubmitAvailable,
          })}
          disabled={!!isDisabled || sendSubmissionInProgress}
          fullWidth={isMobile}
          size={isMobile ? ButtonSize.small : ButtonSize.medium}
          onClick={isResubmitAvailable ? resubmit : submit}
        >
          {isResubmitAvailable
            ? t('live-site.step-quiz.retake-btn')
            : t('live-site.step-quiz.complete-btn')}
        </SidebarLayoutButton>
      )}
    </PreviewActionBlockerContainer>
  );

  const NextButton = (
    <SidebarLayoutButton
      fullWidth={isMobile}
      size={isMobile ? ButtonSize.small : ButtonSize.medium}
      onClick={async () => {
        void onNextEntity(nextEntity);
      }}
    >
      {t('live-site.step-quiz.next-btn')}
    </SidebarLayoutButton>
  );

  // eslint-disable-next-line no-nested-ternary
  const msg = isFilled
    ? isQuizPassed
      ? t('live-site.step-quiz.completed-msg')
      : t('live-site.step-quiz.failed-msg')
    : hasPassingGrade
    ? t('live-site.step-quiz.completed-msg-with-score', {
        score: passingGrade,
      })
    : null;
  const msgLine =
    msg && !isResolveStepRequestInProgress ? (
      <Text
        tagName="p"
        typography={TextTypography.runningText}
        className={classes.message}
      >
        {msg}
      </Text>
    ) : null;

  const showBaseButton = (isUnresolved || isResubmitAvailable) && !isUnavailable && controllerIsReady;
  const showNextButton = showNextBtn && !isStepUndoEnabled && !isUnavailable && controllerIsReady;
  return (
    <SidebarControlsContainer>
      <div
        className={st(classes.root, {
          mobile: isMobile,
          alignment,
        })}
      >
        <StepInTheFutureGuard>
          {showBaseButton ? BaseCTAButton : null}
          {showNextButton ? NextButton : null}
          <StepUndo>{t('live-site.step-quiz.undo')}</StepUndo>
          {msgLine}
          <StepsNavigation
            stepId={currentStep?.id}
            onChangeEntity={onNextEntity}
          />
        </StepInTheFutureGuard>
      </div>
    </SidebarControlsContainer>
  );
};
