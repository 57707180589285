import React from 'react';
import { useSelector } from 'react-redux';
import {
  useEnvironment,
  useTranslation,
  useBi,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { getFormattedLocation } from '@wix/wix-events-commons-statics';

import { groupsListCtaButtonClick } from '@wix/bi-logger-groups-data/v2';

import { IExtendedEvent } from 'api/events/types';

import { selectIsSiteAdmin } from 'store/selectors';

import { Box } from 'wui/Box';
import { Card } from 'wui/Card';
import { Button } from 'wui/Button';
import { Tooltip } from 'wui/Tooltip';
import { Typography } from 'wui/Typography';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { Show } from 'wui/Show';
import { CardMedia, CardMediaPlaceholder } from 'wui/CardMedia';

import { EventActions } from './EventActions';

import classes from './Event.scss';

interface IProps {
  event: IExtendedEvent;
  groupId: string;
}

export function Event(props: IProps) {
  const { event } = props;
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const bi = useBi();

  const isSiteAdmin = useSelector(selectIsSiteAdmin);

  const url = event.eventPageUrl
    ? `${event.eventPageUrl.base}${event.eventPageUrl.path}`
    : undefined;

  const canUpdate =
    props.event.permissions?.canUpdate &&
    experiments.enabled('specs.groups.events-by-uou');

  return (
    <Card
      gap="SP0"
      padding={isMobile ? '0 SP5' : 'SP0'}
      sideBorders={false}
      topBottomBorders={false}
      borders={
        isMobile ? [false, false, true, false] : [true, true, true, true]
      }
      direction={isMobile ? 'vertical' : 'horizontal'}
      className={classes.root}
      data-hook="event"
    >
      <Box flex={false} width={isMobile ? '100%' : '70%'} height={240}>
        {event.mainImage ? (
          <CardMedia
            fluid
            stretchImage
            isInFirstFold
            resize="cover"
            src={event.mainImage.id as string}
            alt={event.mainImage.altText as string}
            sourceHeight={event.mainImage.height as number}
            sourceWidth={event.mainImage.width as number}
          />
        ) : (
          <CardMediaPlaceholder />
        )}
      </Box>
      <Box width="100%" padding="SP5 0" direction="vertical">
        <CardContent gap="SP2" height="100%" align="center">
          <Typography align="center" variant="h2-20">
            {props.event.title}
          </Typography>
          <Box direction="vertical" align="center">
            <Typography secondary variant="p2-14" align="center">
              {props.event.dateAndTimeSettings?.formatted?.dateAndTime}
            </Typography>
            <Typography secondary variant="p2-14" align="center">
              {getFormattedLocation(props.event)}
            </Typography>
          </Box>
          <Typography>{props.event.shortDescription}</Typography>
        </CardContent>
        <CardActions align="center">
          <Tooltip content={getTooltipContent()}>
            <Button
              disabled={!url}
              as="a"
              href={url}
              target="_blank"
              onClick={reportRSVPClick}
            >
              {t('groups-web.events.rsvp')}
            </Button>
          </Tooltip>
        </CardActions>
      </Box>
      <Show if={canUpdate}>
        <EventActions
          groupId={props.groupId}
          eventId={props.event.id || ''}
          eventSlug={props.event.slug || ''}
        />
      </Show>
    </Card>
  );

  function reportRSVPClick() {
    bi.report(
      groupsListCtaButtonClick({
        button_name: 'rsvp',
        page_name: 'events_livesite',
        group_id: props.groupId,
      }),
    );
  }

  function getTooltipContent() {
    if (url) {
      return undefined;
    }

    if (isSiteAdmin) {
      return t('groups-web.events.setup-warning.admin');
    }

    return t('groups-web.events.setup-warning');
  }
}

Event.displayName = 'Event';
