import React, { useEffect } from 'react';

interface ClientSideRenderMarkProps {
  children: React.ReactNode;
}

export const ClientSideRenderMark: React.FC<ClientSideRenderMarkProps> = ({ children }) => {
  useEffect(() => {
    if (typeof performance !== 'undefined') {
      performance.mark && performance.mark('pricing-plans-rendered');
    }
  }, []);

  return <>{children}</>;
};
