import type { UIRouter } from '@wix/tpa-router';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import { SECRET_SLUG } from 'api/groups/constants';

export function editorDefaults(router: UIRouter, params: ControllerParams) {
  const { isEditor, isPreview } = params.flowAPI.environment;

  if (isEditor || isPreview) {
    router.urlService.rules.initial({
      state: 'group.discussion.feed',
      params: { slug: SECRET_SLUG },
    });
  }
}
