import {
  getRelatedPosts,
  getRelatedPostsMetadata,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-related-post/http';
import {
  type GetRelatedPostsMetadataResponse,
  type GetRelatedPostsResponse,
  type Metrics,
  type RelatedPost,
  type RelatedPostMetadata,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-related-post/types';
import { relatedPostsWidgetStyleParams } from '@wix/communities-blog-client-common';
import { isEditorOrPreview } from '../../../viewer/selectors';
import setPosts from '../../common/actions/set-posts';
import {
  getLanguageCode,
  getQueryLocale,
} from '../../common/selectors/locale-selectors';
import {
  enhancePostsWithMetadata,
  normalizePosts,
} from '../../common/services/post-utils';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { fetchTranslationsSuccess } from '../../common/store/translations/translations-actions';
import { type AppState } from '../../common/types';
import { getRelatedPostCount } from '../selectors/related-posts-selectors';
import { type RelatedPostsThunkAction } from '../types';

export const RELATED_POSTS_ENTITY = 'related-posts';

export function fetchRelatedPostsRenderModel(
  postId: string | undefined,
): RelatedPostsThunkAction {
  return async (dispatch, getState, { platformApi, httpClient }) => {
    const state = getState();

    dispatch(setIsLoading(RELATED_POSTS_ENTITY, undefined, true));

    if (!postId) {
      dispatch(setPosts([]));
      dispatch(setIsLoading(RELATED_POSTS_ENTITY, undefined, false));
      return;
    }

    const locale = getQueryLocale(state);
    const languageCode = getLanguageCode(state);
    const pageSize = isEditorOrPreview(platformApi)
      ? relatedPostsWidgetStyleParams['post-list-postCount'].maxValue
      : getRelatedPostCount(state);

    try {
      const [rawPosts, metadata] = await Promise.all([
        httpClient.request(
          getRelatedPosts({
            postId,
            languageCode,
            locale,
            pageSize,
            translationsName: 'related-posts-widget',
            includeInitialPageData: true,
          }),
        ),
        httpClient.request(
          getRelatedPostsMetadata({ postId, languageCode, pageSize }),
        ),
      ]);

      const posts = transformResponse(state, rawPosts.data, metadata.data);

      dispatch(fetchTranslationsSuccess(rawPosts.data.translations));
      dispatch(setPosts(posts));
      dispatch(setIsLoading(RELATED_POSTS_ENTITY, undefined, false));
    } catch (err) {
      dispatch(setPosts([]));
      dispatch(setIsLoading(RELATED_POSTS_ENTITY, undefined, false));
      return;
    }
  };
}

function transformResponse(
  state: AppState,
  postsResponse: GetRelatedPostsResponse,
  metadataResponse: GetRelatedPostsMetadataResponse,
) {
  const posts = enhancePostsWithMetadata(
    postsResponse.relatedPosts ?? [],
    mapMetadataByPostId(metadataResponse.relatedPostsMetadata ?? []),
  );

  const postsWithCoverMedia = applyCoverMedia(posts);

  const normalizedPosts = normalizePosts({
    state,
    posts: postsWithCoverMedia as any,
    blogCategoryIds: [],
    origin: '/v3/posts',
  });

  return normalizedPosts;
}

function applyCoverMedia(posts: RelatedPost[]) {
  return posts.map((_post) => ({
    ..._post,
    coverMedia: { ..._post.media, ..._post.media?.wixMedia },
  }));
}

function mapMetadataByPostId(metadata: RelatedPostMetadata[]) {
  return metadata.reduce<Record<string, Metrics>>((result, { id, metrics }) => {
    if (id && metrics) {
      result[id] = metrics;
    }
    return result;
  }, {});
}
