import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Paper } from '../Paper';
import { Typography } from '../Typography';

import classes from './EmptyState.scss';

export interface IEmptyStateProps
  extends Omit<React.ComponentProps<typeof Paper>, 'title' | 'content'> {
  wired?: boolean;
  centered?: boolean;
  variant?: 'page' | 'section' | 'page-borderless';

  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  action?: React.ReactElement;
  image?: React.ReactElement;
}

export function EmptyState(props: IEmptyStateProps) {
  const {
    wired,
    className,
    centered = true,
    variant = 'page',
    action,
    title: _,
    subtitle: __,
    image,
    ...rest
  } = props;

  const { isMobile } = useEnvironment();

  let title: React.ReactElement;
  let subtitle: React.ReactElement;

  const isSection = variant === 'section';
  const isBorderless = variant === 'page-borderless';
  const isPage = variant === 'page' || variant === 'page-borderless';
  const isLarge = isPage && !isMobile;
  const isBordered = !isBorderless && isPage;

  if (props.title && (props.title as React.ReactElement).type !== Typography) {
    title = (
      <Typography variant={isLarge ? 'h2-24' : 'h2-20'}>
        {props.title}
      </Typography>
    );
  } else {
    title = props.title as React.ReactElement;
  }

  if (
    props.subtitle &&
    (props.subtitle as React.ReactElement).type !== Typography
  ) {
    subtitle = <Typography variant="p2-16">{props.subtitle}</Typography>;
  } else {
    subtitle = props.subtitle as React.ReactElement;
  }

  return (
    <Paper
      shadow={isBordered}
      topBottomBorders={isBordered}
      sideBorders={isBordered && !isMobile}
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
        [classes.centered]: centered,
        [classes.page]: isPage,
        [classes.section]: isSection,
        [classes.transparent]: !isBordered,
        [classes.wired]: wired,
      })}
      {...rest}
    >
      {image && (
        <div data-hook="image">
          {React.cloneElement(image, {
            className: cls(image.props.className, classes.image),
          })}
        </div>
      )}
      {title && (
        <div data-hook="title">
          {React.cloneElement(title, {
            ...title.props,
            className: cls(title.props.className, classes.title),
          })}
        </div>
      )}

      {subtitle && (
        <div data-hook="subtitle">
          {React.cloneElement(subtitle, {
            ...subtitle.props,
            className: cls(subtitle.props.className, classes.subtitle),
          })}
        </div>
      )}

      {action && (
        <div data-hook="action">
          {React.cloneElement(action, {
            wired,
            ...action.props,
            className: cls(action.props.className, classes.action),
          })}
        </div>
      )}
    </Paper>
  );
}

EmptyState.displayName = 'wui/EmptyState';
