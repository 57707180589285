import { resolveProgramId } from './resolveProgramId';
import { requestChallengeSections, requestChallengeSteps } from './api';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Section, Step } from '../../../types/v3Types';
import { isV3enabled } from '../../../experiments/isV3enabled';
import { getSectionsWithSteps } from '../../../selectors/getSectionsWithSteps';
import { IChallengeContext } from './interfaces';
import { convertScheduledStepsToSections } from './convertScheduledStepsToSections';

export const preloadProgramStepData = async (
  flowAPI: ControllerFlowAPI,
  preloadStepLimit: number,
): Promise<Pick<IChallengeContext, 'challengeSteps' | 'challengeSections'>> => {
  const { programId } = await resolveProgramId(flowAPI);
  const [preloadedSections, preloadedSteps] = await Promise.all([
    requestChallengeSections(programId, flowAPI, preloadStepLimit),
    requestChallengeSteps(programId, flowAPI, preloadStepLimit),
  ]);
  const { challengeSections, challengeSteps } = combineSectionsWithSteps({
    flowAPI,
    sections: preloadedSections,
    steps: preloadedSteps,
  });

  if (challengeSections?.length) {
    return {
      challengeSections,
      challengeSteps,
    };
  }

  return {
    challengeSections: await convertScheduledStepsToSections(
      flowAPI,
      challengeSteps,
    ),
    challengeSteps,
  };
};

interface CombineSectionsWithStepsParams {
  flowAPI: ControllerFlowAPI;
  sections: Section[];
  steps: Step[];
}

function combineSectionsWithSteps({
  flowAPI,
  sections,
  steps,
}: CombineSectionsWithStepsParams) {
  const v3StepAndSections = isV3enabled(flowAPI)
    ? getSectionsWithSteps(sections, steps)
    : null;
  return {
    challengeSections: v3StepAndSections?.sections
      ? v3StepAndSections?.sections
      : sections,
    challengeSteps: v3StepAndSections?.steps ? v3StepAndSections?.steps : steps,
  };
}
