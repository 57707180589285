import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward as LoyaltyReward } from '@wix/ambassador-loyalty-v1-reward/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyRewards(flowAPI: ControllerFlowAPI): Promise<LoyaltyReward[]> {
  const { httpClient } = flowAPI;
  const response = await httpClient.request(listRewards({}));

  return response.data.rewards!;
}
