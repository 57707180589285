import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  requestChallengeSections,
  requestChallengeSteps,
} from '../storage-contexts/Challenge';
import { requestResolvedSteps } from '../storage-contexts/Challenge/api/requestResolvedSteps';
import { getSectionsWithSteps } from '../../selectors/getSectionsWithSteps';
import { toParticipantSectionsV3 } from '../../selectors/toParticipantSectionsV3';
import type { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { DateFrame } from '../../types/v3Types';
import { isUserJoinedAlready } from '../User/helpers/userTypeHandlers';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { getStartDate, getState } from '../../selectors/participant';
import { isRequiredOwnerData } from './helpers';
import { assignSectionDateTitleForScheduledProgram } from '../../selectors/assignSectionDateTitleForScheduledProgram';
import { isSelfPaced } from '../../selectors/isSelfPaced';
import {getRightDateFromBackend} from "../../selectors/dates";

export async function getParticipantSectionsV3(
  challenge: Challenge,
  flowAPI: ControllerFlowAPI,
) {
  const challengeId = challenge.id;
  const userProvider = await userProviderPropsMap(flowAPI);
  const { participantV3, participant } = userProvider;
  const participantOrV3 = participantV3 || participant;
  const shouldRequest =
    (participantOrV3 && isUserJoinedAlready(getState(participantOrV3))) ||
    isRequiredOwnerData(flowAPI);
  if (!shouldRequest) {
    return { sections: [], steps: [] };
  }
  flowAPI.controllerConfig?.setProps({
    isListParticipantSectionsRequestInProgress: true,
  });
  const dateFrame: DateFrame = {
    start: participantOrV3
      ? getStartDate(participantOrV3)
      : new Date().toISOString(),
  };
  const [sectionsV3, stepsV3, resolvedSteps] = await Promise.all([
    requestChallengeSections(challengeId, flowAPI),
    requestChallengeSteps(challengeId, flowAPI),
    requestResolvedSteps(participantOrV3?.id, flowAPI),
  ]);
  const sectionsWithScheduledTitle = sectionsV3.map((section) =>
    assignSectionDateTitleForScheduledProgram({
      section,
      isScheduled: !isSelfPaced(challenge),
      participantStartDay: getRightDateFromBackend(dateFrame.start),
      language: flowAPI.environment.language,
    }),
  );
  const { sections } = getSectionsWithSteps(
    sectionsWithScheduledTitle,
    stepsV3,
  );
  return toParticipantSectionsV3({
    sections,
    challenge,
    resolvedSteps,
    dateFrame,
  });
}
