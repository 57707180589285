import React, { type ReactNode } from 'react';
import classNames from 'classnames';
import {
  getInternalLinkRoute,
  SECTION_BLOG_PAGE,
  SECTION_BLOG_POST_PAGE,
} from '@wix/communities-blog-client-common';
import { getPathWithoutSectionUrl } from '../../services/get-path-without-section-url';
import { isAppRoute } from '../../services/section-route-utils';
import { isSite as getIsSite } from '../../store/basic-params/basic-params-selectors';
import { getTopology } from '../../store/topology/topology-selectors';
import { useActions, useSelector } from '../runtime-context';
import styles from './internal-link.scss';

type Props = {
  to?: string;
  className?: string;
  children?: ReactNode;
  addHoverClasses?: boolean;
  sectionId?: string;
  /**
   * Should not change between SSR/CSR. Will produce hydration errors otherwise
   *
   * If "button", will navigate without a full page reload
   **/
  as?: 'button' | 'a';
  isActive?: boolean;
  fullRoute?: string;
  onNavigation?: () => void;
};

const InternalLink = ({
  addHoverClasses = true,
  children,
  className,
  sectionId: section,
  to,
  fullRoute,
  as = 'a',
  isActive,
  onNavigation,
}: Props) => {
  const isSite = useSelector(getIsSite);
  const topology = useSelector(getTopology);
  const actions = useActions();

  const shouldNavigateToFeed = section === SECTION_BLOG_PAGE || isAppRoute(to);
  const sectionId = shouldNavigateToFeed
    ? SECTION_BLOG_PAGE
    : SECTION_BLOG_POST_PAGE;
  const route =
    fullRoute ||
    getInternalLinkRoute({
      topology,
      to,
      shouldNavigateToFeed,
    });
  const linkClassName = classNames(
    addHoverClasses && 'blog-link-hover-color',
    className,
  );

  if (as === 'button') {
    return (
      <button
        aria-current={isActive ? 'page' : undefined}
        onClick={() => {
          actions.navigateWithinBlog(
            getPathWithoutSectionUrl({
              route,
              topology,
              shouldNavigateToFeed,
            }),
            {
              disableScrollToTop: true,
            },
          );

          onNavigation?.();
        }}
        className={classNames(linkClassName, styles.noScrollButton)}
      >
        {children}
      </button>
    );
  }

  return (
    <a
      aria-current={isActive ? 'page' : undefined}
      href={route}
      onClick={(event) => {
        if (!isSite) {
          event.preventDefault();
          actions.navigateInPreview(route, sectionId);
        }

        onNavigation?.();
      }}
      className={linkClassName}
      data-hook="link"
    >
      {children}
    </a>
  );
};

export default InternalLink;
