import { get } from 'lodash';
import { createAction } from '@wix/communities-blog-client-common';
import { type CommonThunkAction } from '@app/types/common-thunk-action.type';

export const USER_JOIN_COMMUNITY_SUCCESS = 'USER_JOIN_COMMUNITY_SUCCESS';
const userJoinCommunitySuccess = createAction(USER_JOIN_COMMUNITY_SUCCESS);

const POPUP_URL = `https://apps.wix.com/_api/members-area/public/modal/join-community`;

export const showJoinCommunityPopup =
  (): CommonThunkAction<void> =>
  async (dispatch, getState, { platformApi, compId }) => {
    const onClose = ({
      message,
    }: {
      message?: { payload?: { inCommunity?: boolean } };
    }) => {
      const hasJoined = get(message, 'payload.inCommunity', false);

      if (hasJoined) {
        dispatch(userJoinCommunitySuccess());
      }
    };

    platformApi.window
      .openPersistentPopup(
        POPUP_URL,
        {
          theme: 'BARE',
          width: '100%',
          height: '100%',
          position: {
            origin: 'FIXED',
            placement: 'CENTER',
            x: 0,
            y: 0,
          },
        },
        compId,
      )
      .then(onClose)
      .catch(onClose);
  };
