import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { configToSlugMap, type PlanListPublicData } from '@wix/pricing-plans-router-utils';
import { createEventHandler } from '@wix/tpa-settings';
import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { IEvents, SETTINGS_EVENTS, TabState } from '../../../constants/settings-events';
import { plansFixtureTranslated } from '../../../fixtures';
import {
  createOrdersApi,
  createPlansApi,
  createPremiumApi,
  createBenefitsApi,
  createGroupsApi,
  memoizePlansApi,
} from '../../../services';
import { Analytics } from '../../../services/analytics';
import { BenefitPrograms } from '../../../services/benefit-programs';
import { commonBIDataFromFlowAPI } from '../../../services/commonBIData';
import { createPaymentMethodsApi } from '../../../services/payment-methods';
import { createSettingsApi } from '../../../services/settings';
import { WarmupData } from '../../../services/WarmupData';
import { AppProps, noModal } from '../../../types/common';
import { resolveLocale } from '../../../utils';
import { isMpaOnEcom, isMultiPageApp } from '../../../utils/isMultiPageApp';
import { getUserData } from '../../../utils/user';
import { SettingsReader } from '../DefaultSettingsAdapter';
import settingsParams from '../settingsParams';
import { App } from './AppController';
import { CheckoutController } from './CheckoutController';
import { ListController } from './ListController';
import { Navigation } from './Navigation';
import { RestrictedController } from './RestrictedController';
import { Router } from './Router';
import { StatusController } from './StatusController';

const createController: CreateControllerFn = async (params: ControllerParams) => {
  const {
    flowAPI,
    controllerConfig: { compId, setProps: _setProps, wixCodeApi, appParams, config },
  } = params;
  const { instance, appDefinitionId, instanceId } = appParams;
  const defaultDemoPlans = plansFixtureTranslated(flowAPI.translations.t);

  const demoPlans = config.publicData?.APP?.demoData?.plans ?? defaultDemoPlans;

  const plansApi = createPlansApi(flowAPI.httpClient);
  const premiumApi = createPremiumApi(flowAPI.httpClient);
  const ordersApi = createOrdersApi(flowAPI.httpClient);
  const benefitsApi = createBenefitsApi(flowAPI.httpClient);
  const groupsApi = createGroupsApi(flowAPI.httpClient);
  const settingsApi = createSettingsApi(flowAPI.httpClient);
  const paymentMethodsApi = createPaymentMethodsApi(flowAPI.httpClient, instanceId);
  const benefitProgramsApi = new BenefitPrograms(flowAPI.httpClient, flowAPI.experiments);

  const componentEventHandler = createEventHandler<IEvents>(config.publicData.COMPONENT || {});

  const noop = () => {};
  const { metaSiteId, ownerId, visitorId } = flowAPI.controllerConfig.platformAPIs.bi ?? {};

  const isMPA = await isMultiPageApp(wixCodeApi, appParams);
  const isOnEcom = await isMpaOnEcom(wixCodeApi);

  const initialProps: AppProps = {
    purchaseLimitExceeded: false,
    appInstanceId: instanceId,
    metaSiteId,
    siteOwnerId: ownerId,
    visitorId,
    modal: noModal,
    hideToast: noop,
    showToast: noop,
    instance,
    loginOnCheckout: noop,
    logout: noop,
    navigateToStatus: noop,
    navigateFromStatusPage: noop,
    plans: [],
    selectPlan: noop,
    selectedPlan: {},
    signupOnCheckout: noop,
    subPage: { name: 'list', integrationData: {} },
    tabState: TabState.REGULAR,
    areSettingsOpened: false,
    user: getUserData(wixCodeApi.user.currentUser),
    locale: resolveLocale(wixCodeApi),
    missingPlan: false,
    navigateToHomePage: noop,
    navigateToPlanList: noop,
    switchAccounts: noop,
    benefits: [],
    trackInitiateCheckout: noop,
    trackSelectPayment: noop,
    demoBuyNowClicked: noop,
    prices: [],
    navigateBackToCheckout: noop,
    biCheckoutStage: noop,
    biPlanPurchased: noop,
    biThankYouPageCtaButtonClick: noop,
    biThankYouPageOnLoad: noop,
    biUpgradeReferralClick: noop,
    updatePriceDetails: noop,
    updatePriceDetailsError: undefined,
    couponInputMode: 'trigger',
    couponCode: '',
    couponLoading: false,
    removeCoupon: noop,
    onBeforeStartPayment: noop,
    onBeforeStartPaymentStatus: undefined,
    updateStartDateError: undefined,
    applyCouponError: undefined,
    hasCoupons: false,
    onGetFreePlan: noop,
    onGetFullyDiscountedPlan: noop,
    zeroPricePlanCheckoutStatus: undefined,
    isCheckoutDataInitialized: false,
    onContinueAsGuest: noop,
    onEditGuestEmail: noop,
    guestCheckoutEnabled: false,
    skipAdditionalInfoStep: true,
    fitToContentHeight: true,
    integrationData: {},
    isMultiPageApp: isMPA,
    multilingualCountryCode: undefined,
    widgetLoaded: noop,
  };

  // For some reason when running integration tests it is not possible to set part of props as missing properties gets
  // reverted to initial set values.
  function setProps<T>(x: T) {
    return _setProps(Object.assign(initialProps, x));
  }

  const warmupData = new WarmupData(compId, wixCodeApi, flowAPI);
  flowAPI.bi?.updateDefaults(commonBIDataFromFlowAPI(flowAPI));
  const nav = new Navigation(flowAPI, appDefinitionId, isMPA);
  const analytics = new Analytics(wixCodeApi.window);
  const router = new Router(setProps, nav, analytics, wixCodeApi, flowAPI);
  const list = new ListController(
    setProps,
    wixCodeApi,
    flowAPI,
    router,
    memoizePlansApi(plansApi),
    ordersApi,
    premiumApi,
    analytics,
    new SettingsReader(flowAPI.settings, settingsParams),
    demoPlans,
    warmupData,
    isMPA,
    isOnEcom,
  );
  const checkout = new CheckoutController(
    setProps,
    wixCodeApi,
    router,
    flowAPI,
    plansApi,
    ordersApi,
    benefitsApi,
    premiumApi,
    paymentMethodsApi,
    analytics,
    settingsApi,
    warmupData,
  );
  const status = new StatusController(setProps, wixCodeApi, flowAPI, router, plansApi, warmupData, benefitProgramsApi);
  const restricted = new RestrictedController(setProps, wixCodeApi, router, groupsApi, flowAPI, warmupData);
  const app = new App(setProps, list, checkout, status, restricted, router, flowAPI);

  router.whenInit({ list: async ({ biOptions }) => analytics.setReferralInfo(biOptions?.referralInfo) });
  router.whenNavigate({
    list: async ({ integrationData: { biOptions } }) => analytics.setReferralInfo(biOptions?.referralInfo),
  });

  return {
    async pageReady() {
      // XXX: call to setProps crashes in Editor if done before pageReady().
      setProps(initialProps);

      componentEventHandler.on(SETTINGS_EVENTS.TAB_STATE, (tabState: TabState) => app.setProps({ tabState }));
      componentEventHandler.on(SETTINGS_EVENTS.ARE_SETTINGS_OPENED, (areSettingsOpened: boolean) =>
        app.setProps({ areSettingsOpened }),
      );
      componentEventHandler.onReset(() => app.setProps({ tabState: TabState.REGULAR, areSettingsOpened: false }));

      wixCodeApi.location.onChange(async () => {
        return router.initialize();
      });

      wixCodeApi.site.onInstanceChanged(({ instance: newInstance }) => {
        setProps({ instance: newInstance });
      }, appDefinitionId);

      router.whenLogin({
        checkout: ({ user }) => checkout.initializeUser?.(user),
      });

      if (isMPA) {
        const routerData = wixCodeApi.window.getRouterPublicData<PlanListPublicData>();
        renderSEOTags(routerData?.plans ?? [], wixCodeApi, appParams.appRouters);
        if (routerData) {
          await list.update(routerData.integrationData ?? {}, routerData.plans);
        } else {
          await list.update({});
        }
      } else {
        await app.initialize();
      }
    },
    async updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
      const plans = await list.fetchAndOrderPlans({});
      setProps({ plans: plans.plans });
    },
  };
};

function renderSEOTags(
  plans: PublicPlan[],
  wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
  [appRouter]: ControllerParams['controllerConfig']['appParams']['appRouters'],
) {
  if (isAppRouter(appRouter)) {
    const slug = getPackagePickerSlug(appRouter);
    wixCodeApi.seo.renderSEOTags({
      itemType: 'PRICING_PLANS',
      itemData: {
        plans,
        amount: plans.length,
        pageUrl: `${wixCodeApi.location.baseUrl}/${appRouter.prefix}/${slug}`,
      },
    });
  }
}

interface AppRouter {
  prefix: string;
  config: string;
}

function isAppRouter(thing: unknown): thing is AppRouter {
  return (
    thing != null &&
    typeof thing === 'object' &&
    'prefix' in thing &&
    typeof thing.prefix === 'string' &&
    'config' in thing &&
    typeof thing.config === 'string'
  );
}

function getPackagePickerSlug(appRouter: AppRouter): string {
  try {
    const slugs = configToSlugMap(JSON.parse(appRouter.config));
    return slugs?.membership_plan_picker_tpa ?? '';
  } catch (e) {
    return '';
  }
}

export default createController;
