import { createSettingsParam, SettingsParamType } from '@wix/tpa-settings';

import { FEED_WIDGET_SECRET_SLUG } from 'api/groups/constants';

import * as common from 'settings/common';
import * as feed from 'settings/feed';

export default {
  ...common.settings,
  ...feed.settings,

  postsCount: createSettingsParam('postsCount', {
    getDefaultValue: () => 5,
    type: SettingsParamType.Number,
  }),

  groupId: createSettingsParam('groupId', {
    getDefaultValue: () => FEED_WIDGET_SECRET_SLUG,
    type: SettingsParamType.Text,
  }),

  groupFeedTitle: createSettingsParam('groupFeedTitle', {
    getDefaultValue: ({ t }) =>
      t('groups-web.settings.TextTab.feedTitle.widget.default'),
    type: SettingsParamType.String,
  }),
};
