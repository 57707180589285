import React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { resolveId } from '@wix/communities-blog-client-common';

import type { NormalizedPost } from '@app/external/common/types';
import { useFeedMetadataSettings } from '../../../common/hooks/use-feed-metadata-settings';
import { usePostBorderWidth } from '../../../common/hooks/use-post-border-width';
import RecentPostsListItem from '../recent-posts-list-item';
import styles from './recent-posts-list.scss';

const RecentPostsListItemWrapper: React.FC<{ post: NormalizedPost }> = ({
  post,
}) => {
  const { borderWidth } = usePostBorderWidth();
  const { isMetadataFooterVisible } = useFeedMetadataSettings({ post });

  const itemClassName = classNames(
    styles.listItem,
    'blog-card-background-color',
    'blog-card-border-color',
    'recent-posts-list__list-item',
  );

  return (
    <div
      className={itemClassName}
      style={{ borderWidth }}
      key={resolveId(post)}
    >
      <RecentPostsListItem
        post={post}
        isMetadataFooterVisible={isMetadataFooterVisible}
      />
    </div>
  );
};

export const RecentPostsList: React.FC<{ posts: NormalizedPost[] }> = ({
  posts = [],
}) => {
  return (
    <div className={styles.container}>
      {map(posts, (post) => (
        <RecentPostsListItemWrapper key={post.id} post={post} />
      ))}
    </div>
  );
};

export default RecentPostsList;
