export enum ToastType {
  status = 'status',
  success = 'success',
  error = 'error',
  preview = 'preview',
}

export type IToastProviderProps = {
  toastMessage?: {
    key: string;
    payload?: any;
    type: ToastType;
  };

  clearMessage(): void;
};
