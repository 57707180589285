import { head } from 'lodash';
import {
  ENTITY_TYPE_POSTS,
  createAction,
} from '@wix/communities-blog-client-common';
import { type NormalizedPost } from '@app/external/common/types';
import fetchFeedPosts from '../../common/actions/fetch-feed-posts';
import { type PostPageThunkAction } from '../types';

export const fetchLastPostRequest = createAction('lastPost/FETCH_REQUEST');
export const fetchLastPostSuccess = createAction(
  'lastPost/FETCH_SUCCESS',
  (payload: NormalizedPost | undefined) => payload,
);
const fetchLastPostFailure = createAction('lastPost/FETCH_FAILURE');

export const fetchLastPost =
  (): PostPageThunkAction<NormalizedPost | undefined> => (dispatch) => {
    dispatch(fetchLastPostRequest());

    const promise = dispatch(
      fetchFeedPosts({
        page: 1,
        pageSize: 1,
        entityType: ENTITY_TYPE_POSTS,
      }),
    );

    let lastPost: NormalizedPost | undefined;
    return promise
      .then(({ body: posts }) => {
        lastPost = head(posts);
        dispatch(fetchLastPostSuccess(lastPost));
      })
      .catch(() => dispatch(fetchLastPostFailure()))
      .then(() => lastPost);
  };
