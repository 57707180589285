import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import RecentPostsDesktop from './recent-posts-desktop';
import RecentPostsMobile from './recent-posts-mobile';
import type { Props } from './types';

export default (props: Props) => {
  const { isMobile } = useEnvironment();

  const Component = isMobile ? RecentPostsMobile : RecentPostsDesktop;

  return <Component {...props} />;
};
