import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isMA } from '../../../../selectors/isMA';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';
import { getChallengeData } from '../data';

export async function getChallengeId(
  flowAPI: ControllerFlowAPI,
): Promise<string> {
  try {
    const challengeData = await getChallengeData(flowAPI);
    const challenge = challengeData?.challenge || null;

    if (isMA(flowAPI)) {
      return null;
    }

    return challenge?.id;
  } catch (error) {
    handleError({ error, context: 'getChallengeId' });
  }
}
