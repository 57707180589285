import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { type MediaPlatformImage } from '@wix/communities-blog-client-common';

import FluidAvatarImage from '../fluid-avatar-image';
import styles from './user-avatar-image.scss';

const getImageSize = (styleVariable: string) => parseInt(styleVariable, 10) * 2;

const IMAGE_SIZE_MAP = {
  large: getImageSize(styles.IMAGE_SIZE_LARGE),
  extraLarge: getImageSize(styles.IMAGE_SIZE_EXTRA_LARGE),
  promote: getImageSize(styles.IMAGE_SIZE_PROMOTE),
  widget: getImageSize(styles.IMAGE_SIZE_WIDGET),
  comment: getImageSize(styles.IMAGE_SIZE_COMMENT),
  commentSearch: getImageSize(styles.IMAGE_SIZE_COMMENT_SEARCH),
};
const IMAGE_SIZE_DEFAULT = getImageSize(styles.IMAGE_SIZE_DEFAULT);

type Props = {
  user: { name?: string; image: MediaPlatformImage };
  type?: keyof typeof IMAGE_SIZE_MAP;
  className?: string;
};

const UserAvatarImage: React.FC<Props> = ({ className, type, user }) => {
  const imageSize = (type && IMAGE_SIZE_MAP[type]) ?? IMAGE_SIZE_DEFAULT;
  const [t] = useTranslation();

  return (
    <span
      className={classNames(
        styles.image,
        type && styles[type],
        className,
        'avatar-image',
      )}
    >
      <FluidAvatarImage
        ariaLabel={`${t('aria-label.authors-picture')}: ${user?.name}`}
        image={user?.image}
        size={imageSize}
      />
    </span>
  );
};

export default UserAvatarImage;
