import React from 'react';

import {
  BoxSelection,
  Image,
  ImageResizeOptions,
  Text,
} from 'wix-ui-tpa/cssVars';
import { MediaItem } from '@wix/ambassador-challenges-v1-participant/types';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useIsFullWithInViewer } from '../../services/hooks/useIsFullWidthInViewer';
import uuid from 'uuid';
import { getMediaImagePreview } from '../../selectors/media';

import { classes, st } from './MediaBoxSelection.st.css';

export interface IMediaBoxSelectionOption {
  id: string;
  label?: string;
  media?: MediaItem;
}

export interface IMediaBoxSelection {
  className?: string;
  dataHook?: string;
  label: string;
  isMultipleAnswers: boolean;
  columns: 'single' | 'two' | 'three';
  options: IMediaBoxSelectionOption[];
  alignment?: 'left' | 'center' | 'right';
  fit?: 'cover' | 'contain';
}

export const MediaBoxSelection: FCWithChildren<IMediaBoxSelection> = ({
  className = '',
  dataHook = '',
  label,
  alignment = 'left',
  fit = 'cover',
  isMultipleAnswers,
  columns,
  options,
}) => {
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const isFullWidthLayout = useIsFullWithInViewer();
  const [selected, setSelected] = React.useState({});

  const onSelectionChange = React.useCallback(
    ({ id }: { id: string }) => {
      if (selected[id]) {
        setSelected({ ...selected, [id]: false });
      } else {
        if (isMultipleAnswers) {
          setSelected({ ...selected, [id]: true });
        } else {
          setSelected({ [id]: true });
        }
      }
    },
    [selected, isMultipleAnswers],
  );

  if (!experiments.enabled('specs.programs.OOIShowMediaChoice')) {
    return null;
  }

  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
          alignment,
          fit,
          columns,
          fullWidth: isFullWidthLayout,
        },
        className,
      )}
      data-hook={dataHook}
    >
      <div className={classes.content}>
        <BoxSelection
          name={`media-box-selection-${uuid()}`}
          label={label}
          onChange={onSelectionChange}
        >
          {options.map((option) => {
            const image = option.media.image;
            let aspectRatio = image.width / image.height;

            return (
              <BoxSelection.Option
                id={option.id}
                className={classes.option}
                checked={selected[option.id]}
              >
                <div className={classes.optionMediaContent}>
                  <div className={classes.imgWrapper}>
                    <Image
                      className={classes.img}
                      src={getMediaImagePreview(image, 500, 500)}
                      alt={image.altText || ''}
                      aspectRatio={aspectRatio}
                      sourceWidth={image.width}
                      sourceHeight={image.height}
                      stretchImage={false}
                      resize={
                        fit === 'cover'
                          ? ImageResizeOptions.cover
                          : ImageResizeOptions.contain
                      }
                    />
                  </div>
                  {option.label ? <Text>{option.label}</Text> : null}
                </div>
              </BoxSelection.Option>
            );
          })}
        </BoxSelection>
      </div>
    </div>
  );
};
