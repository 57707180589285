export const getLocalizedYearAndMonth = (
  utcDate: string | Date,
  timeZone: string | undefined,
) => {
  const date = new Date(utcDate);
  if (!timeZone) {
    return { month: date.getMonth() + 1, year: date.getFullYear() };
  }
  const [month, year] = date
    .toLocaleString('en', { timeZone, year: 'numeric', month: 'numeric' })
    .split('/');
  return { month: parseInt(month, 10), year: parseInt(year, 10) };
};

export const isSameDay = (date1: Date, date2: Date) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};
