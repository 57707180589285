import React, { FC } from 'react';
import { Image, ImageResizeOptions, Text, TextPriority } from 'wix-ui-tpa/cssVars';
import { Plan } from '@wix/ambassador-pricing-plans-v3-plan/types';
import { useCurrencyFormatter, useTranslation } from '@wix/yoshi-flow-editor';
import { usePrice } from '../../../../hooks';
import { useLocale } from '../../../../hooks/useLocale';
import { getFormattedAdditionalFees, getFreeTrialDaysLabel, getPeriodLabel, getPlanDuration } from './plan-v3-utils';
import { classes } from './PlanPreview.st.css';

export const PlanPreview: FC<{ plan: Plan }> = ({ plan }) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const planPrice = usePrice({
    value: plan.pricingVariants?.[0]?.pricingStrategies?.[0]?.flatRate?.amount,
    currency: plan.currency,
  });
  const [setupFee] = getFormattedAdditionalFees({ createCurrencyFormatter: useCurrencyFormatter, plan, locale });

  const freeTrial = getFreeTrialDaysLabel(plan, t);
  const duration = getPlanDuration(plan, t);
  const paymentFrequency = getPeriodLabel(plan, t) ?? '';

  return (
    <section className={classes.root}>
      {plan.image && (
        <Image
          fluid
          src={plan.image.id}
          sourceWidth={plan.image.width}
          sourceHeight={plan.image.height}
          resize={ImageResizeOptions.cover}
          width={320}
          height={140}
        />
      )}
      <div className={classes.previewContent}>
        <Text className={classes.name}>{plan.name}</Text>
        <div>
          <div className={classes.priceContainer}>
            <Text className={classes.currency}>{planPrice.currency}</Text>
            {/* TODO: Should price have dynamic font size? */}
            <Text className={classes.price}>{planPrice.price}</Text>
          </div>
          {paymentFrequency && (
            <Text className={classes.frequency} tagName="div">
              {paymentFrequency}
            </Text>
          )}
          {setupFee && (
            <Text tagName="div" className={classes.setupFee}>
              + {setupFee.amount} {setupFee.name}
            </Text>
          )}
        </div>
        <Text tagName="div" className={classes.description}>
          {plan.description}
        </Text>
        <Text tagName="div" priority={TextPriority.secondary} className={classes.duration}>
          <span>{duration}</span>
          {freeTrial && <div>{freeTrial}</div>}
        </Text>
      </div>
    </section>
  );
};
