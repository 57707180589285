import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {BI_ORIGIN, Specs} from '../../components/thankYouPage/constants';
import {CheckoutApi} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/CheckoutApi/CheckoutApi';

export class CheckoutService {
  private readonly checkoutApi: CheckoutApi;
  private readonly siteStore: SiteStore;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.checkoutApi = new CheckoutApi({siteStore, origin: BI_ORIGIN});
    this.siteStore = siteStore;
  }

  public async markCheckoutAsCompleted(checkoutId: string, orderId?: string): Promise<void> {
    if (this.siteStore.isEditorMode() || this.siteStore.isPreviewMode() || !checkoutId) {
      return;
    }
    if (this.siteStore.experiments.enabled(Specs.SendMarkCheckoutAsCompletedWithOrderIdFromTYP)) {
      await this.checkoutApi.markCheckoutAsCompleted({id: checkoutId, orderId});
    } else {
      await this.checkoutApi.markCheckoutAsCompleted({id: checkoutId});
    }
  }
}
