import {
  seeAllLinkButtonDesignParams,
  relatedPostsWidgetStyleParams,
  SeeAllLinkLayoutType,
} from '@wix/communities-blog-client-common';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { type AppState } from '../../common/types';

const getSeeAllLinkLayout = (state: AppState) =>
  getAppSettingsValue({
    state,
    key: `style.numbers.${relatedPostsWidgetStyleParams['see-all-link-layoutType'].key}`,
    fallback:
      relatedPostsWidgetStyleParams['see-all-link-layoutType'].defaultValue,
  }) as SeeAllLinkLayoutType;

export const getIsSeeAllLinkLayoutText = (state: AppState) =>
  getSeeAllLinkLayout(state) === SeeAllLinkLayoutType.Text;

export const getIsSeeAllLinkUppercase = (state: AppState) => {
  const seeAllLinkLayout = getSeeAllLinkLayout(state);

  const params = {
    [SeeAllLinkLayoutType.Text]: seeAllLinkButtonDesignParams,
    [SeeAllLinkLayoutType.Button]: seeAllLinkButtonDesignParams,
  }[seeAllLinkLayout];

  return getAppSettingsValue({
    state,
    key: `style.booleans.${params.uppercase.key}`,
    fallback: params.uppercase.defaultValue,
  });
};
