import React from 'react';
import { st, classes } from '../../Widget.st.css';
import { Structure } from '../../../../../enums';
import { useSettings } from '@wix/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';
import settingsParams from '../../../settingsParams';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import NumberTwoColumns from './NumberTwoColumns';
import NumberOneColumn from './NumberOneColumn';
import { AccordionCSS } from './Interfaces';

const AccordionStrip = ({
  filteredQuestions,
}: {
  filteredQuestions: QuestionEntry[];
}) => {
  const settings: ISettingsContextValue = useSettings();
  const structureColumn: Structure = settings.get(settingsParams.structure);
  const twoColumnStructure: boolean = structureColumn === Structure.TWO_COLUMNS;
  const indexes: number[] = filteredQuestions.map((item, index) => index + 1);
  const { gap } = settings.get(settingsParams.spacing);
  const customStyle = {
    '--item-gap': `${gap}px`,
  } as AccordionCSS;

  return (
    <div
      style={customStyle}
      className={st(classes.Body, {
        isTwoColumn: twoColumnStructure,
        isNumberedLayout: true,
      })}
    >
      {twoColumnStructure ? (
        <NumberTwoColumns filteredQuestions={filteredQuestions} />
      ) : (
        <NumberOneColumn
          filteredQuestions={filteredQuestions}
          indexes={indexes}
        />
      )}
    </div>
  );
};

export default AccordionStrip;
