import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';

import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import { getPostListMobileLayoutNameForTitleFontColor } from '../../selectors/mobile-settings-selectors';
import { getFeedColorClassName } from '../../services/layout-config';
import DotDotDot from '../dotdotdot';
import { connect } from '../runtime-context';
import styles from './post-list-item-pro-gallery-title.scss';

const getTitleStyle = memoizeOne((titleLineCount, layoutSidesPadding) => ({
  WebkitLineClamp: titleLineCount,
  width: `calc(100% - ${layoutSidesPadding || 0})`,
}));

export const PostListItemProGalleryTitle = ({
  style,
  layoutName,
  lineCount,
  titleLineCount,
  title,
  type,
  showCategoryLabel,
  layoutSidesPadding,
  mobileLayoutName,
  isHoverDisabled,
}) => {
  const { getPostClassName } = useIsFeedDesignEnabled();
  const titleClassName = classNames(
    getPostClassName(
      getFeedColorClassName(mobileLayoutName || layoutName, 'title-color'),
      'title-font',
    ),
  );
  const className = classNames(
    styles.title,
    {
      [styles.titleHover]: !isHoverDisabled,
      'blog-hover-container-element-color': !isHoverDisabled,
    },
    styles[type],
    'post-title',
    titleClassName,
    showCategoryLabel && styles.withCategoryLabel,
  );

  return (
    <div className={className} style={style} data-hook="post-title">
      {titleLineCount ? (
        <h2
          className={classNames(
            style.fontSize ? styles.inheritFont : titleClassName,
            styles.clamp,
          )}
          style={getTitleStyle(titleLineCount, layoutSidesPadding)}
        >
          {title}
        </h2>
      ) : (
        <DotDotDot
          useExactLineHeight={true}
          clamp="auto"
          maxLineCount={lineCount}
          className={style.fontSize ? styles.inheritFont : titleClassName}
          as="h2"
        >
          {title}
        </DotDotDot>
      )}
    </div>
  );
};

PostListItemProGalleryTitle.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  lineCount: PropTypes.number,
  layoutSidesPadding: PropTypes.number,
  titleLineCount: PropTypes.number,
  style: PropTypes.object,
  layoutName: PropTypes.string,
  showCategoryLabel: PropTypes.bool,
  mobileLayoutName: PropTypes.string,
  isHoverDisabled: PropTypes.bool,
};

const mapRuntimeToProps = (state) => {
  return {
    mobileLayoutName: getPostListMobileLayoutNameForTitleFontColor(state),
  };
};

export default flowRight(
  withLayoutColorClasses,
  connect(mapRuntimeToProps),
)(PostListItemProGalleryTitle);
