// eslint-disable-next-line no-restricted-imports
import { connect } from 'react-redux';
import { type IHostProps } from '@wix/yoshi-flow-editor';
import { type Actions, type AppState } from '../../types';
import { type WidgetState } from './widget-state.type';

export type MapRuntimeToProps<StateProps, OwnProps> = (
  state: AppState,
  ownProps: OwnProps,
  actions: Actions,
  host: IHostProps,
  workerReady: boolean,
) => StateProps;

export default <StateProps = {}, OwnProps = {}>(
  mapRuntimeToProps: MapRuntimeToProps<StateProps, OwnProps>,
) =>
  connect<StateProps, {}, OwnProps, WidgetState>((state, ownProps) => {
    return mapRuntimeToProps(
      state.state,
      ownProps,
      state.actions,
      state.host,
      state.workerReady,
    );
  }, {});
