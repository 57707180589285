import React from 'react';
import type { Category } from '@wix/ambassador-faq-category-v2-category/types';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa/cssVars';
import { st, classes } from '../../Widget.st.css';

const VerticalTabs = ({
  categoriesToRender,
  activeTab,
  setActiveTab,
  sideCategoriesPosition,
}: {
  categoriesToRender: Category[];
  activeTab: number;
  setActiveTab: (index: number) => void;
  sideCategoriesPosition: boolean;
}) => {
  return (
    <div
      className={st(classes.Tabs, {
        isSideCategoriesPosition: sideCategoriesPosition,
      })}
    >
      {categoriesToRender.map((category, index) => {
        return (
          <TextButton
            priority={
              activeTab === index
                ? TextButtonPriority.link
                : TextButtonPriority.secondary
            }
            className={st(classes.underlineTextColor, {
              isLink: activeTab === index,
            })}
            onClick={setActiveTab.bind(null, index)}
          >
            {category.title}
          </TextButton>
        );
      })}
    </div>
  );
};

export default VerticalTabs;
