import { type CreateControllerFn } from '@wix/yoshi-flow-editor';

import { createMyPostsPageController } from '../../external/my-posts-page/controller';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}) => {
  return createMyPostsPageController(controllerConfig, flowAPI);
};

export default createController;
