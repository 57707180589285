import {
  installRegisteredApps,
  maybeInstallMembersArea,
} from '@wix/members-area-integration-kit';
import EditorWrapper from './EditorWrapper';
import {
  CHALLENGE_PAGE_ID,
  CHALLENGES_INSTALL_MIGRATION_TYPE,
  CHALLENGES_LIST_PAGE_ID,
  CHALLENGES_LIST_PAGE_MANIFEST_KEY,
  CHALLENGES_LIST_PAGE_TYPE,
  CHALLENGES_PAGE_MANIFEST_KEY,
  CHALLENGES_PAGES,
  GROUPS_APP_ID,
  PAID_PLANS_APP_ID,
  PARTICIPANT_PAGE_ID,
  PARTICIPANT_PAGE_MANIFEST_KEY,
  PAYMENT_PAGE_ID,
  PAYMENT_PAGE_MANIFEST_KEY,
  THANK_YOU_PAGE_ID,
  THANK_YOU_PAGE_MANIFEST_KEY,
} from './app-config';
import { getChallengesManifest } from './manifest';
import AppManifest, { AppManagerEvent } from './types/manifest';
import { TPARef } from './types/common';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { Challenges } from './types/Experiments';
import { CHALLENGES_BM_ROUTES } from '../config/constants';
import { installParticipantPage } from './migrations/installParticipantPage';

export class ChallengesPlatform {
  private readonly editor: EditorWrapper;
  private readonly translations;
  private flowAPI: EditorScriptFlowAPI;

  constructor(
    editorSDK,
    public readonly appDefId: string,
    flowAPI: EditorScriptFlowAPI,
    public readonly isADI?: boolean,
    public readonly isFirstInstall?: boolean,
    public readonly isEditorWizardCreationFlow?: boolean,
  ) {
    this.editor = new EditorWrapper(editorSDK, appDefId);
    this.translations = flowAPI.translations;
    this.flowAPI = flowAPI;
  }

  async isChallengesInstalled() {
    return this.editor.isAppInstalled(this.appDefId);
  }

  async installTPA(appDefId: string) {
    const isTPAInstalled = await this.editor.isAppInstalled(appDefId);

    if (!isTPAInstalled) {
      await this.editor.installTPA(appDefId);
    }
  }

  async handleMigration(payload: {
    migrationType: CHALLENGES_INSTALL_MIGRATION_TYPE;
  }) {
    if (payload?.migrationType === 'install_pricing_plans') {
      await this.installTPA(PAID_PLANS_APP_ID);
    }

    if (payload?.migrationType === 'install_groups') {
      await this.installTPA(GROUPS_APP_ID);
    }
  }

  async setupProgramsPages(isFirstInstall: boolean = false) {
    const isParticipantPageEnabled = this.flowAPI.experiments.enabled(
      Challenges.enableParticipantPageInEditor,
    );

    try {
      this.flowAPI.fedops.interactionStarted('setup-programs-pages');

      const chalPages = await this.challengesPagesOnStage();
      const thankYouPage = chalPages.find(
        (page) => page.tpaPageId === THANK_YOU_PAGE_ID,
      );

      try {
        await this.editor.updatePage(thankYouPage?.pageRef, {
          pageUriSEO: 'challenge-thanks',
        });

        // await this.editor.save();
        console.log('[challenges]: Thank you page migrated');
      } catch (err) {
        console.error('[challenges]:error in migrating thank you page', err);
      }

      /* Page titles should be changed for the all new users
        and also for old users after Participant page will be opened (Program Page => Visitor Page). */
      if (isFirstInstall || isParticipantPageEnabled) {
        await this.editor.setPageInitialTitles(
          chalPages,
          this.translations,
          isParticipantPageEnabled,
        );
        await this.editor.addPagesToEditorPageMenu(chalPages);
      }

      await this.setStateForPages();

      this.flowAPI.fedops.interactionEnded('setup-programs-pages');
    } catch (err) {
      console.error('[Challenges]: cannot setup pages ', err);
      return Promise.reject(err);
    }
  }

  async setStateForPages(): Promise<void> {
    await this.setPageState({
      manifestKey: PAYMENT_PAGE_MANIFEST_KEY,
      tpaPageId: PAYMENT_PAGE_ID,
      applicationId: this.appDefId,
    });
    await this.setPageState({
      manifestKey: CHALLENGES_PAGE_MANIFEST_KEY,
      tpaPageId: CHALLENGE_PAGE_ID,
      applicationId: this.appDefId,
    });
    await this.setPageState({
      manifestKey: THANK_YOU_PAGE_MANIFEST_KEY,
      tpaPageId: THANK_YOU_PAGE_ID,
      applicationId: this.appDefId,
    });
    await this.setPageState({
      manifestKey: CHALLENGES_LIST_PAGE_MANIFEST_KEY,
      tpaPageId: CHALLENGES_LIST_PAGE_ID,
      applicationId: this.appDefId,
    });
    await this.setPageState({
      manifestKey: PARTICIPANT_PAGE_MANIFEST_KEY,
      tpaPageId: PARTICIPANT_PAGE_ID,
      applicationId: this.appDefId,
    });
  }

  async updatePublicData(): Promise<void> {
    const challengesPages = await this.challengesPagesOnStage();

    if (this.isFirstInstall && !this.isADI) {
      const challengePageTpaSection = await this.editor.getTpaSection(
        CHALLENGE_PAGE_ID,
        challengesPages,
      );
      const challengeListPageTpaSection = await this.editor.getTpaSection(
        CHALLENGES_LIST_PAGE_ID,
        challengesPages,
      );

      if (challengePageTpaSection) {
        await this.editor.setPublicData(
          {
            id: challengePageTpaSection.id,
            type: 'DESKTOP',
          },
          {
            key: 'visitorPageBodyEnableSidebar',
            value: true,
          },
        );
      }

      if (challengeListPageTpaSection) {
        await this.editor.setPublicData(
          {
            id: challengeListPageTpaSection.id,
            type: 'DESKTOP',
          },
          {
            key: 'dpListTitle',
            value: true,
          },
        );
      }
    }
  }

  async participantPageMigrations() {
    const challengesPages = await this.challengesPagesOnStage();
    try {
      this.flowAPI.fedops.interactionStarted('call-install-page-editor');
      await installParticipantPage(this.flowAPI, this.editor, challengesPages);
      this.flowAPI.fedops.interactionEnded('call-install-page-editor');
    } catch (err) {
      console.error('[Challenges]: error trying migrate participant page', err);
      return Promise.reject(err);
    }
  }

  async installDependencies(): Promise<void> {
    // skipping all the installations of TPA, in Classic Autopilot will install it
    return Promise.resolve();
  }

  async setupMA({
    isFirstInstall = false,
  }: {
    isFirstInstall: boolean;
  }): Promise<void> {
    const isSkipMAInstallationEnabled = this.flowAPI.experiments.enabled(
      Challenges.skipMAInstallation,
    );
    const skipMAInstallation =
      this.isEditorWizardCreationFlow && isSkipMAInstallationEnabled;
    // https://wix.slack.com/archives/CM11JK8NA/p1655885110600659

    if (isFirstInstall && !skipMAInstallation) {
      try {
        await maybeInstallMembersArea();
        await installRegisteredApps();
      } catch (err) {
        console.error('[Challenges]: error trying setup MA', err);
        return Promise.reject(err);
      }
    }
  }

  async setPageState(options: {
    applicationId: string;
    manifestKey: string;
    tpaPageId: string;
  }) {
    const { applicationId, manifestKey, tpaPageId } = options;

    try {
      const allSitePages = await this.editor.getAllPages();
      const page = allSitePages.find((_page) => {
        return (
          _page.managingAppDefId === applicationId &&
          _page.tpaPageId === tpaPageId
        );
      });

      if (page?.id) {
        return await this.editor.setPageState({
          [manifestKey]: [{ id: page?.id }],
        });
      } else {
        console.warn("[Challenges]: can't find a page to link with manifest.");
      }
    } catch (err) {
      console.error("[Challenges]: can't link page with manifest", err);
      return Promise.reject(err);
    }
  }

  private getChallengesTPARefs(allSitePages, applicationId): TPARef[] {
    return allSitePages
      .filter(
        (page) =>
          page.tpaApplicationId === applicationId &&
          CHALLENGES_PAGES.includes(page.tpaPageId),
      )
      .map(({ id, tpaPageId, title, managingAppDefId }) => {
        return {
          title,
          tpaPageId,
          managingAppDefId,
          pageRef: { id },
        };
      });
  }

  async getManifest({ appManifestBuilder }): Promise<AppManifest> {
    return getChallengesManifest<typeof this.flowAPI.experiments>({
      appManifestBuilder,
      translations: this.translations,
      openDashboard: this.openDashboard.bind(this),
      experiments: this.flowAPI.experiments,
    });
  }

  async deleteApp() {
    try {
      await this.editor.deleteApp();
    } catch (e) {
      console.warn('[challenges]', e);
    }
  }

  async challengesPagesOnStage() {
    const [{ applicationId }, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);

    return this.getChallengesTPARefs(allSitePages, applicationId);
  }

  async getPageOnStage(pageId: string) {
    const challengesPages = await this.challengesPagesOnStage();

    return challengesPages.find((page) => page.tpaPageId === pageId);
  }

  async getTPASectionId(pageId: string): Promise<string> {
    const challengesPages = await this.challengesPagesOnStage();
    const challengePageTpaSection = await this.editor.getTpaSection(
      pageId,
      challengesPages,
    );

    return challengePageTpaSection?.id || '';
  }

  async openDashboard(actionId: string) {
    if (actionId === AppManagerEvent.OPEN_DASHBOARD) {
      return this.editor.openDashboard(CHALLENGES_BM_ROUTES.Home);
    }
    if (actionId === AppManagerEvent.OPEN_CREATE_CHALLENGE) {
      return this.editor.openDashboard(CHALLENGES_BM_ROUTES.CreateProgram);
    }

    if (actionId === AppManagerEvent.OPEN_SP) {
      await this.editor.openAppPage(CHALLENGES_LIST_PAGE_ID, this.appDefId);

      const challengeListPageId = await this.getTPASectionId(
        CHALLENGES_LIST_PAGE_ID,
      );

      try {
        await this.editor.openSP(
          {
            id: challengeListPageId,
            type: 'DESKTOP',
          },
          this.appDefId,
          CHALLENGES_LIST_PAGE_TYPE,
        );
      } catch (e) {
        console.error('Error on open SP from App Manager:', e);
      }
    }

    return;
  }

  // todo: use this method for real errors
  handleInstallError() {}
}
