import { noop } from 'lodash';
import { type IUser } from '@wix/yoshi-flow-editor';
import { type CommonThunkAction } from '@app/types/common-thunk-action.type';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { getLanguage } from '../store/basic-params/basic-params-selectors';

export const requestLogin =
  (): CommonThunkAction<Promise<IUser | void>> =>
  async (dispatch, getState, { platformApi }) => {
    return platformApi.user
      .promptLogin({ lang: getLanguage(getState()) })
      .catch(noop);
  };

export const requestLoginPromisified = createPromisifiedAction(
  requestLogin,
  (result: Partial<IUser> = {}) => ({
    id: result.id,
    loggedIn: result.loggedIn,
    role: result.role,
  }),
);
