import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeData } from '../storage-contexts/Challenge';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { isUserJoinedAlready } from '../User/helpers/userTypeHandlers';
import { getDetails } from '../../selectors/step';
import { request } from '../../services/request';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { isRequiredOwnerData } from './helpers';
import { DescriptionFieldSet } from '@wix/ambassador-challenges-v1-challenge/types';
import { getStep as getParticipantStep } from '@wix/ambassador-challenges-v1-participant/http';
import { getStep } from '@wix/ambassador-challenges-v1-challenge/http';
import { isV3enabled } from '../../experiments/isV3enabled';
import { requestStepV3 } from '../storage-contexts/Challenge/api/requestStepV3';
import { Step } from '../../types/v3Types';

export type StepDescriptionMap = { [participantStepId: string]: string };

export const stepDescriptionMap: StepDescriptionMap = {};

export const updateStepDescriptionMap = (
  sourceStep: Step,
  participantStepId: string,
) => {
  stepDescriptionMap[participantStepId] = getDetails(sourceStep);
};

export async function requestParticipantStep(
  stepId: string,
  flowAPI: ControllerFlowAPI,
): Promise<void> {
  // here we assume, that challenge, participant and sections / steps already loaded
  const challengeData = await getChallengeData(flowAPI);
  const challenge = challengeData?.challenge || null;
  const participant = (await userProviderPropsMap(flowAPI))?.participant;
  // const steps = (await participantSectionsPropsMap(flowAPI))?.participantSteps
  //   ?.steps;
  const isJoinedParticipant = isUserJoinedAlready(
    participant?.transitions?.[0]?.state,
  );
  const isPreviewWithParticipant =
    flowAPI.environment.isPreview && isJoinedParticipant;
  const isOwnerData = isRequiredOwnerData(flowAPI) && !isPreviewWithParticipant;

  if (isV3enabled(flowAPI)) {
    const step = await requestStepV3(
      { stepId, descriptionFieldSet: DescriptionFieldSet.EXTENDED },
      flowAPI,
    );
    updateStepDescriptionMap(step, stepId);
  } else if (!isOwnerData) {
    try {
      const step = (
        await request(
          flowAPI,
          getParticipantStep({
            challengeId: challenge.id,
            participantId: participant.id,
            stepId,
            descriptionFieldSet: DescriptionFieldSet.EXTENDED,
          }),
        )
      )?.data?.step;
      updateStepDescriptionMap(step.source, stepId);
    } catch (error) {
      console.error(error);
      handleError({ error, context: 'requestParticipantStep (participant)' });
    }
  } else {
    try {
      const step = (
        await request(
          flowAPI,
          getStep({
            challengeId: challenge.id,
            stepId,
            descriptionFieldSet: DescriptionFieldSet.EXTENDED,
          }),
        )
      )?.data?.step;

      updateStepDescriptionMap(step, stepId);
    } catch (error) {
      console.error(error);
      handleError({ error, context: 'requestParticipantStep (challenge)' });
    }
  }

  flowAPI.controllerConfig.setProps({
    stepDescriptionMap,
  });
}
