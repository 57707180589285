import React from 'react';
import classNames from 'classnames';
import { Counter } from '../counter';
import { type CounterProps } from '../counter/counter';
import styles from './counter-compact.scss';

type Props = {
  dataHook: string;
  children: React.ReactNode;
  tabIndex?: number;
} & CounterProps;

export const CounterCompact = (props: Props) => (
  <span
    className={classNames(styles.container, props.className)}
    data-hook={props.dataHook}
  >
    {props.children}
    <Counter {...props} className={styles.count} />
  </span>
);
