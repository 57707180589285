import {
  type Middleware,
  type MiddlewareAPI,
  type Dispatch,
  type AnyAction,
} from 'redux';
import { WS_EMIT, WS_JOIN, WS_LEAVE } from '../../actions/ws-actions';
import { isAuthenticated } from '../../store/auth/auth-selectors';
import { createDuplexerConnection } from './create-duplexer-connection';

type DuplexerChannel = {
  send: (eventName: string, message: any) => void;
  on: (
    event: string,
    callback: (data: any, context?: { user?: any }) => void,
  ) => void;
};

type DuplexerConnection = {
  subscribe: (
    channelName: string,
    options: { forceTokenRequest: boolean },
  ) => DuplexerChannel;
  unsubscribe: (channelName: string) => void;
};

type WsAction = {
  type: typeof WS_JOIN | typeof WS_LEAVE | typeof WS_EMIT;
  payload: {
    channel: string;
    hasClientEvents?: boolean;
    eventName?: string;
    message?: any;
  };
};

type ActionHandlerParams = {
  action: WsAction;
  store: MiddlewareAPI<Dispatch<AnyAction>, any>;
  channelName: string;
  channels: Record<string, DuplexerChannel>;
  connection: DuplexerConnection;
  eventHandlers: Record<string, (data: any, user?: any) => any>;
  captureMessage: (message: string, data: any) => void;
  history: string[];
};

type DuplexerMiddlewareConfig = {
  getInstance: () => string | undefined;
  instanceId: string;
  eventHandlers?: Record<string, (data: any, user?: any) => any>;
  captureMessage?: (message: string, data: any) => void;
};

export default function createDuplexerMiddleware({
  getInstance,
  instanceId,
  eventHandlers = {},
  captureMessage = () => {},
}: DuplexerMiddlewareConfig): Middleware {
  const channels: Record<string, DuplexerChannel> = {};
  const history: string[] = [];
  let connectionPromise: Promise<DuplexerConnection> | undefined;

  return (store) => (next) => (action) => {
    const actionHandler =
      actionHandlers[action.type as keyof typeof actionHandlers];

    if (actionHandler) {
      if (!connectionPromise) {
        connectionPromise = createDuplexerConnection({ getInstance });
      }

      return connectionPromise.then(
        (connection) => {
          history.push(`${action.type} ${action.payload.channel}`);
          const channelName = getChannelName(
            action.payload.channel,
            instanceId,
          );

          actionHandler({
            action,
            store,
            channelName,
            channels,
            connection,
            eventHandlers,
            captureMessage,
            history,
          });
        },
        () => {},
      );
    }

    return next(action);
  };
}

const actionHandlers = {
  [WS_JOIN]: ({
    action,
    channelName,
    store,
    connection,
    eventHandlers,
    channels,
  }) => {
    const channel = connection.subscribe(channelName, {
      forceTokenRequest: Boolean(
        action.payload.hasClientEvents && isAuthenticated(store.getState()),
      ),
    });
    channels[channelName] = channel;

    Object.keys(eventHandlers).forEach((event) => {
      channel.on(event, (data, { user } = {}) => {
        store.dispatch(eventHandlers[event](data, user));
      });
    });

    return channel;
  },
  [WS_LEAVE]: ({
    channelName,
    connection,
    channels,
    captureMessage,
    history,
  }) => {
    if (channels[channelName]) {
      connection.unsubscribe(channelName);
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete channels[channelName];
    } else {
      captureMessage('Cannot leave non-existent channel', {
        channelName,
        history,
      });
    }
  },
  [WS_EMIT]: ({ action, channelName, channels, captureMessage, history }) => {
    const { eventName, message } = action.payload;

    if (channels[channelName] && eventName) {
      return channels[channelName].send(eventName, message);
    } else {
      captureMessage('Cannot emit to non-existent channel', {
        channelName,
        history,
      });
    }
  },
} satisfies Record<string, (params: ActionHandlerParams) => any>;

function getChannelName(entity: string, instanceId: string): string {
  return `instance-${instanceId}__${entity}`;
}
