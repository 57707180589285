import React, { useEffect, useRef } from 'react';
import styles from './print-wrapper.scss';

const WIX_BLOG_HIDE_IN_PRINT = 'wix-blog-hide-in-print';
const WIX_BLOG_PRINT_IN_FULL_WIDTH = 'wix-blog-print-in-full-width';

const printCss = `
  @media print {
    .${WIX_BLOG_HIDE_IN_PRINT} {
      display: none !important;
    }
    .${WIX_BLOG_PRINT_IN_FULL_WIDTH} {
      left: 0 !important;
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    #site-root {
      top: 0 !important;
    }
  }`
  .replace(/\s+/g, ' ')
  .trim();

const PrintWrapper: React.FC = ({ children }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const removePrintInFullWidth: HTMLElement[] = [];
    const removeHideInPrint: Element[] = [];
    const body = document.body;
    let currentElement = wrapperRef.current?.parentElement;

    while (currentElement && currentElement !== body) {
      currentElement.classList.add(WIX_BLOG_PRINT_IN_FULL_WIDTH);
      removePrintInFullWidth.push(currentElement);
      const parent = currentElement.parentElement;
      if (parent) {
        for (const child of parent.children) {
          if (child !== currentElement) {
            child.classList.add(WIX_BLOG_HIDE_IN_PRINT);
            removeHideInPrint.push(child);
          }
        }
      }
      currentElement = parent;
    }

    const printStyleElement = document.createElement('style');
    printStyleElement.innerText = printCss;
    document.head.appendChild(printStyleElement);

    return () => {
      document.head.removeChild(printStyleElement);
      removePrintInFullWidth.forEach((element) =>
        element.classList.remove(WIX_BLOG_PRINT_IN_FULL_WIDTH),
      );
      removeHideInPrint.forEach((element) =>
        element.classList.remove(WIX_BLOG_HIDE_IN_PRINT),
      );
    };
  }, []);

  return (
    <div className={styles.printContainer} ref={wrapperRef}>
      {children}
    </div>
  );
};

export default PrintWrapper;
