import React from 'react';
import PopoverRoot from '../popovers/popover-root';

const BOTTOM_THRESHOLD = 60;

type Props = {
  actionsContainerRef?: React.RefObject<HTMLDivElement>;
  containerRef?: React.RefObject<HTMLButtonElement>;
  componentId: string;
  container?: HTMLElement;
};

export const useGetContainerPosition = ({
  actionsContainerRef,
  containerRef,
  componentId,
  container,
}: Props) => {
  const [isAtTop, setIsAtTop] = React.useState(false);
  const [isAtRight, setIsAtRight] = React.useState(false);

  const checkIfShouldRenderRight = (
    outerContainerRec: DOMRect,
    buttonRec: DOMRect,
  ) => {
    const actionsWidth = actionsContainerRef?.current?.offsetWidth ?? 0;
    const availableSpaceAtRight = outerContainerRec.right - buttonRec.right;
    const availableSpaceAtLeft = buttonRec.left - outerContainerRec.left;

    if (
      availableSpaceAtRight > actionsWidth &&
      availableSpaceAtLeft < availableSpaceAtRight
    ) {
      return true;
    }

    if (availableSpaceAtLeft > actionsWidth) {
      return false;
    }

    return true;
  };

  const checkIfFitsInEditor = () => {
    const rect = containerRef?.current?.getBoundingClientRect();
    if (rect) {
      const popoverContainer = getPopoverContainerRect();
      const scrollOffset =
        window.scrollY || document.documentElement.scrollTop || 0;
      const bodyHeight = document.body.offsetHeight;
      const topOffset = scrollOffset + rect.top;
      const bottomOffset = bodyHeight - topOffset - rect.height;
      const actionsHeight = actionsContainerRef?.current
        ? actionsContainerRef.current.offsetHeight
        : 0;

      setIsAtTop(
        actionsHeight > bottomOffset - BOTTOM_THRESHOLD &&
          actionsHeight < topOffset,
      );
      setIsAtRight(checkIfShouldRenderRight(popoverContainer, rect));
    }
  };

  const checkIfFits = () => {
    if (containerRef?.current) {
      const moreButtonRect = containerRef.current.getBoundingClientRect();
      const popoverContainer = getPopoverContainerRect();
      const rootRect = document
        ?.getElementById(componentId)
        ?.getBoundingClientRect();
      if (rootRect) {
        const actionsHeight = actionsContainerRef?.current
          ? actionsContainerRef.current.offsetHeight
          : 0;

        const availableSpaceAtTop =
          moreButtonRect.top - Math.max(rootRect.top, 0);
        const availableSpaceAtBottom =
          window.innerHeight > rootRect.bottom
            ? rootRect.bottom - Math.max(moreButtonRect.bottom, 0)
            : window.innerHeight - Math.max(moreButtonRect.bottom, 0);

        setIsAtTop(
          availableSpaceAtTop > actionsHeight &&
            availableSpaceAtBottom < actionsHeight,
        );
        setIsAtRight(
          checkIfShouldRenderRight(popoverContainer, moreButtonRect),
        );
      }
    }
  };

  const getPopoverContainerRect = () => {
    return (
      container ?? PopoverRoot.getPopoverRootElement()!
    ).getBoundingClientRect();
  };

  const calculateActionsContainerPosition = () => {
    if (containerRef?.current) {
      const containerRect = getPopoverContainerRect();
      const buttonRect = containerRef.current.getBoundingClientRect();

      const verticalPosition = isAtTop
        ? { top: 'auto', bottom: containerRect.bottom - buttonRect.bottom }
        : {
            top:
              buttonRect.top -
              containerRect.top +
              containerRef.current.clientHeight,
          };

      const horizontalPosition = isAtRight
        ? { left: buttonRect.right - containerRect.left }
        : { right: containerRect.right - buttonRect.left };

      return { ...verticalPosition, ...horizontalPosition };
    }
  };

  return {
    calculateActionsContainerPosition,
    checkIfFits,
    checkIfFitsInEditor,
    checkIfShouldRenderRight,

    // used for testing
    setIsAtTop,
    setIsAtRight,
  };
};
