import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { bindRouter } from '@wix/tpa-router/plugins';
import _ from 'lodash';

import {
  createMembersAreaWidgetPluginService,
  createWidgetPluginExports,
} from '@wix/members-area-widget-plugin-lib/viewer';

import {
  initializeRouter,
  registerApplicationRoute,
  registerApplicationRoutes,
} from 'controller/router';
import { initializeStore, persistStore } from 'controller/store';
import { bindViewModel, initializeViewModel } from 'controller/view-model';
import { resolvables } from 'controller/resolvables';
import { initializeSettingsEventHandler } from 'controller/tpa-settings-events';

import maGroupsRoutes from '../routes';

import { resolve } from './resolve';
import { configureRouter } from './config';

export const controller: CreateControllerFn = async (params) => {
  const router = initializeRouter(params, { viewer: { inMemory: true } });
  const settingsEvents = initializeSettingsEventHandler(router, params);
  const widgetPluginService = createMembersAreaWidgetPluginService();

  params.flowAPI.bi?.updateDefaults({
    userEntry: 'site',
    origin: 'groups in members area',
  });

  const initialise = async () => {
    const store = initializeStore(params);
    const vm = initializeViewModel(router, store, params);

    configureRouter(router, params);
    registerApplicationRoutes(router, vm, store, params);
    // register groups in ma routes only if exists,
    // if component is not installed vertical base url returns url of group or groups page =>
    // as a result route conflicts 💩
    registerApplicationRoute(
      _.flatten(maGroupsRoutes),
      router,
      vm,
      store,
      params,
    );

    bindViewModel(vm, store, params);
    bindRouter(router, params.flowAPI);

    await resolvables(params, resolve(router, params));

    persistStore(store, params);
  };

  return {
    async pageReady() {
      const isRendered = widgetPluginService.getIsRendered();

      if (isRendered) {
        await initialise();
      }
    },
    updateConfig($w, updatedConfig) {
      settingsEvents.notify(updatedConfig.publicData.COMPONENT || {});
    },
    async onBeforeUnLoad() {
      router.dispose();
    },
    exports: () => {
      return createWidgetPluginExports(widgetPluginService, initialise);
    },
  };
};
