import { Alignment } from './settingsParams';
import { Alignment as StyleAlignment } from './stylesParams';

export const getIsSearchBarEnabledDefaultValue = () => true;

export const getDefaultPaginationAlignment = () => Alignment.Center;

export const alignmentTextToNumber = (alignment: string) => {
  const stringToNumber = {
    left: StyleAlignment.Left,
    center: StyleAlignment.Center,
    right: StyleAlignment.Right,
  };
  return stringToNumber[alignment as keyof typeof stringToNumber];
};

export const alignmentTextFromNumber = (alignment: StyleAlignment) => {
  const numberToString = {
    [StyleAlignment.Left]: Alignment.Left,
    [StyleAlignment.Center]: Alignment.Center,
    [StyleAlignment.Right]: Alignment.Right,
  };
  return numberToString[alignment];
};

export const getIsSearchBarEnabledFromTpaData = (
  tpaData: any,
  isMobile: boolean,
): boolean => {
  const isSearchBarEnabled = isMobile
    ? tpaData['isSearchBarEnabled▶︎m']
    : tpaData.isSearchBarEnabled;
  return isSearchBarEnabled ?? getIsSearchBarEnabledDefaultValue();
};

export const getPaginationAlignmentFromTpaData = (
  tpaData: any,
  isMobile: boolean,
): boolean => {
  const paginationAlignment = isMobile
    ? tpaData['paginationAlignment▶︎m']
    : tpaData.paginationAlignment;
  return paginationAlignment ?? getDefaultPaginationAlignment();
};
