import { compact } from 'lodash';
import { applyMiddleware, createStore, type Store } from 'redux';
import thunk from 'redux-thunk';
import {
  FETCH_BLOG_DATA_SUCCESS,
  GLOBALS,
  SET_APP_CONFIG,
} from '@wix/communities-blog-client-common';

import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { type Router } from '@app/external/common/router';
import {
  type ControllerConfig,
  type FlowAPI,
} from '@app/external/common/types';
import {
  appUouBiMiddleware,
  appUsersBiMiddleware,
} from '../../common/bi-events/init-middleware';
import { getInstance } from '../../common/controller/helpers';
import createShowMessage from '../../common/messages/framework/create-show-message';
import { createMessageMiddleware } from '../../common/messages/framework/initialize-store';
import createRouterMiddleware from '../../common/middleware/create-router-middleware';
import {
  createDuplexerMiddleware,
  socketEventHandlers,
} from '../../common/middleware/duplexer-middleware';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';
import {
  debounceActions,
  throttleActions,
} from '../../common/middleware/redux-middlewares';
import {
  createModalClosedMiddleware,
  createModalOpenedMiddleware,
} from '../../common/modals/framework/initialize-store';
import createRequests from '../../common/services/create-requests';
import { SET_APP_SETTINGS } from '../../common/store/app-settings/app-settings-actions';
import { SET_BASIC_PARAMS } from '../../common/store/basic-params/basic-params-actions';
import { FETCH_CATEGORIES_SUCCESS } from '../../common/store/categories/fetch-categories';
import { SET_INSTANCE_VALUES } from '../../common/store/instance-values/instance-values-actions';
import { FETCH_SITE_PROPERTIES_SUCCESS } from '../../common/store/site-properties/site-properties-types';
import { FETCH_TOPOLOGY_SUCCESS } from '../../common/store/topology/topology-actions';
import { waitForActionMiddleware } from '../../common/store/wait-for-action/wait-for-action-middleware';
import { eventMap } from '../bi-events/uou-events';
import { usersEventMap } from '../bi-events/users-events';
import messageResolvers from '../components/messages/message-resolvers';
import { modalDataLoaderByType } from '../components/modals/modal-data-loader-map-by-type';
import { modalResolverMapByType } from '../components/modals/modal-resolver-map-by-type';
import reducers from '../reducers';

export function createReduxStore({
  appParams,
  platformApi,
  compId,
  fedopsLogger,
  platformAPIs,
  getRouter,
  isSSR,
  sentry,
  isEditor,
  isPreview,
  language,
  bundleName,
  flowAPI,
}: {
  appParams: ControllerConfig['appParams'];
  platformApi: PlatformApi;
  compId: ControllerConfig['compId'];
  fedopsLogger: FlowAPI['fedops'];
  platformAPIs: ControllerConfig['platformAPIs'];
  getRouter: () => Router;
  isSSR: boolean;
  sentry: FlowAPI['sentry'];
  isEditor: boolean;
  isPreview: boolean;
  language: string;
  bundleName: string;
  flowAPI: FlowAPI;
}) {
  const p: {
    store?: Store;
  } = {};

  const { request, platformizedRequest, paywallRequest } = createRequests({
    platformApi,
    bundleName,
    getStore: () => p.store!,
    flowAPI,
  });

  const disableDuplexerByInstanceId = (
    GLOBALS.DISABLE_DUPLEXER_FOR_INSTANCE_IDS || ''
  )
    .split(',')
    .includes(appParams.instanceId);
  const useDuplexer =
    !isSSR && !isEditor && !isPreview && !disableDuplexerByInstanceId;

  const middleware = compact([
    throttleActions(),
    debounceActions(),
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      platformApi,
      paywallRequest,
      compId,
      appParams,
      fedopsLogger,
      getRouter,
      httpClient: flowAPI.httpClient,
      flowAPI,
    }),
    waitForActionMiddleware([
      SET_APP_SETTINGS,
      SET_APP_CONFIG,
      SET_INSTANCE_VALUES,
      SET_BASIC_PARAMS,
      FETCH_BLOG_DATA_SUCCESS,
      FETCH_SITE_PROPERTIES_SUCCESS,
      FETCH_TOPOLOGY_SUCCESS,
      FETCH_CATEGORIES_SUCCESS,
    ]),

    (isEditor || isPreview) && createRouterMiddleware(getRouter),
    createModalClosedMiddleware({ modalResolverMapByType, createShowMessage }),
    createModalOpenedMiddleware({ modalDataLoaderByType }),
    createMessageMiddleware({ resolvers: messageResolvers }),
    postMetadataMiddleware({ isSSR, language }),
    isEditor
      ? appUsersBiMiddleware({
          platformAPIs,
          instanceId: appParams.instanceId,
          eventMap: usersEventMap,
        })
      : appUouBiMiddleware({
          platformAPIs,
          instanceId: appParams.instanceId,
          eventMap,
        }),
    useDuplexer &&
      createDuplexerMiddleware({
        getInstance: getInstance(platformApi),
        eventHandlers: socketEventHandlers,
        instanceId: appParams.instanceId,
        captureMessage: (message, extra) =>
          sentry.captureException(message, { extra }),
      }),
  ]);

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
