import { raise } from '@wix/communities-blog-client-common';
import { DateFormatter } from '../../blocks/common/date-formatter';
import { isMemberAreaInstalled } from '../../common/members';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import blocksModel from './model';
import type { ControllerParams, IController } from './types';

export class MetadataController implements IController {
  private readonly platformApi =
    this.context.appData?.platformApi ?? raise('No platform API provided');
  private readonly facade = new BlocksWidgetFacade(
    this.context.flowAPI,
    this.platformApi,
  );
  private readonly dateFormatter = new DateFormatter(this.platformApi);

  private readonly ui = (() => {
    const $w = this.context.$w;

    return {
      writer: $w('#writer'),
      writerButton: $w('#button1'),
      publishedDate: $w('#text5'),
      timeToRead: $w('#text2'),
      lastPublishedDate: $w('#text3'),
      avatar: $w('#avatar'),
    };
  })();

  constructor(private readonly context: ControllerParams) {}

  pageReady = () => {};

  exports = {
    ...blocksModel.getExports(),

    initialize: async (model: PostPageRenderModel) => {
      this.setState(model);

      const membersInstalled = await isMemberAreaInstalled(this.platformApi);

      if (membersInstalled) {
        this.ui.writerButton.link = await this.facade.getMembersProfileUrl(
          model.post,
        );
        this.ui.writerButton.target = '_self';
      } else {
        this.ui.writerButton.disable();
      }
    },
  };

  private setState({ post, author }: PostPageRenderModel) {
    this.ui.writerButton.label = post.owner?.name ?? '';

    if (!post.firstPublishedDate) {
      throw new Error('No first published date provided');
    }

    this.ui.publishedDate.text = this.dateFormatter.format(
      post.firstPublishedDate,
    );

    const { t } = this.context.flowAPI.translations;

    this.ui.timeToRead.text = t('post-metadata.time-to-read.label', {
      minutes: post.timeToRead,
    });

    if (post.lastPublishedDate) {
      this.ui.lastPublishedDate.text = t('post-metadata.update-date', {
        date: this.dateFormatter.formatICU(post.lastPublishedDate) ?? '',
      });
    }

    // @ts-expect-error
    this.ui.avatar.firstName = author.firstName;
    // @ts-expect-error
    this.ui.avatar.lastName = author.lastName;
    // @ts-expect-error
    this.ui.avatar.src = author.avatarUrl;
  }
}
