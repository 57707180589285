import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { request } from '../../../../services/request';
import { listCategories } from '@wix/ambassador-achievements-categories-v3-category/http';
import { shouldReportErrorMonitor } from '../../Challenge/helpers/shouldReportErrorMonitor';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';

export async function requestCategoriesHttp(flowAPI: ControllerFlowAPI) {
  let categories = [];

  try {
    categories =
      (await request(flowAPI, listCategories({})))?.data?.categories || [];
  } catch (error) {
    handleError({
      error,
      context: 'GetCategoriesList',
      preventErrorMonitorReport: !shouldReportErrorMonitor(error),
    });
  }

  return { categories };
}
