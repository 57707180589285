import { IResolveStepContext } from '../../../contexts/ResolveStep/ResolveStepContext';
import type { IParticipantSectionsContext } from '../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { IGeneralDataProviderProps } from '../../../contexts/GeneralDataProvider/generalDataProviderPropsMap';
import { IUserContext } from '../../../contexts/User/UserContext';
import { ILocationProps } from '../../../contexts/Location/locationProviderPropsMap';
import { ISettingsEventsContext } from '../../../contexts/SettingsEvents/SettingsEvents';

import { IChallengeSettings } from '../Settings/challengeSettings/challengeSettings.types';
import { UnpackSettingsValues } from '../../../types/helpers';
import { SelectedPaymentOption } from './components/Pricing/interfaces';
import { ILoadingContext } from '../../../contexts/Loading/interfaces';
import { IToastContext } from '../../../contexts/ToastContext/ToastContext';
import { IMonitoringContext } from '../../../contexts/Monitoring/MonitoringContext';
import { ISocialGroupsProviderProps } from '../../../contexts/SocialGroups/interfaces';
import { IQuizContext } from '../../../contexts/Quiz/QuizContext';
import { IChallengeContext } from '../../../contexts/storage-contexts/Challenge';

export type IChallengePageSettingsValues =
  UnpackSettingsValues<IChallengeSettings> & {
    buttonState: 'default' | 'hover';
  };

export interface IChallengePageProps
  extends ILocationProps,
    IUserContext,
    ILoadingContext,
    IGeneralDataProviderProps,
    IChallengeContext,
    IParticipantSectionsContext,
    IResolveStepContext,
    IToastContext,
    ISettingsEventsContext,
    IMonitoringContext,
    ISocialGroupsProviderProps,
    IQuizContext {
  settings: IChallengePageSettingsValues;
  eligiblePlans: any;
  userPaidPlans: any;
  isLoading: boolean;
  t(key: string, opts?: any): string;
  pageRef?: React.RefObject<HTMLElement>;
  isForcedBlankPage: boolean;
}

export enum IChallengeJoinRestriction {
  MAX_PARTICIPANTS = 'MAX_PARTICIPANTS',
  SPECIFIC_STARTED = 'SPECIFIC_STARTED',
  FINISHED = 'FINISHED',
  OFFLINE_PAYMENT_PENDING = 'OFFLINE_PAYMENT_PENDING',
}

export interface IChallengePageState {
  wixAdvertisementBannerHeight?: number;
  isEmptyStartDateError?: boolean;
  startDate?: string;
  selectedTabIndex?: number;
  currentStep?: IParticipantSectionsContext['selectedStep'];
  isCurrentStateWasResolved?: boolean;
  isShareModalOpened?: boolean;
  isLeaveModalOpened?: boolean;
  isCantJoinModalOpened?: boolean;
  selectedPaymentOption: SelectedPaymentOption | null;
  isChallengeDisabledForJoin?: boolean;
  challengeJoinRestrictions?: IChallengeJoinRestriction[];
  sharedCurrentDateForWeeksSelector?: Date;
  mobileScreen?: 'list' | 'view';
  stepViewAsPageId?: string;
  chosenSectionTileId?: string;
  sectionIdForceOpened?: string; // for ability to open certain section in list layout
  stepIdForceOpened?: string; // for ability to open certain step in list layout
  isDisableEligiblePlans?: boolean;
  isPremiumOutdatedModalOpened?: boolean;
  videoStepAccepted?: boolean;
  isVideoLoadingError?: boolean;
  isAutoNavigateToNextStep?: boolean;
  isAutoPlayVideo?: boolean;
  isSectionsWithoutDescriptionEnabled?: boolean;
}
