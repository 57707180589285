import type { ChallengeStep } from '@wix/ambassador-challenges-v1-participant/types';
import type { Step as StepV3 } from '@wix/ambassador-online-programs-v3-step/types';

const isV3Step = (step: any): boolean => {
  return step && (step as StepV3).revision !== undefined;
};

export const getVideoOptions = (step: any) => {
  if (isV3Step(step)) {
    return (step as StepV3)?.videoOptions;
  }
  return (step as ChallengeStep)?.settings?.general?.video;
};
export const isVideoStep = (step: any) => {
  return !!getVideoOptions(step);
};
