import React from 'react';
import { TabItem, Tabs, TabsAlignment, TabsSkin } from 'wix-ui-tpa/cssVars';
import type { Category } from '@wix/ambassador-faq-category-v2-category/types';
import { st, classes } from '../../Widget.st.css';

const HorizontalTabs = ({
  searchTerm,
  categoriesToRender,
  activeTab,
  adjustedActiveTab,
  setActiveTab,
  splitIntoCategories,
  rtl,
}: {
  searchTerm?: string;
  categoriesToRender: Category[];
  activeTab: number;
  adjustedActiveTab: number;
  setActiveTab: (index: number) => void;
  splitIntoCategories: boolean;
  rtl: boolean;
}) => {
  return (
    !searchTerm &&
    splitIntoCategories &&
    categoriesToRender.length > 0 && (
      <Tabs
        data-hook="faq-tabs"
        className={st(classes.Tabs)}
        items={categoriesToRender as TabItem[]}
        activeTabIndex={adjustedActiveTab}
        onTabClick={setActiveTab}
        skin={TabsSkin.clear}
        alignment={rtl ? TabsAlignment.right : TabsAlignment.left}
      />
    )
  );
};

export default HorizontalTabs;
