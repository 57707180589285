import { DISABLE_POST_COMMENTS_SUCCESS } from '../../../comments/actions/disable-post-comments-types';
import { ENABLE_POST_COMMENTS_SUCCESS } from '../../../comments/actions/enable-post-comments-types';
import { FETCH_RECENT_POSTS_SUCCESS } from '../../../post-page/actions/fetch-recent-posts';
import { FETCH_SEARCH_SUCCESS } from '../../../search/actions/fetch-search-types';
import { CREATE_POST_DRAFT_SUCCESS } from '../../actions/create-post-draft-types';
import { DELETE_POST_SUCCESS } from '../../actions/delete-post';
import { FETCH_ARCHIVE_SUCCESS } from '../../actions/fetch-archive';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../../actions/fetch-category-posts';
import { FETCH_FEED_POSTS_SUCCESS } from '../../actions/fetch-feed-posts';
import { FETCH_HASHTAG_POSTS_SUCCESS } from '../../actions/fetch-hashtag-posts';
import { FETCH_POST_SUCCESS } from '../../actions/fetch-post';
import { FETCH_TAG_POSTS_SUCCESS } from '../../actions/fetch-tag-posts';
import { PIN_POST_SUCCESS } from '../../actions/pin-post-types';
import { PUBLISH_POST_SUCCESS } from '../../actions/publish-post-types';
import { SAVE_POST_DRAFT_SUCCESS } from '../../actions/save-post-draft-types';
import { SET_POSTS } from '../../actions/set-posts';
import {
  SUBSCRIBE_FAILURE,
  SUBSCRIBE_REQUEST,
} from '../../actions/subscribe-types';
import { UNPIN_POST_SUCCESS } from '../../actions/unpin-post-types';
import {
  UNSUBSCRIBE_FAILURE,
  UNSUBSCRIBE_REQUEST,
} from '../../actions/unsubscribe-types';
import { UPDATE_POST_SUCCESS } from '../../actions/update-post';
import { SET_USER } from '../auth/set-user';

const unionPosts = (oldPosts, newPosts) =>
  newPosts.reduce(
    (result, post) => {
      result[post.id] = post;
      return result;
    },
    { ...oldPosts },
  );

// eslint-disable-next-line complexity
const post = (state, action) => {
  switch (action.type) {
    case SET_USER:
      if (state.owner.siteMemberId === action.payload.siteMemberId) {
        return { ...state, owner: { ...state.owner, ...action.payload } };
      }
      return state;
    case SUBSCRIBE_REQUEST:
      if (state.id === action.payload.id) {
        return { ...state, isSubscribed: true };
      }
      return state;
    case SUBSCRIBE_FAILURE:
      if (state.id === action.payload.id) {
        return { ...state, isSubscribed: false };
      }
      return state;
    case UNSUBSCRIBE_REQUEST:
      if (state.id === action.payload.id) {
        return { ...state, isSubscribed: false };
      }
      return state;
    case UNSUBSCRIBE_FAILURE:
      if (state.id === action.payload.id) {
        return { ...state, isSubscribed: true };
      }
      return state;
    default:
      return state;
  }
};

// eslint-disable-next-line complexity
const posts = (state = {}, { type, payload } = {}) => {
  switch (type) {
    case SET_POSTS:
      return unionPosts({}, payload);
    case FETCH_POST_SUCCESS:
      return unionPosts(state, [payload.post]);
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_RECENT_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_ARCHIVE_SUCCESS:
    case FETCH_HASHTAG_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_SUCCESS:
      return unionPosts(state, payload);
    case UPDATE_POST_SUCCESS:
    case PIN_POST_SUCCESS:
    case UNPIN_POST_SUCCESS:
    case ENABLE_POST_COMMENTS_SUCCESS:
    case DISABLE_POST_COMMENTS_SUCCESS:
      return unionPosts(state, [payload]);
    case CREATE_POST_DRAFT_SUCCESS:
    case PUBLISH_POST_SUCCESS:
      return unionPosts(state, [payload]);

    case SAVE_POST_DRAFT_SUCCESS:
      return unionPosts(state, [payload?.post]);

    case FETCH_SEARCH_SUCCESS:
      return unionPosts(
        state,
        payload.posts.filter((post) => post.type === 'post'),
      );
    case DELETE_POST_SUCCESS: {
      return Object.keys(state).reduce((result, postId) => {
        const current = state[postId];
        if (current.id !== payload) {
          result[postId] = current;
        }
        return result;
      }, {});
    }
    case SUBSCRIBE_REQUEST:
    case SUBSCRIBE_FAILURE:
    case UNSUBSCRIBE_REQUEST:
    case UNSUBSCRIBE_FAILURE:
      if (payload.type === 'post') {
        return Object.keys(state).reduce((result, current) => {
          result[current] = post(state[current], { type, payload });
          return result;
        }, {});
      }
      return state;
    case SET_USER:
      return Object.keys(state).reduce((result, current) => {
        result[current] = post(state[current], { type, payload });
        return result;
      }, {});
    default:
      return state;
  }
};

export default posts;
