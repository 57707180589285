import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { type IFeedItem } from 'api/feed/types';

import { Visible as VisibleIcon } from '@wix/wix-ui-icons-common/on-stage';

import { FeedItemStat, type IFeedItemStatProps } from '../FeedItemStat';

export interface IViewsStatProps extends Partial<IFeedItemStatProps> {
  item: IFeedItem;
}

export function ViewsStat(props: IViewsStatProps) {
  const { item, ...rest } = props;

  const { t } = useTranslation();

  return (
    <FeedItemStat
      count={item.viewsCount}
      icon={<VisibleIcon />}
      label={t('groups-web.discussion.feed.views.total_icu', {
        count: item.viewsCount ?? 0,
      })}
      {...rest}
    />
  );
}

ViewsStat.displayName = 'ViewsStat';
