import React from 'react';
import _ from 'lodash';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from 'api/feed/types';

import { Show } from 'wui/Show';
import { Stack } from 'wui/Stack';
import { Box, type IBoxProps } from 'wui/Box';

import { ReactedMembersDialog } from '../Reactions/ReactedMembersDialog';

import { ViewsStat } from './ViewsStat';
import { CommentsStat } from './CommentsStat';
import { ReactionsStat } from './ReactionsStat';

export interface IFeedItemStatsProps extends IBoxProps {
  short?: boolean;
  item: IFeedItem;

  onCommentsClick?(): void;
}

export function FeedItemStats(props: IFeedItemStatsProps) {
  const { item, short = false, onCommentsClick, ...rest } = props;

  const { t } = useTranslation();
  const { experiments } = useExperiments();

  const [isOpen, setIsOpen] = React.useState(false);

  const views = item.viewsCount ?? 0;
  const reactions = item.reactions?.total ?? 0;
  const canViewFullPost = item.permissions?.canViewFullPost ?? false;

  if (short) {
    return (
      <Box verticalAlign="middle" {...rest}>
        <CommentsStat item={item} onClick={onCommentsClick} />
      </Box>
    );
  }

  if (!views) {
    return (
      <Box verticalAlign="middle" align="space-between" {...rest}>
        <ReactionsStat
          item={item}
          onClick={canViewFullPost ? openDialog : undefined}
        />

        <CommentsStat item={item} onClick={onCommentsClick} />

        <ReactedMembersDialog
          item={item}
          isOpen={isOpen}
          onClose={closeDialog}
        />
      </Box>
    );
  }

  return (
    <Box verticalAlign="middle" align="space-between" {...rest}>
      <Stack verticalAlign="middle" gap="SP4">
        <Show if={!!reactions}>
          <ReactionsStat
            item={item}
            onClick={canViewFullPost ? openDialog : undefined}
          />
        </Show>

        <CommentsStat item={item} onClick={onCommentsClick} />
      </Stack>

      <Show if={!!views}>
        <ViewsStat item={item} />
      </Show>

      <ReactedMembersDialog item={item} isOpen={isOpen} onClose={closeDialog} />
    </Box>
  );

  function openDialog() {
    setIsOpen(true);
  }

  function closeDialog() {
    setIsOpen(false);
  }
}

FeedItemStats.displayName = 'FeedItemStats';
