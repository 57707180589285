import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { userProviderPropsMap } from '../../contexts/User/userProviderPropsMap';
import { getParticipantIdFromInitialProps } from './getParticipantId';
import { getLocationFromFlowAPI } from '../../contexts/Location/locationProviderPropsMap';

export const resolveParticipantId = async (flowAPI: ControllerFlowAPI) => {
  const participantIdFromLocation =
    getLocationFromFlowAPI(flowAPI).query?.participantId;
  if (participantIdFromLocation) {
    return { participantId: participantIdFromLocation };
  }
  const data = await userProviderPropsMap(flowAPI);
  return { participantId: getParticipantIdFromInitialProps(data) };
};
