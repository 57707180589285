import { ActionTypes } from '../../main/biInterfaces';
import { Status } from '@wix/ambassador-challenges-v1-approval-request/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeId } from '../../storage-contexts/Challenge/helpers/getChallengeId';
import { biBeforeAndAfter } from '../../../services/biHelpers';
import {
  listApprovalRequests,
  resolveApprovalRequest,
} from '@wix/ambassador-challenges-v1-approval-request/http';
import { request } from '../../../services/request';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';

async function getApprovalRequestId(
  flowAPI: ControllerFlowAPI,
  challengeId?: string,
) {
  challengeId = challengeId ? challengeId : await getChallengeId(flowAPI);

  try {
    const approvals = (
      await request(
        flowAPI,
        listApprovalRequests({
          challengeId,
          resolverId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
          includeResolved: false,
        }),
      )
    )?.data;

    return approvals?.requests?.['0']?.id;
  } catch (error) {
    handleError({ error, context: 'acceptInvite.listApprovalRequests' });
  }
}

export async function acceptInvite(
  flowAPI: ControllerFlowAPI,
  timeZone: string,
  startDate: string,
) {
  const approvalRequestId = await getApprovalRequestId(flowAPI);

  if (approvalRequestId) {
    await biBeforeAndAfter(
      flowAPI.bi,
      ActionTypes.CHALLENGE_JOIN,
      async (actionId) => {
        return (
          await request(
            flowAPI,
            resolveApprovalRequest({
              actionId,
              memberTimeZone: timeZone,
              requestId: approvalRequestId,
              resolutionStatus: Status.ACCEPTED,
              startDate,
            }),
          )
        )?.data;
      },
    );
  } else {
    return;
  }
}
