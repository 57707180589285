import { get } from 'lodash';

export const DEFAULT_MOBILE_VIEWPORT_WIDTH = 320;
const DEFAULT_MOBILE_SCREEN_WIDTH = 320;

export const getMobileScaleFactor = () => {
  const screenWidth = get(window, 'screen.width', DEFAULT_MOBILE_SCREEN_WIDTH);
  const viewportWidth = get(
    window,
    'innerWidth',
    DEFAULT_MOBILE_VIEWPORT_WIDTH,
  );
  return screenWidth / viewportWidth;
};
