import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import withTranslate from '../../hoc/with-translate';
import { CounterCompact } from '../counter-compact';
import { ViewCountIcon } from '../icons/view-count-icon';

const ViewCountCompact = ({ t, iconColorClassName, ...counterProps }) => (
  <CounterCompact
    {...counterProps}
    dataHook="view-count-compact"
    ariaHidden={true}
  >
    <ViewCountIcon
      aria-label={t('view-count.label', {
        count: counterProps.count,
        numberFormatted: counterProps.countFormatted,
      })}
      className={iconColorClassName}
    />
  </CounterCompact>
);

ViewCountCompact.propTypes = {
  iconColorClassName: PropTypes.string,
  t: PropTypes.func,
};
export default flowRight(
  withTranslate,
  withLayoutColorClasses,
)(ViewCountCompact);
