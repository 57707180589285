import { useSettings } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import settingsParams from '../settingsParams';
import { Divider } from './divider';
import { classes } from './error-state.st.css';

export const ErrorState = () => {
  const { t } = useTranslation();
  const settings = useSettings();

  const titleTag = settings.get(settingsParams.emptyStateTitleTag);
  const subtitleTag = settings.get(settingsParams.emptyStateSubtitleTag);

  return (
    <div data-hook={DataHook.ErrorState} className={classes.root}>
      <Divider />
      <div className={classes.content}>
        <Text tagName={titleTag} data-hook={DataHook.ErrorStateTitle} className={classes.title}>
          {t('app.error-state.service-not-available')}
        </Text>
        <Text tagName={subtitleTag} data-hook={DataHook.ErrorStateDescription} className={classes.description}>
          {t('app.error-state.reload-page')}
        </Text>
      </div>
      <Divider />
    </div>
  );
};
