import stylesParams from '../../../stylesParams';
import { useGeneralData } from '../../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { useStyles } from '@wix/tpa-settings/react';
import { useCallback, useMemo } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const useQuizStyles = () => {
  // This function is needed for form-viewer to get the styles from the form-app
  const { host } = useGeneralData();
  const { isEditor } = useEnvironment();
  const styles = useStyles();
  const getStylesForHost = useCallback(() => {
    return {
      ...(host?.style ?? {}),
      styleParams: {
        ...(host?.style?.styleParams ?? {}),
        booleans: { ...(host?.style?.styleParams?.booleans ?? {}) },
        colors: {
          ...(host?.style?.styleParams?.colors ?? {}),
          headerOneColor: styles.get(
            stylesParams.sidebarLayoutBodyDescriptionColor,
          ),
          headerTwoColor: styles.get(
            stylesParams.sidebarLayoutBodyDescriptionColor,
          ),
          paragraphColor: styles.get(
            stylesParams.sidebarLayoutBodyDescriptionColor,
          ),
        },
        fonts: {
          ...(host?.style?.styleParams?.fonts ?? {}),
        },
        numbers: { ...(host?.style?.styleParams?.numbers ?? {}) },
      },
      compId: host.id,
    };
  }, [host, styles]);

  let style = useMemo(() => getStylesForHost(), [getStylesForHost]);

  if (isEditor) {
    style = getStylesForHost();
  }
  return style;
};
