import { bindActionCreators, type Store } from 'redux';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import {
  type ControllerConfig,
  type FlowAPI,
} from '@app/external/common/types';

import { getInstance } from '../../common/controller/helpers';
import { initializeActions as initializeCommonActions } from '../../common/controller/init-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { initializeInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import {
  setLocale,
  setQueryLocaleAction,
} from '../../common/store/locale/locale-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { fetchArchiveWidgetInitialData } from '../actions/fetch-initial-data';

export {
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export function initializeActions({
  platformApi,
  store,
  fedopsLogger,
}: {
  platformApi: PlatformApi;
  store: Store;
  fedopsLogger: FlowAPI['fedops'];
}) {
  return {
    ...initializeCommonActions({
      platformApi,
      store,
      fedopsLogger,
    }),
    ...bindActionCreators({ fetchArchiveWidgetInitialData }, store.dispatch),
  };
}

export async function initializeStoreBaseData({
  platformApi,
  store,
  language,
  platformAPIs,
  bundleName,
  appParams,
  showNewestPostsFirst,
  monthsDisplayLimit,
}: {
  platformApi: PlatformApi;
  store: Store;
  language: string;
  platformAPIs: ControllerConfig['platformAPIs'];
  bundleName: string;
  appParams: ControllerConfig['appParams'];
  showNewestPostsFirst: boolean;
  monthsDisplayLimit: number;
}) {
  const viewMode = platformApi.window.viewMode.toLowerCase();

  store.dispatch(initializeInstanceValues(getInstance(platformApi)()));

  const instanceId = getInstanceId(store.getState());
  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(platformApi, appParams));

  await Promise.all([
    store.dispatch(
      setBasicParams({
        viewMode,
        language,
        biPageNumber: platformAPIs.bi?.pageNumber,
      }),
    ),
    store.dispatch(setAppConfig({ bundleName })),
    store.dispatch(
      fetchArchiveWidgetInitialData({
        showNewestPostsFirst,
        monthsDisplayLimit,
      }),
    ),
  ]);
  await store.dispatch(fetchTopology(instanceId));
}
