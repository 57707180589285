import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  BREAKPOINT_POST_CONTENT_AREA_MIN,
  BREAKPOINT_POST_CONTENT_AREA_SMALL,
} from '../../../post-page/constants/post-page';
import ResponsiveContext from './responsive-context';

const CLASS_PREDICATES = Object.entries({
  xs: (w: number) => w < 660,
  s: (w: number) => w >= 660 && w < 686,
  sm: (w: number) => w >= 686 && w < 980,
  md: (w: number) => w >= 980 && w < 1280,
  lg: (w: number) => w >= 1280 && w < 1920,
  xl: (w: number) => w >= 1920,
  'lt-s': (w: number) => w < 660,
  'lt-sm': (w: number) => w < 686,
  'lt-md': (w: number) => w < 980,
  'lt-lg': (w: number) => w < 1280,
  'lt-xl': (w: number) => w < 1920,
  'gt-xs': (w: number) => w >= 660,
  'gt-s': (w: number) => w >= 686,
  'gt-sm': (w: number) => w >= 980,
  'gt-md': (w: number) => w >= 1280,
  'gt-lg': (w: number) => w >= 1920,
  'gt-740': (w: number) => w >= 740,
  'gt-830': (w: number) => w >= 830,
  'gt-886': (w: number) => w >= 886,
  'gt-940': (w: number) => w >= 940,
  'gt-1000': (w: number) => w >= 1000,
  'gt-1080': (w: number) => w >= 1080,
  'gt-1200': (w: number) => w >= 1200,
  'w686-745': (w: number) => w >= 686 && w <= 745,
  'w686-980': (w: number) => w >= 686 && w <= 980,
  'lte-w980': (w: number) => w <= 980,
  'lte-post_content_area_min': (w: number) =>
    w <= BREAKPOINT_POST_CONTENT_AREA_MIN,
  'lte-post_content_area_small': (w: number) =>
    w <= BREAKPOINT_POST_CONTENT_AREA_SMALL,
  'lte-banner-w1564': (w: number) => w <= 1564,
  'lte-banner-w1425': (w: number) => w <= 1425,
  'lte-banner-w1181': (w: number) => w <= 1181,
  'lte-banner-w980': (w: number) => w <= 980,
  'lte-category-header-w1364 ': (w: number) => w <= 1364,
});

const getClassListForWidth = (w: unknown, isDesktop: boolean) => {
  if (typeof w !== 'number') {
    return isDesktop ? 'use-media-queries' : undefined;
  }

  const classList = CLASS_PREDICATES.filter(([_, predicate]) =>
    predicate(w),
  ).map(([className]) => className);

  return classNames(classList);
};

type Props = {
  children: React.ReactNode;
  className?: string;
  dataHook?: string;
};

const ResponsiveListener: React.FC<Props> = (props) => {
  const nodeRef = useRef(null);

  const { dimensions, isMobile } = useEnvironment();
  const isDesktop = !isMobile;
  const [width, setWidth] = useState(
    (dimensions.width as number | '') || undefined,
  );

  const classList = useMemo(
    () => getClassListForWidth(width, isDesktop),
    [isDesktop, width],
  );

  useEffect(() => {
    if (!nodeRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      const newWidth = Math.floor(entries[0].contentRect.width);
      if (width !== newWidth) {
        setWidth(newWidth);
      }
    });

    resizeObserver.observe(nodeRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [width]);

  const value = useMemo(() => ({ rootWidth: width }), [width]);
  const cssStyle = useMemo(
    () => ({ '--root-width': width ? `${width}px` : '100vw' }),
    [width],
  );

  return (
    <div
      ref={nodeRef}
      style={cssStyle}
      className={classNames(classList, props.className)}
      data-hook={props.dataHook}
    >
      <ResponsiveContext.Provider value={value}>
        {props.children}
      </ResponsiveContext.Provider>
    </div>
  );
};

export default ResponsiveListener;
