import { type Store } from 'redux';
import {
  decodeQuery,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_HASHTAG_PAGE_USE_FEED_PAGE } from '@wix/communities-blog-experiments';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { type RouteResolverFn } from '@app/external/common/types';
import { pageOpened } from '../../../common/actions/page-opened';
import {
  search,
  clearSearchResults,
} from '../../../common/controller/lazy-actions';
import { SEARCH_RESULTS } from '../../../common/services/detect-route';
import { setMetaTagRobotsNoIndex } from '../../../common/services/set-metatag-robots-noindex';
import { isSSR } from '../../../common/store/basic-params/basic-params-selectors';
import { setIsLoading } from '../../../common/store/is-loading/is-loading-actions';
import { getTopology } from '../../../common/store/topology/topology-selectors';
import { MIN_QUERY_LENGTH } from '../../../search/actions/search-types';

export const createSearchPageRouter =
  (
    store: Store,
    platformApi: PlatformApi,
  ): RouteResolverFn<{ query?: string }> =>
  async ({ params: { query } }) => {
    if (
      query &&
      query.startsWith('.hash.') &&
      isExperimentEnabled(
        store.getState(),
        EXPERIMENT_HASHTAG_PAGE_USE_FEED_PAGE,
      )
    ) {
      const hashtag = query.split('.')[2];
      const { sectionUrl } = getTopology(store.getState());
      const canonical = `${sectionUrl}/hashtags/${hashtag}`;
      platformApi.seo.setLinks([
        ...(platformApi.seo.links || []),
        { rel: 'canonical', href: canonical },
      ]);
    }
    setMetaTagRobotsNoIndex(platformApi);
    store.dispatch(clearSearchResults());
    if (query && query.length >= MIN_QUERY_LENGTH) {
      store.dispatch(setIsLoading('search', '', true));
      return (
        !isSSR(store.getState()) &&
        store.dispatch(search({ query: decodeQuery(query) }))
      );
    }
    !isSSR(store.getState()) &&
      store.dispatch(pageOpened({ page: SEARCH_RESULTS }));
  };
